import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EIConnectionViewTable from "./eiConnectionView";
import "../util/tables.css";
import { useDataView } from "../../contexts/data.view.context";
import * as API from "../api/index";
import { NotificationManager } from "react-notifications";
// import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ViewEditConnection from "./viewEditConnection";
import ShowLoadingBar from "../util/showLoadingBar";
import ConfirmPromoteDialog from "../util/confirmPromoteDialog";
import ViewDetailsIcon from "../util/detailsModal/viewDetailsIcon";

const columns = [
  {
    accessorKey: "CONNECTION_ID", //access nested data with dot notation
    header: "ID",
    size: 75,
  },
  {
    accessorKey: "CONNECTION_TYPE",
    header: "Type",
    size: 75,
  },
  {
    accessorKey: "PROJECT_ID", //access nested data with dot notation
    header: "Project ID",
    size: 75,
  },
  {
    accessorKey: "PROJECT_OID", //access nested data with dot notation
    header: "Project OID",
    size: 75,
  },
  {
    accessorKey: "PROJECT_NAME", //access nested data with dot notation
    header: "Project Name",
    size: 75,
  },
  {
    accessorKey: "SPONSOR_NAME", //access nested data with dot notation
    header: "Sponsor Name",
    size: 75,
  },
  {
    accessorKey: "BUSINESS_ID", //access nested data with dot notation
    header: "Business ID",
    size: 75,
  },
  {
    accessorKey: "CONNECTION_URL",
    header: "URL",
    size: 150,
  },
  {
    accessorKey: "CONNECTION_PROVIDER", //access nested data with dot notation
    header: "Provider",
    size: 100,
  },
  {
    accessorKey: "CONNECTION_DATA",
    header: "Data",
    size: 150,
  },
  {
    accessorKey: "AUTH_TYPE",
    header: "Auth Type",
    size: 150,
  },
  {
    accessorKey: "AUTH_ID",
    header: "Auth ID",
    size: 150,
  },
  {
    accessorKey: "AUTH_PIN",
    header: "Auth PIN",
    size: 150,
  },
  {
    accessorKey: "AUTH_PASSCODE",
    header: "Auth Passcode",
    size: 150,
  },
  {
    accessorKey: "STATE", //access nested data with dot notation
    header: "Status",
    size: 50,
  },
  {
    accessorKey: "MAX_ROWS_PER_QUERY",
    header: "Max Rows per Query",
    size: 150,
  },
  {
    accessorKey: "MAX_QUERIES",
    header: "Max Queries",
    size: 150,
  },
  {
    accessorKey: "PING_SERVICE",
    header: "Ping Service",
    size: 150,
  },
  {
    accessorKey: "COMMENTS", //access nested data with dot notation
    header: "Comments",
    size: 50,
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const EIConnections = () => {
  const {
    eiConnections,
    fetchEIConenctionsFromAllEnv,
    REACT_APP_ENV,
    envColumn1,
    envColumn2,
    openPromoteModal,
    setOpenPromoteModal,
    openDetailForm,
    editable,
    envCell,
    curRow,
    handleClickOpenDetail,
    handleDetailClose,
    onEditClick,
    handleClickOpen,
    handleClose,
    detailViewBoxStyle,
  } = useDataView();

  let currentEnvConfig = [];
  let devConfigs = [];
  let testConfigs = [];
  let prodConfigs = [];
  let detailData = null;

  const handleConfirm = () => {
    const payload = [
      {
        CONNECTION_ID: envCell.current.row.original.CONNECTION_ID,
        CONNECTION_TYPE: envCell.current.row.original.CONNECTION_TYPE,
        PROJECT_ID: envCell.current.row.original.PROJECT_ID,
        PROJECT_OID: envCell.current.row.original.PROJECT_OID,
        PROJECT_NAME: envCell.current.row.original.PROJECT_NAME,
        SPONSOR_NAME: envCell.current.row.original.SPONSOR_NAME,
        BUSINESS_ID: envCell.current.row.original.BUSINESS_ID,
        CONNECTION_URL: envCell.current.row.original.CONNECTION_URL,
        CONNECTION_PROVIDER: envCell.current.row.original.CONNECTION_PROVIDER,
        CONNECTION_DATA: envCell.current.row.original.CONNECTION_DATA,
        AUTH_TYPE: envCell.current.row.original.AUTH_TYPE,
        AUTH_ID: envCell.current.row.original.AUTH_ID,
        AUTH_PIN: envCell.current.row.original.AUTH_PIN,
        AUTH_PASSCODE: envCell.current.row.original.AUTH_PASSCODE,
        STATE: "Pending",
        MAX_ROWS_PER_QUERY: envCell.current.row.original.MAX_ROWS_PER_QUERY,
        MAX_QUEERIES: envCell.current.row.original.MAX_QUEERIES,
        PING_SERVICE: envCell.current.row.original.PING_SERVICE,
        COMMENTS: envCell.current.row.original.COMMENTS,
      },
    ];

    const env = envCell.current.column.id;
    const cmdCount = envCell.current.row.original.CmdCount;
    const connectionID = envCell.current.row.original.CONNECTION_ID;

    onSubmit(payload, env, cmdCount, connectionID);
    setOpenPromoteModal(false);
  };

  const onSubmit = (payload, env, cmdCount, connectionID) => {
    const resourcePath = "integrations/cfg/connections";

    API.AddRecordToOtherEnv(resourcePath, env, payload).then((response) => {
      if (response.code === 200) {
        const resourcePath =
          "integrations/cfg/transfer/" + connectionID + "/cmds";

        if (parseInt(cmdCount) > 0) {
          API.GetDataFromAPI(resourcePath).then((data) => {
            data.map((el) => {
              delete el["_pkTransferCmd"];
              delete el["pkTransferCmd"];
              delete el["CREATED_BY"];
              delete el["CREATED_TS"];
              return el;
            });

            if (data !== null) {
              API.AddRecordToOtherEnv(resourcePath, env, data).then(
                (response) => {
                  if (response.code === 200) {
                    NotificationManager.success(
                      "Record added to " + env + " env",
                      "EI Connection",
                      10000
                    );
                    fetchEIConenctionsFromAllEnv();
                  } else {
                    NotificationManager.error(
                      response.message,
                      "Only connection is promoted. Commands failed!",
                      10000
                    );
                  }
                }
              );
            }
          });
        } else {
          NotificationManager.success(
            "Record added to " + env + " env",
            "EI Connection",
            10000
          );
          fetchEIConenctionsFromAllEnv();
        }
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  // const handleStateToggle = ({ row }) => {
  //   const payload = [
  //     {
  //       _CONNECTION_ID: row.original._CONNECTION_ID,
  //       STATE: row.original.STATE,
  //     },
  //   ];

  //   const resourcePath = "integrations/cfg/connections";

  //   API.UpdateRecord(resourcePath, payload).then((response) => {
  //     if (response.code === 200) {
  //       fetchEIConenctionsFromAllEnv();
  //       NotificationManager.success("State updated!", "Connection", 10000);
  //     } else {
  //       NotificationManager.error(response.message, "Failed!", 10000);
  //     }
  //   });
  // };

  function customCellAction() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === "N/A") {
        return (
          <ConfirmPromoteDialog
            cell={cell}
            open={openPromoteModal}
            envCell={envCell}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        );
      }
      return cellValue;
    };
  }

  // function handleStateAnimation() {
  //   return function ({ cell, row }) {
  //     const cellValue = cell.getValue();
  //     let switchOn = false;

  //     if (cellValue === "Active") {
  //       switchOn = true;
  //     }

  //     return (
  //       <Switch
  //         checked={switchOn}
  //         //onChange={handleStateToggle({ row })}
  //         inputProps={{ "aria-label": "controlled" }}
  //       />
  //     );
  //   };
  // }

  const mainColumns = [
    {
      accessorKey: "CONNECTION_ID", //access nested data with dot notation
      header: "ID",
      size: 300,
      Cell: viewDetails(),
    },
    {
      id: "CmdCount",
      accessorKey: "CmdCount", //normal accessorKey
      header: "CmdCount",
      size: 150,
      enableEditing: false,

      Cell: ({ row }) => {
        return (
          <Link to={"/ConnectionCommands/" + row.original._CONNECTION_ID}>
            {row.original.CmdCount}
          </Link>
        );
      },
    },
    {
      accessorKey: "STATE", //access nested data with dot notation
      header: "Status",
      size: 100,
      //Cell: handleStateAnimation(),
    },
    {
      accessorKey: "envColumn1",
      header: envColumn1,
      id: envColumn1,
      size: 150,
      Cell: customCellAction(),
    },
    {
      accessorKey: "envColumn2", //access nested data with dot notation
      header: envColumn2,
      id: envColumn2,
      size: 150,
      Cell: customCellAction(),
    },
  ];

  function viewDetails() {
    return function ({ cell, row }) {
      const cellValue = cell.getValue();
      const tooltip = "View Details";

      return (
        <>
          <ViewDetailsIcon
            row={row}
            tooltip={tooltip}
            onClick={handleClickOpenDetail}
          />
          {cellValue}

          <Modal
            open={openDetailForm}
            onClose={handleClickOpenDetail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            hideBackdrop
            paperprops={{
              elevation: 0,
              sx: {
                border: "solid 1px gray",
                boxShadow: "none",
              },
            }}
            sx={{
              overflow: "scroll",
              paddingLeft: "20%",
            }}
          >
            <Box sx={detailViewBoxStyle}>
              <ViewEditConnection
                curRow={curRow.current}
                handleDetailClose={handleDetailClose}
                editable={editable}
                onEditClick={onEditClick}
                data={detailData}
              />
            </Box>
          </Modal>
        </>
      );
    };
  }

  useEffect(() => {
    (async () => {
      if (eiConnections.length === 0) await fetchEIConenctionsFromAllEnv();
    })();
    // eslint-disable-next-line
  }, []);

  if (eiConnections.length > 0) {
    if (REACT_APP_ENV === "DEV") {
      currentEnvConfig = eiConnections[0];
    } else if (REACT_APP_ENV === "TEST") {
      currentEnvConfig = eiConnections[1];
    } else if (REACT_APP_ENV === "PROD") {
      currentEnvConfig = eiConnections[2];
    } else {
      currentEnvConfig = eiConnections[0];
    }

    devConfigs = eiConnections[0];
    testConfigs = eiConnections[1];
    prodConfigs = eiConnections[2];

    detailData = currentEnvConfig.map((connection) => {
      return {
        _CONNECTION_ID: connection._CONNECTION_ID,
        CONNECTION_ID: connection.CONNECTION_ID,
        CONNECTION_TYPE: connection.CONNECTION_TYPE,
        PROJECT_ID: connection.PROJECT_ID,
        PROJECT_OID: connection.PROJECT_OID,
        PROJECT_NAME: connection.PROJECT_NAME,
        SPONSOR_NAME: connection.SPONSOR_NAME,
        BUSINESS_ID: connection.BUSINESS_ID,
        CONNECTION_URL: connection.CONNECTION_URL,
        CONNECTION_PROVIDER: connection.CONNECTION_PROVIDER,
        CONNECTION_DATA: connection.CONNECTION_DATA,
        AUTH_TYPE: connection.AUTH_TYPE,
        AUTH_ID: connection.AUTH_ID,
        AUTH_PIN: connection.AUTH_PIN,
        AUTH_PASSCODE: connection.AUTH_PASSCODE,
        STATE: connection.STATE,
        MAX_ROWS_PER_QUERY: connection.MAX_ROWS_PER_QUERY,
        MAX_QUERIES: connection.MAX_QUERIES,
        PING_SERVICE: connection.PING_SERVICE,
        COMMENTS: connection.COMMENTS,
        CmdCount: connection._cmdCount_,
      };
    });
  }

  let loading = false;
  if (currentEnvConfig.length === 0) {
    loading = true;
  } else {
    loading = false;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="LIST of CONNECTIONS" loading={loading} />;
  }

  return (
    <div className="cache-table">
      <h2 style={h2Style}>LIST of CONNECTIONS</h2>

      {currentEnvConfig.length > 0 && (
        <EIConnectionViewTable
          data={currentEnvConfig.map((connection) => {
            const _CONNECTION_ID = connection._CONNECTION_ID;
            const col1 = getColumnValue(
              envColumn1,
              devConfigs,
              _CONNECTION_ID,
              testConfigs,
              prodConfigs
            );

            const col2 = getColumnValue(
              envColumn2,
              devConfigs,
              _CONNECTION_ID,
              testConfigs,
              prodConfigs
            );

            return {
              _CONNECTION_ID: connection._CONNECTION_ID,
              CONNECTION_ID: connection.CONNECTION_ID,
              CONNECTION_TYPE: connection.CONNECTION_TYPE,
              PROJECT_ID: connection.PROJECT_ID,
              PROJECT_OID: connection.PROJECT_OID,
              PROJECT_NAME: connection.PROJECT_NAME,
              SPONSOR_NAME: connection.SPONSOR_NAME,
              BUSINESS_ID: connection.BUSINESS_ID,
              CONNECTION_URL: connection.CONNECTION_URL,
              CONNECTION_PROVIDER: connection.CONNECTION_PROVIDER,
              CONNECTION_DATA: connection.CONNECTION_DATA,
              AUTH_TYPE: connection.AUTH_TYPE,
              AUTH_ID: connection.AUTH_ID,
              AUTH_PIN: connection.AUTH_PIN,
              AUTH_PASSCODE: connection.AUTH_PASSCODE,
              STATE: connection.STATE,
              MAX_ROWS_PER_QUERY: connection.MAX_ROWS_PER_QUERY,
              MAX_QUERIES: connection.MAX_QUERIES,
              PING_SERVICE: connection.PING_SERVICE,
              COMMENTS: connection.COMMENTS,
              CmdCount: connection._cmdCount_,
              envColumn1: col1,
              envColumn2: col2,
            };
          })}
          columns={columns}
          mainColumns={mainColumns}
        />
      )}
    </div>
  );
};

export default EIConnections;

function getColumnValue(
  envColumn,
  devConfigs,
  _CONNECTION_ID,
  testConfigs,
  prodConfigs
) {
  let checkConfigExists = false;

  if (envColumn === "Dev") {
    checkConfigExists = devConfigs.filter((config) => {
      return config._CONNECTION_ID === _CONNECTION_ID;
    });
  } else if (envColumn === "Test") {
    checkConfigExists = testConfigs.filter((config) => {
      return config._CONNECTION_ID === _CONNECTION_ID;
    });
  } else if (envColumn === "Prod") {
    checkConfigExists = prodConfigs.filter((config) => {
      return config._CONNECTION_ID === _CONNECTION_ID;
    });
  }

  if (checkConfigExists.length > 0) {
    return checkConfigExists[0].STATE;
  }

  return "N/A";
}
