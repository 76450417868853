import React from "react";
import XMLViewer from "react-xml-viewer";
import { formatDialect, transactsql } from "sql-formatter";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const FormatText = ({ columnID, detailText }) => {
  let firstChar = detailText.substring(0, 1);

  if (
    columnID === "PropertyValue" ||
    columnID === "Test" ||
    columnID === "Prod" ||
    columnID === "Dev"
  ) {
    const propertyValueArr = [];

    const propValue = detailText.split(",");
    propValue.forEach(function (element) {
      propertyValueArr.push(element.toLowerCase());
    });

    return (
      <div>
        <Autocomplete
          id="PropertyValue"
          name="PropertyValue"
          freeSolo
          multiple
          fullWidth
          readOnly
          options={propertyValueArr}
          value={propertyValueArr}
          renderInput={(params) => (
            <TextField
              {...params}
              id="PropertyValue"
              name="PropertyValue"
              size="large"
              variant="outlined"
              fullWidth
              sx={{
                paddingRight: "15px",
                width: "100%",
                border: "none",
                "& fieldset": { border: "none" },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
              }}
            />
          )}
        />
      </div>
    );
  }

  if (firstChar === "<") {
    return (
      <div>
        <XMLViewer xml={detailText} />
      </div>
    );
  } else if (firstChar === "{") {
    return <div>{detailText}</div>;
  } else {
    return (
      <div>
        <pre>{formatDialect(detailText, { dialect: transactsql })}</pre>
      </div>
    );
  }
};

export default FormatText;
