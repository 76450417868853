import React, { useRef } from "react";
import { MaterialReactTable } from "material-react-table";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { Box, TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useDataView } from "../../contexts/data.view.context";

const ServiceConfigViewTable = ({ data, mainColumns, emailList }) => {
  const { fetchServiceConfigsFromAllEnv } = useDataView();

  const validationSchema = Yup.object().shape({
    ServiceName: Yup.string().required("Service Name is required"),
    PropertyName: Yup.string().required("Property NAme is required"),
    //PropertyValue: Yup.string().required("Property Value is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const propertValRef = useRef(null);
  const autoC = useRef(null);

  const handleChange = ({ e, values }) => {
    propertValRef.current = values;
  };

  const onSubmit = (payload) => {
    const propertyValueArr = propertValRef.current;
    let PropertyValue = "";

    if (propertyValueArr.length > 1) {
      PropertyValue = propertyValueArr.join(",");
    } else {
      PropertyValue = propertyValueArr[0];
    }

    const resourcePath = "integrations/cfg/serviceconfigs";

    const simplePayload = [
      {
        ServiceName: payload.ServiceName,
        PropertyName: payload.PropertyName,
        PropertyValue: PropertyValue,
      },
    ];

    API.AddRecord(resourcePath, simplePayload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Service Config", 10000);
        fetchServiceConfigsFromAllEnv();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
    reset();
    //clear the autocomplete since reset only work for pure text fields
    const ele = autoC.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  return (
    <MaterialReactTable
      columns={mainColumns}
      data={data}
      enableStickyHeader
      enableStickyFooter
      initialState={{
        density: "compact",
      }}
      renderTopToolbarCustomActions={() => (
        <>
          <form onSubmit={handleSubmit(onSubmit)} id="ServiceConfigForm">
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                paddingLeft: "15px",
              }}
            >
              <TextField
                required
                id="ServiceName"
                name="ServiceName"
                label="Service Name"
                size="small"
                {...register("ServiceName")}
                error={errors.ServiceName ? true : false}
                sx={{
                  paddingRight: "15px",
                  width: "300px",
                }}
              />

              <TextField
                required
                id="PropertyName"
                name="PropertyName"
                label="Property Name"
                size="small"
                {...register("PropertyName")}
                error={errors.PropertyName ? true : false}
                variant="outlined"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "left",
                  paddingRight: "15px",
                  width: "300px",
                }}
              />

              <Controller
                name="PropertyValue"
                id="PropertyValue"
                label="Property Value"
                control={control}
                setValue={setValue}
                render={({ onChange, ...props }) => (
                  <Autocomplete
                    id="PropertyValue"
                    name="PropertyValue"
                    freeSolo
                    multiple
                    onChange={(e, values) => handleChange({ e, values })}
                    getOptionLabel={(option) => option}
                    options={emailList}
                    ref={autoC}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // required
                        id="PropertyValue"
                        name="PropertyValue"
                        label="Property Value"
                        size="small"
                        {...register("PropertyValue")}
                        error={errors.PropertyValue ? true : false}
                        variant="outlined"
                        sx={{
                          paddingRight: "15px",
                          width: "500px",
                        }}
                      />
                    )}
                  />
                )}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{
                  size: "small",
                  height: "40px",
                  top: "0px",
                  left: "20px",
                }}
              >
                {"SAVE"}
              </Button>
            </Box>
          </form>
        </>
      )}
      autoResetPageIndex={false}
    />
  );
};

export default ServiceConfigViewTable;
