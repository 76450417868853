import React, { Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { Paper, Box, TextField, Typography, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useDataView } from "../../contexts/data.view.context";

const OuraPatientForm = () => {
  const validationSchema = Yup.object().shape({
    PROJECT_ID: Yup.string().required("Project ID is required"),
    SITE_ID: Yup.string().required("Site ID is required"),
    PATIENT_ID: Yup.string().required("Patient ID is required"),
    INFORMED_CONSENT_DATE: Yup.string().required(
      "Informed Consent date is required"
    ),
    STATE: Yup.string().required("State is required"),
    AGE: Yup.string().nullable().notRequired().default(""),
    WEIGHT: Yup.string().nullable().notRequired().default(""),
    HEIGHT: Yup.string().nullable().notRequired().default(""),
    GENDER: Yup.string().nullable().notRequired().default(""),
    PROTOCOL_ID: Yup.string().required("Protocol ID is required"),
    STUDY_ID: Yup.string().required("Connection Data is required"),
    SPONSOR: Yup.string().required("Auth Type is required"),
    TOKEN: Yup.string().required("Auth ID is required"),
    COMMENTS: Yup.string().required("Auth PIN is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const { fetchOuraPatients } = useDataView();

  const stateItems = ["Active", "Disabled"];

  const onSubmit = (data) => {
    data.INFORMED_CONSENT_DATE = dayjs(data.INFORMED_CONSENT_DATE).format(
      "YYYY-MM-DD"
    );

    const resourcePath = "incyte/oura/onboard";
    API.AddOuraPatient(resourcePath, data).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Oura patient", 10000);
        fetchOuraPatients();
        navigate("/OuraPatients");
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
          }}
        ></Box>

        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            <Typography variant="h6" paddingLeft="10px" margin="dense">
              ADD NEW PATIENT - OURA RING
            </Typography>

            <div>
              <TextField
                required
                id="PROJECT_ID"
                name="PROJECT_ID"
                label="PROJECT_ID"
                margin="dense"
                size="small"
                {...register("PROJECT_ID")}
                error={errors.PROJECT_ID ? true : false}
                variant="outlined"
                defaultValue="INCY216559"
              />
              <br />

              <TextField
                required
                id="PROTOCOL_ID"
                name="PROTOCOL_ID"
                label="PROTOCOL_ID"
                margin="dense"
                size="small"
                {...register("PROTOCOL_ID")}
                error={errors.PROTOCOL_ID ? true : false}
                variant="outlined"
                defaultValue="INCB 18424-902"
              />
              <br />
              <TextField
                required
                id="STUDY_ID"
                name="STUDY_ID"
                label="STUDY_ID"
                margin="dense"
                size="small"
                {...register("STUDY_ID")}
                error={errors.STUDY_ID ? true : false}
                variant="outlined"
                defaultValue="Incyte_Ph4_AD_Sleep_Study"
              />
              <br />
              <TextField
                required
                id="SPONSOR"
                name="SPONSOR"
                label="SPONSOR"
                margin="dense"
                size="small"
                {...register("SPONSOR")}
                error={errors.SPONSOR ? true : false}
                variant="outlined"
                defaultValue="INCYTE"
              />
              <br />
              <TextField
                required
                id="SITE_ID"
                name="SITE_ID"
                label="SITE_ID"
                margin="dense"
                size="small"
                {...register("SITE_ID")}
                error={errors.SITE_ID ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="PATIENT_ID"
                name="PATIENT_ID"
                label="PATIENT_ID"
                margin="dense"
                size="small"
                {...register("PATIENT_ID")}
                error={errors.PATIENT_ID ? true : false}
                variant="outlined"
              />
              <br />
              {/* <TextField
                required
                id="STATE"
                name="STATE"
                label="STATE"
                margin="dense"
                size="small"
                {...register("STATE")}
                error={errors.STATE ? true : false}
                variant="outlined"
              /> */}
              <TextField
                select
                label="Patient Status"
                id="STATE"
                margin="dense"
                size="small"
                {...register("STATE")}
                error={errors.STATE ? true : false}
                variant="outlined"
              >
                {stateItems.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <br />
            <div>
              <TextField
                required
                id="TOKEN"
                name="TOKEN"
                label="TOKEN"
                margin="dense"
                size="small"
                {...register("TOKEN")}
                error={errors.TOKEN ? true : false}
                variant="outlined"
              />
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  control={control}
                  name="INFORMED_CONSENT_DATE"
                  render={({
                    field: {
                      ref,
                      onBlur,
                      name,
                      margin,
                      size,
                      variant,
                      ...field
                    },
                    fieldState,
                  }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label="INFORMED_CONSENT_DATE"
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "INFORMED_CONSENT_DATE",
                          margin: "dense",
                          variant: "outlined",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <br />

              <TextField
                required
                id="COMMENTS"
                name="COMMENTS"
                label="COMMENTS"
                margin="dense"
                size="small"
                {...register("COMMENTS")}
                error={errors.COMMENTS ? true : false}
                variant="outlined"
              />
            </div>

            <Box mt={3} paddingLeft="10px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default OuraPatientForm;
