import React from "react";
import { MaterialReactTable } from "material-react-table";

const EICacheViewTable = ({ data, columns }) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      initialState={{
        density: "compact",
      }}
      autoResetPageIndex={false}
      enableFilters={false}
      enableDensityToggle={false}
      enableFiltersToggle={false}
      enableSortingRemoval={false}
      enableshowFilter={false}
      enableFullScreenToggle={false}
      enableColumnFilters={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableColumnOrdering={false}
      enableMultiSort={false}
      enableRowOrdering={false}
      enableColumnFilterModes={false}
      enableSorting={false}
    />
  );
};

export default EICacheViewTable;
