import React, { useEffect } from "react";
import FileTransferViewTable from "./fileTransferView";
import { Link } from "react-router-dom";
import "../util/tables.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import { useDataView } from "../../contexts/data.view.context";
import ViewEditFileTransfer from "./viewEditFileTransfer";
import Modal from "@mui/material/Modal";
import ShowLoadingBar from "../util/showLoadingBar";
import ViewDetailsIcon from "../util/detailsModal/viewDetailsIcon";
import ConfirmPromoteDialog from "../util/confirmPromoteDialog";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";

const columns = [
  {
    id: "TRANSFER_ID",
    accessorKey: "TRANSFER_ID", //access nested data with dot notation
    header: "Transfer ID",
    size: 250,
    enableEditing: false,
    enableHiding: true,
  },
  {
    id: "PROJECT_ID",
    accessorKey: "PROJECT_ID",
    header: "Project ID",
    size: 150,
  },

  {
    accessorKey: "STATE",
    header: "State",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalState = cell.row.original.STATE;
      const [stateValue, setState] = React.useState(originalState);

      const handleChange = (event) => {
        cell.row._valuesCache.STATE = event.target.value;
        setState(event.target.value);
      };

      const items = ["Active", "Closed", "Pending", "Locked"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              State
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={stateValue}
              defaultValue={originalState}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "TRANSFER_TYPE",
    header: "Transfer Type",
    size: 150,
  },
  {
    accessorKey: "RETENTION_DAYS",
    header: "Retention Days",
    size: 150,
  },
  {
    accessorKey: "COMMENTS",
    header: "Comments",
    size: 150,
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const FileTransfers = () => {
  const {
    fileTransfers,
    fetchFileTransfersFromAllEnv,
    REACT_APP_ENV,
    envColumn1,
    envColumn2,
    openDetailForm,
    editable,
    curRow,
    handleClickOpenDetail,
    handleDetailClose,
    onEditClick,
    detailViewBoxStyle,
    openPromoteModal,
    setOpenPromoteModal,
    handleClickOpen,
    handleClose,
    envCell,
  } = useDataView();

  let currentEnvConfig = [];
  let devConfigs = [];
  let testConfigs = [];
  let prodConfigs = [];

  const mainColumns = [
    {
      id: "TRANSFER_ID",
      accessorKey: "TRANSFER_ID", //access nested data with dot notation
      header: "Transfer ID",
      size: 250,
      enableEditing: false,
      enableHiding: true,
    },
    {
      id: "PROJECT_ID",
      accessorKey: "PROJECT_ID",
      header: "Project ID",
      size: 150,
      Cell: viewDetails(),
    },
    {
      accessorKey: "TRANSFER_TYPE",
      header: "Transfer Type",
      size: 150,
    },
    {
      id: "COMMANDS",
      accessorKey: "COMMANDS", //normal accessorKey
      header: "No of Commands",
      size: 200,
      enableEditing: false,
      //accessorFn: (row) => row,
      Cell: ({ row }) => {
        //const row = cell.getValue();
        return (
          <Link to={"/FileCommands/" + row.original.TRANSFER_ID}>
            {row.original.COMMANDS}
          </Link>
        );
      },
    },
    {
      accessorKey: "STATE",
      header: "State",
      size: 150,
    },
    {
      id: envColumn1,
      accessorKey: "envColumn1",
      header: envColumn1,
      size: 150,
      Cell: customCellAction(),
    },
    {
      id: envColumn2,
      accessorKey: "envColumn2", //access nested data with dot notation
      header: envColumn2,
      size: 150,
      Cell: customCellAction(),
    },
  ];

  let detailData = null;

  function viewDetails() {
    return function ({ cell, row }) {
      const cellValue = cell.getValue();
      const tooltip = "View Details";

      return (
        <>
          <ViewDetailsIcon
            row={row}
            tooltip={tooltip}
            onClick={handleClickOpenDetail}
          />
          {cellValue}

          <Modal
            open={openDetailForm}
            onClose={handleClickOpenDetail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            hideBackdrop
            paperprops={{
              elevation: 0,
              sx: {
                border: "solid 1px gray",
                boxShadow: "none",
              },
            }}
            sx={{
              overflow: "scroll",
              paddingLeft: "20%",
              paddingTop: "10%",
            }}
          >
            <Box sx={detailViewBoxStyle}>
              <ViewEditFileTransfer
                curRow={curRow.current}
                handleDetailClose={handleDetailClose}
                editable={editable}
                onEditClick={onEditClick}
                data={detailData}
              />
            </Box>
          </Modal>
        </>
      );
    };
  }

  function customCellAction() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === "N/A") {
        return (
          <ConfirmPromoteDialog
            cell={cell}
            open={openPromoteModal}
            envCell={envCell}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        );
      }
      return cellValue;
    };
  }

  const handleConfirm = () => {
    const payload = [
      {
        TRANSFER_ID: envCell.current.row.original.TRANSFER_ID,
        PROJECT_ID: envCell.current.row.original.PROJECT_ID,
        STATE: "Pending",
        TRANSFER_TYPE: envCell.current.row.original.TRANSFER_TYPE,
        RETENTION_DAYS: envCell.current.row.original.RETENTION_DAYS,
        COMMENTS: envCell.current.row.original.COMMENTS,
      },
    ];

    const env = envCell.current.column.id;
    const cmdCount = envCell.current.row.original.COMMANDS;
    const transID = envCell.current.row.original.TRANSFER_ID;

    onSubmit(payload, env, cmdCount, transID);
    setOpenPromoteModal(false);
  };

  const onSubmit = (payload, env, cmdCount, transID) => {
    const resourcePath = "integrations/cfg/transfers";

    API.AddRecordToOtherEnv(resourcePath, env, payload).then((response) => {
      if (response.code === 200) {
        const resourcePath = "integrations/cfg/transfer/" + transID + "/cmds";

        if (parseInt(cmdCount) > 0) {
          API.GetDataFromAPI(resourcePath).then((data) => {
            data.map((el) => {
              delete el["_pkTransferCmd"];
              delete el["pkTransferCmd"];
              delete el["CREATED_BY"];
              delete el["CREATED_TS"];
              return el;
            });

            if (data !== null) {
              API.AddRecordToOtherEnv(resourcePath, env, data).then(
                (response) => {
                  if (response.code === 200) {
                    NotificationManager.success(
                      "Record added to " + env + " env",
                      "File Transfer",
                      10000
                    );
                    fetchFileTransfersFromAllEnv();
                  } else {
                    NotificationManager.error(
                      response.message,
                      "Only File Transfer is promoted. Commands failed!",
                      10000
                    );
                  }
                }
              );
            }
          });
        } else {
          NotificationManager.success(
            "Record added to " + env + " env",
            "File Transfer",
            10000
          );
          fetchFileTransfersFromAllEnv();
        }
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  useEffect(() => {
    (async () => {
      if (fileTransfers.length === 0) await fetchFileTransfersFromAllEnv();
    })();
    // eslint-disable-next-line
  }, []);

  if (fileTransfers.length > 0) {
    if (REACT_APP_ENV === "DEV") {
      currentEnvConfig = fileTransfers[0];
    } else if (REACT_APP_ENV === "TEST") {
      currentEnvConfig = fileTransfers[1];
    } else if (REACT_APP_ENV === "PROD") {
      currentEnvConfig = fileTransfers[2];
    } else {
      currentEnvConfig = fileTransfers[0];
    }

    devConfigs = fileTransfers[0];
    testConfigs = fileTransfers[1];
    prodConfigs = fileTransfers[2];

    detailData = currentEnvConfig.map((file) => {
      return {
        _pkTransfer: file._pkTransfer,
        TRANSFER_ID: file.TRANSFER_ID,
        PROJECT_ID: file.PROJECT_ID,
        COMMANDS: file.COMMANDS,
        STATE: file.STATE,
        TRANSFER_TYPE: file.TRANSFER_TYPE,
        RETENTION_DAYS: file.RETENTION_DAYS,
        COMMENTS: file.COMMENTS,
      };
    });
  }

  let loading = false;
  if (currentEnvConfig.length === 0) {
    loading = true;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="LIST DATA TRANSFER" loading={loading} />;
  }

  return (
    <>
      <div className="cache-table">
        <h2 style={h2Style}>LIST DATA TRANSFER (Projects)</h2>

        {currentEnvConfig.length > 0 && (
          <FileTransferViewTable
            columns={columns}
            data={currentEnvConfig.map((connection) => {
              const TRANSFER_ID = connection.TRANSFER_ID;
              const col1 = getColumnValue(
                envColumn1,
                devConfigs,
                TRANSFER_ID,
                testConfigs,
                prodConfigs
              );
              const col2 = getColumnValue(
                envColumn2,
                devConfigs,
                TRANSFER_ID,
                testConfigs,
                prodConfigs
              );

              return {
                _pkTransfer: connection._pkTransfer,
                TRANSFER_ID: connection.TRANSFER_ID,
                PROJECT_ID: connection.PROJECT_ID,
                COMMANDS: connection._cmdCount_,
                STATE: connection.STATE,
                TRANSFER_TYPE: connection.TRANSFER_TYPE,
                RETENTION_DAYS: connection.RETENTION_DAYS,
                COMMENTS: connection.COMMENTS,
                envColumn1: col1,
                envColumn2: col2,
                COMMANDS_TEXT: connection.COMMANDS,
              };
            })}
            mainColumns={mainColumns}
          />
        )}
      </div>
    </>
  );
};

export default FileTransfers;

function getColumnValue(
  envColumn,
  devConfigs,
  TRANSFER_ID,
  testConfigs,
  prodConfigs
) {
  let checkConfigExists = [];

  if (envColumn === "Dev") {
    checkConfigExists = devConfigs.filter((config) => {
      return config.TRANSFER_ID === TRANSFER_ID;
    });
  } else if (envColumn === "Test") {
    checkConfigExists = testConfigs.filter((config) => {
      return config.TRANSFER_ID === TRANSFER_ID;
    });
  } else if (envColumn === "Prod") {
    checkConfigExists = prodConfigs.filter((config) => {
      return config.TRANSFER_ID === TRANSFER_ID;
    });
  }

  if (checkConfigExists.length > 0) {
    return checkConfigExists[0].STATE;
  }

  return "N/A";
}
