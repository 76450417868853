import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { useDataView } from "../../contexts/data.view.context";

import { Paper, Box, TextField, Typography, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const FileTransferForm = () => {
  const validationSchema = Yup.object().shape({
    //TRANSFER_ID: Yup.string().required("Transfer ID is required"),
    PROJECT_ID: Yup.string().required("Project ID is required"),
    STATE: Yup.string().required("State is required"),
    TRANSFER_TYPE: Yup.string().required("Transfer Type is required"),
    RETENTION_DAYS: Yup.string().required("Retention Days is required"),
    COMMENTS: Yup.string().nullable().notRequired().default(""),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fetchFileTransfersFromAllEnv } = useDataView();
  const navigate = useNavigate();
  const stateItems = ["Active", "Closed", "Pending", "Locked"];

  // const onClickBackButton = () => {
  //   navigate(-1);
  // };

  const onSubmit = (data) => {
    data.TRANSFER_ID = "DT_" + data.PROJECT_ID;
    const resourcePath = "integrations/cfg/transfers";

    API.AddRecord(resourcePath, data).then((response) => {
      if (response.code === 200) {
        const transfer_seq = 0;
        const params = {
          transferID: data.TRANSFER_ID,
          transfer_seq: transfer_seq,
        };
        NotificationManager.success("Record added!", "File Transfer", 10000);
        fetchFileTransfersFromAllEnv();
        navigate("/AddTransferCmd", { state: params });
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            {/* <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              align="left"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                paddingLeft: "15px",
                width: "100px",
              }}
            >
              {"BACK"}
            </Link> */}
            <Typography variant="h6" paddingLeft="10px" margin="dense">
              Add File Transfer
            </Typography>

            <div>
              {/* <TextField
                required
                id="TRANSFER_ID"
                name="TRANSFER_ID"
                label="Transfer ID"
                margin="dense"
                size="small"
                {...register("TRANSFER_ID")}
                error={errors.TRANSFER_ID ? true : false}
                variant="outlined"
              />
              <br /> */}
              <TextField
                required
                id="PROJECT_ID"
                name="PROJECT_ID"
                label="Project ID"
                margin="dense"
                size="small"
                {...register("PROJECT_ID")}
                error={errors.PROJECT_ID ? true : false}
                variant="outlined"
              />
              <br />
              {/* <TextField
                required
                id="STATE"
                name="STATE"
                label="State"
                margin="dense"
                size="small"
                {...register("STATE")}
                error={errors.STATE ? true : false}
                variant="outlined"
              /> */}

              <TextField
                select
                label="State"
                id="STATE"
                margin="dense"
                size="small"
                {...register("STATE")}
                error={errors.STATE ? true : false}
                variant="outlined"
                defaultValue="Active"
              >
                {stateItems.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="TRANSFER_TYPE"
                name="TRANSFER_TYPE"
                label="Transfer Type"
                margin="dense"
                size="small"
                {...register("TRANSFER_TYPE")}
                error={errors.TRANSFER_TYPE ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="RETENTION_DAYS"
                name="RETENTION_DAYS"
                label="Retention Days"
                margin="dense"
                size="small"
                {...register("RETENTION_DAYS")}
                error={errors.RETENTION_DAYS ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="COMMENTS"
                name="COMMENTS"
                label="Comments"
                margin="dense"
                size="small"
                {...register("COMMENTS")}
                error={errors.COMMENTS ? true : false}
                variant="outlined"
                style={{ width: 600 }}
              />
            </div>

            <Box mt={3} paddingLeft="10px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default FileTransferForm;
