import React from "react";
import AppBar from "@mui/material/AppBar";
import "./appbar.css";

const MainAppBar = ({ children }) => {
  return (
    <AppBar position="fixed" style={{ background: "#004080" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100px",
          padding: "0 2em",
          margin: "0.5em 0",
        }}
      >
        {children}
      </div>
    </AppBar>
  );
};

export default MainAppBar;
