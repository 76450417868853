import React from "react";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "./user.css";

const UserMenu = ({ setIsSignedIn }) => {
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const loggedInUser = currentUser.firstName + " " + currentUser.lastName;
  const navigate = useNavigate();

  return (
    <Container>
      <IconButton>
        <AccountCircle className="avatar" />
      </IconButton>
      <Select
        value="placeholder"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "60px",
          color: "#FFFFFF",
        }}
        variant="standard"
        disableUnderline
      >
        <MenuItem value="placeholder" disabled>
          {loggedInUser}
        </MenuItem>
        <MenuItem
          onClick={() => {
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("mainNavIndex");
            sessionStorage.removeItem("subNavIndex");

            setIsSignedIn(false);
            navigate("/");
          }}
        >
          Sign Out
        </MenuItem>
      </Select>
    </Container>
  );
};

export default UserMenu;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  variant: standard;
`;
