import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { Link, Box } from "@mui/material";
import * as API from "../api/index";

const QueryScheduleTaskViewTable = (tableData) => {
  const [data, setData] = useState(tableData.data);

  const navigate = useNavigate();

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    data[row.index] = values;
    const schedule_id = tableData.dataParams.schedule_id;

    const payload = [
      {
        _schedule_id: schedule_id,
        _task_no: values.task_no,
        query_id: values.query_id,
        query_params: values.query_params,
        entity: values.entity,
        service_name: values.service_name,
        task_state: values.task_state,
      },
    ];

    const resourcePath = "integrations/cfg/schedule/" + schedule_id + "/tasks";

    API.UpdateRecord(resourcePath, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success(
          "Record Updated",
          "Query Schedule Task",
          10000
        );
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });

    setData([...data]);
    exitEditingMode(); //required to exit editing mode
  };

  function onClick() {
    navigate("/AddQueryScheduleTask", { state: tableData.dataParams });
  }

  function onClickBackButton() {
    navigate(-1);
  }

  const handleRowEditCancel = ({ row, table }) => {
    //avoid setting invalid state on cancel edit
    setData([...data]);
  };

  return (
    <MaterialReactTable
      columns={tableData.columns}
      data={data}
      editingMode="modal"
      enableEditing
      onEditingRowSave={handleSaveRow}
      onEditingRowCancel={handleRowEditCancel}
      initialState={{
        density: "compact",
      }}
      enableStickyHeader
      enableStickyFooter
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              //left: "15px",
            }}
          >
            <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                paddingLeft: "15px",
                width: "30px",
              }}
            >
              {"BACK"}
            </Link>

            <Link
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                //margin: "15px",
                paddingLeft: "15px",
                width: "200px",
              }}
            >
              {"ADD TASK"}
            </Link>
          </Box>
        </>
      )}
      //autoResetPageIndex={false}
      //enableRowOrdering
      //enableSorting={false}
      // muiTableBodyRowDragHandleProps={({ table }) => ({
      //   onDragEnd: () => {
      //     console.log("onDragEnd");
      //     const { draggingRow, hoveredRow } = table.getState();
      //     if (hoveredRow && draggingRow) {
      //       data.splice(
      //         hoveredRow.index,
      //         0,
      //         data.splice(draggingRow.index, 1)[0]
      //       );
      //       //console.log(hoveredRow);
      //       const hoveredRowTrnsSeq = hoveredRow.original.task_no;
      //       //const draggingRowTrnsSeq = draggingRow.original.TRANSFER_SEQ;
      //       console.log(hoveredRowTrnsSeq, hoveredRow.index);
      //       //console.log(draggingRowTrnsSeq, draggingRow.index);
      //       draggingRow.original.task_no = hoveredRowTrnsSeq;
      //       //hoveredRow.original.TRANSFER_SEQ = draggingRowTrnsSeq;
      //       if (hoveredRow.index > draggingRow.index) {
      //         for (let i = draggingRow.index; i < hoveredRow.index; i++) {
      //           data[i].task_no = i;
      //         }
      //       } else {
      //         let i = hoveredRow.index + 1;
      //         for (i; i < draggingRow.index + 1; i++) {
      //           data[i].task_no = i;
      //         }
      //       }

      //       setData([...data]);
      //     }
      //   },
      // })}
    />
  );
};

export default QueryScheduleTaskViewTable;
