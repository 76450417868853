import React, { useEffect, useRef, useState } from "react";

import { MaterialReactTable } from "material-react-table";
import "../util/tables.css";
import { useDataView } from "../../contexts/data.view.context";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as API from "../api/index";
import { NotificationManager } from "react-notifications";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ShowLoadingBar from "../util/showLoadingBar";
import ViewDetailsModal from "../util/detailsModal/viewDetailsModal";

const h2Style = {
  paddingLeft: "20px",
};

const QueryInfo = () => {
  const columns = [
    {
      accessorKey: "QueryId",
      header: "Id",
      id: "ID",
    },
    {
      accessorKey: "DataSource",
      header: "DS",
      id: "DataSource",
      size: 50,
    },
    {
      accessorKey: "Entity",
      header: "Entity",
      id: "Entity",
      size: 50,
    },
    {
      accessorKey: "QueryState",
      header: "State",
      id: "QueryState",
      size: 50,
      Cell: showStateIcon(),
    },
    {
      accessorKey: "LastQueryRunDate",
      header: "Last Run Time",
      id: "LastQueryRunDate",
      Cell: changeLastRunDateTime(),
    },
    {
      accessorKey: "LastQueryMaxUpdateDate",
      header: "Max Update Date",
      id: "LastQueryMaxUpdateDate",
      Cell: showNotAvaialableCell(),
    },
    {
      accessorKey: "FullQuery",
      header: "FullQuery",
      id: "FullQuery",
      size: 50,
      Cell: viewFullText("View Full Query"),
    },
    {
      accessorKey: "DefaultValue",
      header: "DefaultValue",
      id: "DefaultValue",
      size: 50,
      Cell: viewFullText("View Default Value"),
    },
    {
      accessorKey: "IncrementalQuery",
      header: "Inc Query",
      id: "IncrementalQuery",
      size: 50,
      Cell: viewFullText("View Full Query"),
    },
    {
      accessorKey: "IdQuery",
      header: "Id Query",
      id: "IdQuery",
      size: 50,
      Cell: viewFullText("View Full Query"),
    },
    {
      accessorKey: "TransformationId",
      header: "Transform Id",
      id: "TransformationId",
    },
  ];

  const { fetchQueryInfo, queryInfo } = useDataView();
  const [open, setOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [value, setValue] = useState(dayjs("2022-04-17"));

  const tableRef = useRef(null);
  const refEnvCell = useRef(null);
  const titleRef = useRef(null);
  const LastQueryRunDateCellInfo = useRef(null);
  const selectedQueryID = useRef(null);

  const style = {
    position: "absolute",
    top: "40%",
    left: "35%",
    width: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 0,
    p: 4,
  };

  useEffect(() => {
    (async () => {
      if (queryInfo.length === 0) await fetchQueryInfo();
    })();
    // eslint-disable-next-line
  }, []);

  const handleClickOpen = ({ cell, table }) => {
    refEnvCell.current = cell.getValue();
    titleRef.current = cell.column.id;
    tableRef.current = table;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDatePicker = ({ dateValueUTC, queryID }) => {
    setOpenDatePicker(true);
    LastQueryRunDateCellInfo.current = dateValueUTC;
    selectedQueryID.current = queryID;
    setValue(dateValueUTC);
  };
  const handleCloseDatePicker = () => setOpenDatePicker(false);

  const handleLastQueryTimeEdit = () => {
    setOpenDatePicker(false);
    const timestamp = dayjs(value).format("YYYY-MM-DDTHH:mm:ss");

    const resourcePath = "integrations/cfg/query/lastrun";
    const payload = [
      {
        _QueryId: selectedQueryID.current,
        LastQueryRunDate: timestamp,
      },
    ];

    API.UpdateRecord(resourcePath, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Successful!", "Query Time Changed", 10000);
        fetchQueryInfo();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  function viewFullText() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === "") {
        return "N/A";
      }

      return (
        <ViewDetailsModal
          cell={cell}
          openDetail={open}
          currentColumnRef={refEnvCell}
          title={titleRef.current}
          handleDetailOpen={handleClickOpen}
          handleDetailClose={handleClose}
        />
      );
    };
  }

  function showNotAvaialableCell() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === undefined || cellValue === null || cellValue === "") {
        return "N/A";
      }

      return cellValue;
    };
  }

  function showStateIcon() {
    return function ({ cell }) {
      const cellValue = cell.getValue();
      let switchOn = false;

      if (cellValue === "Active") {
        switchOn = true;
      }

      return <Switch checked={switchOn} />;
    };
  }

  function changeLastRunDateTime() {
    return function ({ cell, table }) {
      const queryID = cell.row.original.QueryId;
      const queryState = cell.row.original.QueryState;
      let cellValue = cell.getValue();
      const tooltip = "Change last run date";

      if (cellValue === undefined || cellValue === null || cellValue === "") {
        cellValue = "N/A";
      }

      if (queryState !== "Active") {
        return cellValue;
      }

      let dateValue = new Date(cellValue);

      const utcDateTime = dateValue.toLocaleString("en-US", {
        timeZone: "UTC",
      });

      let dateValueUTC = new Date(utcDateTime);

      return (
        <>
          <IconButton
            sx={{ color: "blue" }}
            onClick={() => handleOpenDatePicker({ dateValueUTC, queryID })}
          >
            <Tooltip id="button-report" title={tooltip}>
              <EditIcon sx={{ color: "#5F9EA0" }} />
            </Tooltip>
          </IconButton>
          {cellValue}

          <Modal
            open={openDatePicker}
            onClose={handleCloseDatePicker}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            hideBackdrop
            paperprops={{
              elevation: 0,
              sx: {
                border: "solid 1px gray",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Last Query run date"
                    id="Last_run"
                    defaultValue={LastQueryRunDateCellInfo.current}
                    value={value}
                    ampm={false}
                    onChange={setValue}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "Last Query run date",
                        id: "Last_run",
                        margin: "dense",
                        variant: "outlined",
                        align: "center",
                      },
                    }}
                  />
                </LocalizationProvider>
                <br />
                <div align="right">
                  <IconButton
                    sx={{ color: "blue" }}
                    onClick={handleCloseDatePicker}
                  >
                    <Tooltip id="button-report" title="Close">
                      <CloseIcon sx={{ color: "#5F9EA0" }} />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    sx={{ color: "blue" }}
                    onClick={handleLastQueryTimeEdit}
                  >
                    <Tooltip id="button-report" title="Save">
                      <CheckIcon sx={{ color: "#5F9EA0" }} />
                    </Tooltip>
                  </IconButton>
                </div>
              </Typography>
            </Box>
          </Modal>
        </>
      );
    };
  }

  let loading = false;
  if (queryInfo.length === 0) {
    loading = true;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="Query Info" loading={loading} />;
  }

  return (
    <div className="relative">
      <h2 style={h2Style}>Query Info</h2>

      {queryInfo.length > 0 && (
        <MaterialReactTable
          columns={columns}
          data={queryInfo.map((queryinfo) => {
            return {
              QueryId: queryinfo.QueryId,
              DataSource: queryinfo.DataSource,
              Entity: queryinfo.Entity,
              QueryState: queryinfo.QueryState,
              LastQueryRunDate: queryinfo.LastQueryRunDate,
              LastQueryMaxUpdateDate: queryinfo.LastQueryMaxUpdateDate,
              FullQuery: queryinfo.FullQuery,
              DefaultValue: queryinfo.DefaultValue,
              IncrementalQuery: queryinfo.IncrementalQuery,
              IdQuery: queryinfo.IdQuery,
              TransformationId: queryinfo.TransformationId,
              TransformationState: queryinfo.TransformationState,
            };
          })}
          initialState={{
            density: "compact",
          }}
          autoResetPageIndex={false}
        />
      )}
    </div>
  );
};

export default QueryInfo;
