import React, { useState } from "react";
import { Avatar, Button, TextField, Typography } from "@mui/material";
import "./login.css";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MainAppBar from "../topBar/appBar";
import Logo from "../topBar/logo";
var base64 = require("base-64");

async function loginUser(credentials) {
  const { REACT_APP_API_URL = "https://esbdev.premier-research.com/" } =
    process.env;

  const URL = REACT_APP_API_URL + "integrations/login";

  console.log("LOGIN_CALLED", URL);

  let headers = new Headers();

  //headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  headers.append(
    "Authorization",
    "Basic " + base64.encode(credentials.username + ":" + credentials.password)
  );

  return fetch(URL, {
    method: "POST",
    headers: headers,
  }).then((response) => {
    if (!response.ok) {
      return {
        message: "Login Failed",
      };
    }

    return response.json();
  });
}

export default function Login(props) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
    });

    if ("access_token" in response) {
      sessionStorage.setItem("access_token", response["access_token"]);
      sessionStorage.setItem("user", JSON.stringify(response["user"]));
      sessionStorage.setItem("mainNavIndex", 0);
      sessionStorage.setItem("subNavIndex", 0);
      props.setIsSignedIn(true);
      window.location.replace("/FileTransferHistoryView");
    } else {
      props.setIsSignedIn(false);
      NotificationManager.error(
        "Login failed! Please try again.",
        "Error",
        10000
      );
    }
  };

  return (
    <>
      <div className="body">
        <MainAppBar>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Logo />
          </Container>
        </MainAppBar>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 6,
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
      <NotificationContainer />
    </>
  );
}
