import React, { useEffect } from "react";

import { MaterialReactTable } from "material-react-table";
import "../util/tables.css";
import ShowLoadingBar from "../util/showLoadingBar";
import { useDataView } from "../../contexts/data.view.context";

const columns = [
  {
    accessorKey: "Source",
    header: "Source",
    id: "Source",
    size: 300,
  },
  {
    accessorKey: "Entity",
    header: "Entity",
    id: "Entity",
    size: 125,
  },
  {
    accessorKey: "Last_30_Days",
    header: "Last 30 Days",
    id: "Last_30_Days",
    size: 50,
  },

  {
    accessorKey: "Last_7_Days",
    header: "Last 7 Days",
    id: "Last_7_Days",
    size: 75,
  },
  {
    accessorKey: "Yesterday",
    header: "Yesterday",
    id: "Yesterday",
    size: 75,
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const RecieveDataReport = () => {
  const { fetchRecieveDataReport, recieveDataReport } = useDataView();
  let loading = false;

  useEffect(() => {
    (async () => {
      if (recieveDataReport.length === 0) await fetchRecieveDataReport();
    })();
    // eslint-disable-next-line
  }, []);

  if (recieveDataReport.length === 0) {
    loading = true;
  } else {
    loading = false;
  }

  if (Boolean(loading)) {
    return (
      <ShowLoadingBar title="Receive Data Count Report" loading={loading} />
    );
  }

  return (
    <div className="relative">
      <h2 style={h2Style}>Receive Data Count Report</h2>

      {recieveDataReport.length > 0 && (
        <MaterialReactTable
          columns={columns}
          data={recieveDataReport.map((errorlog) => {
            let last_30_days = errorlog["Record Count Last 30 Days"];
            let last_7_days = errorlog["Record Count Last 7 Days"];
            let yesterday = errorlog["Record Count Yesterday"];

            last_30_days = formatData(last_30_days);
            last_7_days = formatData(last_7_days);
            yesterday = formatData(yesterday);

            return {
              Source: errorlog.Source,
              Entity: errorlog.Entity,
              Last_30_Days: last_30_days,
              Last_7_Days: last_7_days,
              Yesterday: yesterday,
            };
          })}
          initialState={{
            density: "compact",
          }}
          autoResetPageIndex={false}
        />
      )}
    </div>
  );
};

export default RecieveDataReport;

function formatData(value) {
  value = "" + value;

  if (value.includes(">")) {
    value = value.replace(/>/g, "");
    value = value.replace(/</g, "");
  }

  return value;
}
