import React from "react";
// import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
// import { Link, Box } from "@mui/material";

const FileTransferStatsViewTable = ({ data, columns }) => {
  // const navigate = useNavigate();

  // function onClickBackButton() {
  //   navigate(-1);
  // }

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableGrouping
      enableStickyHeader
      enableStickyFooter
      initialState={{
        density: "compact",
        expanded: true, //expand all groups by default
        grouping: ["cmdGroup"], //an array of columns to group by by default (can be multiple)
        pagination: { pageIndex: 0, pageSize: 20 },
        sorting: [{ id: "cmdGroup", desc: false }], //sort by state by default
      }}
      // renderTopToolbarCustomActions={() => (
      //   <>
      //     <Box
      //       sx={{
      //         display: "flex",
      //         flexWrap: "wrap",
      //         justifyContent: "left",
      //         //left: "15px",
      //       }}
      //     >
      //       <Link
      //         underline="hover"
      //         onClick={onClickBackButton}
      //         variant="button"
      //         sx={{
      //           display: "flex",
      //           flexWrap: "wrap",
      //           justifyContent: "left",
      //           size: "large",
      //           //padding: "15px",
      //           paddingLeft: "15px",
      //           width: "30px",
      //         }}
      //       >
      //         {"BACK"}
      //       </Link>
      //     </Box>
      //   </>
      // )}
    />
  );
};

export default FileTransferStatsViewTable;
