import React, { useEffect } from "react";

import RemarqueEventsViewTable from "./remarqueEventsView";
import "../util/tables.css";
import { useDataView } from "../../contexts/data.view.context";
import ShowLoadingBar from "../util/showLoadingBar";

const columns = [
  {
    accessorKey: "EVENT_TYPE",
    header: "Event Type",
    id: "EVENT_TYPE",
    size: 75,
  },
  {
    accessorKey: "ACCESS_TS",
    header: "Time Stamp",
    id: "ACCESS_TS",
    size: 75,
  },
  {
    accessorKey: "ACCESS_STATUS",
    header: "Status",
    id: "ACCESS_STATUS",
    size: 50,
    Cell: ({ row }) => {
      let value = row.original.ACCESS_STATUS;
      value = "" + value;
      value = value.substr(0, value.indexOf("."));

      if (value === "200") {
        return (
          <span style={{ color: "white", backgroundColor: "green" }}>
            {value}
          </span>
        );
      }

      return (
        <span style={{ color: "white", backgroundColor: "red" }}>{value}</span>
      );
    },
  },

  {
    accessorKey: "ACCESS_METHOD",
    header: "Method",
    id: "ACCESS_METHOD",
    size: 75,
  },
  {
    accessorKey: "ACCESS_URL",
    header: "URL",
    id: "ACCESS_URL",
    size: 75,
  },
  {
    accessorKey: "EVENT_START",
    header: "Start",
    id: "EVENT_START",
    size: 100,
  },

  {
    accessorKey: "EVENT_END",
    header: "End",
    id: "EVENT_END",
    size: 75,
  },
  {
    accessorKey: "EVENT_DURATION",
    header: "Duration",
    id: "EVENT_DURATION",
    size: 75,
  },
  {
    accessorKey: "EVENT_MESSAGE",
    header: "Message",
    id: "EVENT_MESSAGE",
    size: 20,
    align: "justify",
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const RemarqueEvents = () => {
  const { fetchRemarqueEvents, remarqueEvents } = useDataView();
  let mainRowData = null;
  let subRowData = null;
  useEffect(() => {
    (async () => {
      if (remarqueEvents.length === 0) await fetchRemarqueEvents();
    })();
    // eslint-disable-next-line
  }, []);

  if (remarqueEvents.length > 0) {
    const map = new Map();
    mainRowData = remarqueEvents.map((remarqueEvent) => {
      if (map.has(remarqueEvent.EVENT_TYPE) === false) {
        map.set(remarqueEvent.EVENT_TYPE);
        return {
          EVENT_TYPE: remarqueEvent.EVENT_TYPE,
          ACCESS_TS: remarqueEvent.ACCESS_TS,
          ACCESS_STATUS: remarqueEvent.ACCESS_STATUS,
          ACCESS_DETAILS: remarqueEvent.ACCESS_DETAILS,
          ACCESS_METHOD: remarqueEvent.ACCESS_METHOD,
          ACCESS_URL: remarqueEvent.ACCESS_URL,
          EVENT_START: remarqueEvent.EVENT_START,
          EVENT_END: remarqueEvent.EVENT_END,
          EVENT_DURATION: remarqueEvent.EVENT_DURATION,
          EVENT_MESSAGE: remarqueEvent.EVENT_MESSAGE,
        };
      } else {
        return null;
      }
    });
  }

  if (mainRowData != null) {
    mainRowData = mainRowData.filter(function (x) {
      return x !== null;
    });
  }

  if (remarqueEvents.length > 0) {
    const map = new Map();
    subRowData = remarqueEvents.map((remarqueEvent) => {
      if (map.has(remarqueEvent.EVENT_TYPE) === true) {
        return {
          EVENT_TYPE: remarqueEvent.EVENT_TYPE,
          ACCESS_TS: remarqueEvent.ACCESS_TS,
          ACCESS_STATUS: remarqueEvent.ACCESS_STATUS,
          ACCESS_DETAILS: remarqueEvent.ACCESS_DETAILS,
          ACCESS_METHOD: remarqueEvent.ACCESS_METHOD,
          ACCESS_URL: remarqueEvent.ACCESS_URL,
          EVENT_START: remarqueEvent.EVENT_START,
          EVENT_END: remarqueEvent.EVENT_END,
          EVENT_DURATION: remarqueEvent.EVENT_DURATION,
          EVENT_MESSAGE: remarqueEvent.EVENT_MESSAGE,
        };
      } else {
        map.set(remarqueEvent.EVENT_TYPE);
        return null;
      }
    });
  }

  if (subRowData != null) {
    subRowData = subRowData.filter(function (x) {
      return x !== null;
    });
  }

  let loading = false;
  if (remarqueEvents.length === 0) {
    loading = true;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="Remarque Events" loading={loading} />;
  }

  return (
    <div className="relative">
      <h2 style={h2Style}>Remarque Events</h2>

      {remarqueEvents.length > 0 && (
        <RemarqueEventsViewTable
          data={mainRowData}
          columns={columns}
          subRowData={subRowData}
        />
      )}
    </div>
  );
};

export default RemarqueEvents;
