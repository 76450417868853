import React from "react";
import styled from "styled-components";
import { ReactComponent as ReactLogo } from "./logo.svg";

const Logo = () => {
  const styleObj = {
    color: "#bfd672",
    textAlign: "left",
  };

  return (
    <>
      <div>
        <StyledImage />
        <h4 style={styleObj}>ENTERPRISE INTEGRATIONS</h4>
      </div>
    </>
  );
};

export default Logo;

const StyledImage = styled(ReactLogo)`
  height: 50px;
`;
