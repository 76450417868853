import React from "react";
import styled from "styled-components";
import MainAppBar from "./appBar";
import Logo from "./logo";
import Navigation from "./navigation";
import UserMenu from "./userMenu";

const TopBar = ({ isSignedIn, setIsSignedIn }) => {
  return (
    <>
      {isSignedIn && (
        <>
          <MainAppBar>
            <Container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Logo />
              <Navigation />
            </Container>
            <Container>
              <UserMenu setIsSignedIn={setIsSignedIn} />{" "}
            </Container>
          </MainAppBar>
        </>
      )}
    </>
  );
};

export default TopBar;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
