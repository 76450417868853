import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";

import { Paper, Box, TextField, Typography, Button, Link } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const QueryScheduleTaskForm = () => {
  const validationSchema = Yup.object().shape({
    query_id: Yup.string().required("Query ID is required"),
    query_params: Yup.string().required("Query Parameters is required"),
    entity: Yup.string().required("Entity is required"),
    service_name: Yup.string().required("Service Name is required"),
    task_state: Yup.string().required("Task State is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const taskMetaData = location.state;

  const items = ["Active", "Disable", "Not Active", "Pending", "Not Exist"];

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onClickQuerySchedule = () => {
    navigate("/TaskSchedules");
  };

  const schedule_id = taskMetaData.schedule_id;
  const task_no = taskMetaData.task_no;

  const onSubmit = (data) => {
    const payload = [
      {
        ...data,
        schedule_id: schedule_id,
        task_no: task_no,
      },
    ];

    const resourcePath = "integrations/cfg/schedule/" + schedule_id + "/tasks";

    API.AddRecord(resourcePath, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success(
          "Record added!",
          "Query Schedule Task",
          10000
        );
        navigate("/QueryScheduleTasks/" + schedule_id);
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
          }}
        >
          <Link
            underline="hover"
            onClick={onClickBackButton}
            variant="button"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              size: "large",
              paddingLeft: "15px",
              width: "30px",
            }}
          >
            {"BACK"}
          </Link>

          <Link
            underline="hover"
            onClick={onClickQuerySchedule}
            variant="button"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              size: "large",
              paddingLeft: "15px",
              width: "200px",
            }}
          >
            {"QUERY SCHEDULES"}
          </Link>
        </Box>

        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            <Typography variant="h6" paddingLeft="10px" margin="dense">
              Add Query Schedule Task - {schedule_id}
            </Typography>

            <div>
              <TextField
                required
                id="query_id"
                name="query_id"
                label="Query ID"
                margin="dense"
                size="small"
                {...register("query_id")}
                error={errors.query_id ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="query_params"
                name="query_params"
                label="Query Params"
                margin="dense"
                size="small"
                {...register("query_params")}
                error={errors.query_params ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="entity"
                name="entity"
                label="Entity"
                margin="dense"
                size="small"
                {...register("entity")}
                error={errors.entity ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="service_name"
                name="service_name"
                label="Service Name"
                margin="dense"
                size="small"
                {...register("service_name")}
                error={errors.service_name ? true : false}
                variant="outlined"
              />
              <br />

              <TextField
                select
                label="Task State"
                id="task_state"
                margin="dense"
                size="small"
                {...register("task_state")}
                error={errors.task_state ? true : false}
                variant="outlined"
              >
                {items.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <Box mt={3} paddingLeft="10px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default QueryScheduleTaskForm;
