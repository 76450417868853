import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";

import { Paper, Box, TextField, Typography, Button, Link } from "@mui/material";

const ServiceConfigForm = () => {
  const validationSchema = Yup.object().shape({
    ServiceName: Yup.string().required("Service Name is required"),
    PropertyName: Yup.string().required("Property NAme is required"),
    PropertyValue: Yup.string().required("Property Value is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    const resourcePath = "integrations/cfg/serviceconfigs";

    API.AddRecord(resourcePath, data).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Service Config", 10000);
        navigate("/ServiceConfigs");
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              align="left"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                paddingLeft: "15px",
                width: "100px",
              }}
            >
              {"BACK"}
            </Link>
            <Typography variant="h6" align="center" margin="dense">
              Add Service Config
            </Typography>

            <div>
              <TextField
                required
                id="ServiceName"
                name="ServiceName"
                label="Service Name"
                margin="dense"
                size="small"
                {...register("ServiceName")}
                error={errors.ServiceName ? true : false}
                variant="outlined"
              />

              <TextField
                required
                id="PropertyName"
                name="PropertyName"
                label="Property Name"
                margin="dense"
                size="small"
                {...register("PropertyName")}
                error={errors.PropertyName ? true : false}
                variant="outlined"
              />

              <TextField
                required
                id="PropertyValue"
                name="PropertyValue"
                label="Property Value"
                margin="dense"
                size="small"
                {...register("PropertyValue")}
                error={errors.PropertyValue ? true : false}
                variant="outlined"
              />
            </div>

            <Box mt={3} align="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default ServiceConfigForm;
