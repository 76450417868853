import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { NotificationManager } from "react-notifications";
import { Box, Button } from "@mui/material";
import Select from "react-select";

const FileTransferHistoryViewTable = ({
  data,
  columns,
  projectsList,
  mainCmdGroupList,
  mainTransferNameList,
}) => {
  const [tableData, setTableData] = useState(data);
  const [projectId, setProjectId] = useState(null);
  const [cmdGroup, setCmdGroup] = useState(null);
  const [transferName, setTransferName] = useState(null);
  const [cmdGroupList, setCmdGroupList] = useState(mainCmdGroupList);
  const [transferNameList, setTransferNameList] =
    useState(mainTransferNameList);
  const [columnFilter, setColumnFilter] = useState({
    density: "compact",
    columnVisibility: { projectId: true, cmdGroup: true, transferName: true },
  });

  const handleProjectChange = (selectedOption) => {
    const selectedProject = data.filter(
      (fileHistory) => selectedOption.value === fileHistory.projectId
    );

    const cmdList = selectedProject.map((fileHistory) => {
      return fileHistory.cmdGroup;
    });

    let uniqueList = [...new Set(cmdList)];

    const groupList = uniqueList.map((cmdGroup) => {
      return { value: cmdGroup, label: cmdGroup };
    });

    setCmdGroupList(groupList);

    setProjectId(selectedOption);
    setCmdGroup(null);
    setTransferName(null);
  };

  const handleCmdGroupChange = (selectedOption) => {
    let selectedProject = [];
    let selectedGroups = [];
    let tranNames = [];

    if (projectId) {
      selectedProject = data.filter(
        (fileHistory) => projectId.value === fileHistory.projectId
      );

      selectedGroups = selectedProject.filter(
        (fileHistory) => selectedOption.value === fileHistory.cmdGroup
      );

      tranNames = selectedGroups.map((fileHistory) => {
        return fileHistory.transferName;
      });
    } else {
      const filterTransNames = data.filter(
        (fileHistory) => selectedOption.value === fileHistory.cmdGroup
      );
      tranNames = filterTransNames.map((fileHistory) => {
        return fileHistory.transferName;
      });
    }

    let uniqueList = [...new Set(tranNames)];

    const list = uniqueList.map((transfer) => {
      return { value: transfer, label: transfer };
    });
    setTransferNameList(list);
    setCmdGroup(selectedOption);
    setTransferName(null);
  };

  const handleTransferNameChange = (selectedOption) => {
    setTransferName(selectedOption);
  };

  const filterTableData = () => {
    let filteredData = data;
    let filterState = {
      density: "compact",
      columnVisibility: { projectId: true, cmdGroup: true, transferName: true },
    };

    if (projectId) {
      filteredData = data.filter(
        (fileHistory) => projectId.value === fileHistory.projectId
      );
      filterState.columnVisibility.projectId = false;
    }

    if (cmdGroup) {
      filteredData = filteredData.filter(
        (fileHistory) => cmdGroup.value === fileHistory.cmdGroup
      );
      filterState.columnVisibility.cmdGroup = false;
    }

    if (transferName) {
      filteredData = filteredData.filter(
        (fileHistory) => transferName.value === fileHistory.transferName
      );
      filterState.columnVisibility.transferName = false;
    }

    setColumnFilter(filterState);
    setTableData(filteredData);
  };

  const resetTableData = () => {
    setProjectId(null);
    setCmdGroup(null);
    setTransferName(null);
    setCmdGroupList(mainCmdGroupList);
    setTransferNameList(mainTransferNameList);
    setColumnFilter({
      density: "compact",
      columnVisibility: { projectId: true, cmdGroup: true, transferName: true },
    });
    setTableData(data);
  };

  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            paddingLeft: "15px",
            paddingRight: "15px",
            m: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Select
              options={projectsList}
              value={projectId}
              onChange={handleProjectChange}
              placeholder="Select a Project"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Select
              options={cmdGroupList}
              value={cmdGroup}
              onChange={handleCmdGroupChange}
              placeholder="Select a Cmd Group"
              width={200}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Select
              options={transferNameList}
              value={transferName}
              onChange={handleTransferNameChange}
              placeholder="Select a Transfer Name"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={filterTableData}
              sx={{
                size: "small",
                height: "40px",
                top: "0px",
                left: "20px",
                right: "20px",
              }}
            >
              {"FILTER"}
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={resetTableData}
              sx={{
                size: "small",
                height: "40px",
                top: "0px",
                left: "20px",
              }}
            >
              {"RESET"}
            </Button>
          </Box>
        </Box>
      </div>
      <div>
        <MaterialReactTable
          columns={columns}
          data={tableData}
          // state={{
          //   density: "compact",
          //   columnVisibility: { cmdGroup: false },
          // }}
          state={columnFilter}
          autoResetPageIndex={false}
          enableFilters={false}
          enableDensityToggle={false}
          enableFiltersToggle={false}
          enableSortingRemoval={false}
          enableshowFilter={false}
          enableFullScreenToggle={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          enableColumnActions={false}
        />
      </div>
    </>
  );
};

export default FileTransferHistoryViewTable;
