import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Box, FormControl, FormLabel } from "@mui/material";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@mui/material/Autocomplete";

const ViewEditServiceConfig = ({ curRow, handleEditClose, emailList }) => {
  const removeBorderStyle = {
    border: "solid 0px green",
  };

  const { fetchServiceConfigsFromAllEnv } = useDataView();
  const values = curRow.original;

  const propertyValueArr = [];

  const propValue = values.PropertyValue.split(",");
  propValue.forEach(function (element) {
    propertyValueArr.push(element.toLowerCase());
  });

  const [formData, setFormData] = useState({
    _ServiceName: values.ServiceName,
    _PropertyName: values.PropertyName,
    PropertyValue: values.PropertyValue,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleAutoCompleteChange = ({ e, values }) => {
    let dbFormattedValue = values;
    if (values.length > 0) {
      dbFormattedValue = values.join();
    }

    setFormData((prevState) => ({
      ...prevState,
      PropertyValue: dbFormattedValue,
    }));
  };

  function handleSubmit(event) {
    event.preventDefault();
    const payload = [
      {
        _ServiceName: values.ServiceName,
        _PropertyName: values.PropertyName,
        PropertyValue: formData.PropertyValue,
      },
    ];

    if (formData.PropertyValue.length === 0) {
      NotificationManager.error(
        "Field can not be empty",
        "Service Config",
        10000
      );
    } else {
      const resourcePath = "integrations/cfg/serviceconfigs";

      API.UpdateRecord(resourcePath, payload).then((response) => {
        if (response.code === 200) {
          NotificationManager.success(
            "Record Updated",
            "Service Config",
            10000
          );
          fetchServiceConfigsFromAllEnv();
        } else {
          NotificationManager.error(response.message, "Failed!", 10000);
        }
      });

      handleEditClose();
    }
  }

  return (
    <Box>
      <FormControl fullWidth={true} required>
        <FormLabel name="ServiceName">Service Name</FormLabel>
        <TextField
          required
          id="ServiceName"
          name="ServiceName"
          size="large"
          variant="standard"
          value={values.ServiceName}
          onChange={handleChange}
          disabled
        />
      </FormControl>
      <FormControl fullWidth={true} required>
        <FormLabel name="PropertyName">Property Name</FormLabel>
        <TextField
          required
          id="PropertyName"
          name="PropertyName"
          size="large"
          variant="standard"
          value={values.PropertyName}
          onChange={handleChange}
          disabled
        />
      </FormControl>
      {/* <FormControl fullWidth={true} required>
        <FormLabel name="PropertyValue">Property Value</FormLabel>
        <TextField
          required
          id="PropertyValue"
          name="PropertyValue"
          size="large"
          //defaultValue={curRow.original.PropertyValue}
          variant="standard"
          value={formData.PropertyValue}
          onChange={handleChange}
          multiline
        />
      </FormControl> */}
      <FormControl fullWidth={true} sx={removeBorderStyle}>
        <FormLabel name="PropertyValue">Property Value</FormLabel>
        <Autocomplete
          id="PropertyValue"
          name="PropertyValue"
          freeSolo
          multiple
          onChange={(e, values) => handleAutoCompleteChange({ e, values })}
          getOptionLabel={(option) => option}
          options={emailList}
          fullWidth
          defaultValue={propertyValueArr}
          renderInput={(params) => (
            <TextField
              {...params}
              id="PropertyValue"
              name="PropertyValue"
              size="large"
              variant="outlined"
              fullWidth
              sx={{
                paddingRight: "15px",
                width: "100%",
              }}
            />
          )}
        />
      </FormControl>

      <div align="right">
        <IconButton sx={{ color: "blue" }} onClick={handleEditClose}>
          <Tooltip id="button-report" title="Close">
            <CloseIcon sx={{ color: "#5F9EA0" }} />
          </Tooltip>
        </IconButton>
        <IconButton sx={{ color: "blue" }} onClick={handleSubmit}>
          <Tooltip id="button-report" title="Save">
            <CheckIcon sx={{ color: "#5F9EA0" }} />
          </Tooltip>
        </IconButton>
      </div>
    </Box>
  );
};

export default ViewEditServiceConfig;
