import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, FormControl, FormLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";

const ViewEditConnection = ({
  curRow,
  handleDetailClose,
  editable,
  onEditClick,
  data,
}) => {
  const { fetchEIConenctionsFromAllEnv } = useDataView();
  const values = curRow.original;

  let conDataFieldRows = calculateCmdTextLines(values);

  const [formData, setFormData] = useState({
    _CONNECTION_ID: values._CONNECTION_ID,
    CONNECTION_ID: values.CONNECTION_ID,
    CONNECTION_TYPE: values.CONNECTION_TYPE,
    PROJECT_ID: values.PROJECT_ID,
    PROJECT_OID: values.PROJECT_OID,
    PROJECT_NAME: values.PROJECT_NAME,
    SPONSOR_NAME: values.SPONSOR_NAME,
    BUSINESS_ID: values.BUSINESS_ID,
    CONNECTION_URL: values.CONNECTION_URL,
    CONNECTION_PROVIDER: values.CONNECTION_PROVIDER,
    CONNECTION_DATA: values.CONNECTION_DATA,
    AUTH_TYPE: values.AUTH_TYPE,
    AUTH_ID: values.AUTH_ID,
    AUTH_PIN: values.AUTH_PIN,
    AUTH_PASSCODE: values.AUTH_PASSCODE,
    STATE: values.STATE,
    MAX_ROWS_PER_QUERY:
      values.MAX_ROWS_PER_QUERY === null ? "" : values.MAX_ROWS_PER_QUERY,
    MAX_QUEERIES: values.MAX_QUEERIES === null ? "" : values.MAX_QUEERIES,
    PING_SERVICE: values.PING_SERVICE === null ? "" : values.PING_SERVICE,
    COMMENTS: values.COMMENTS === null ? "" : values.COMMENTS,
  });

  const items = ["Active", "Not Active", "Closed", "Pending", "Locked"];

  const menuItems = items.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    const CONNECTION_ID = formData.CONNECTION_ID;
    const org_con_id = values.CONNECTION_ID;
    let checkConnectionIDExists = null;

    if (org_con_id === CONNECTION_ID) {
      // connection id is not edited
    } else {
      checkConnectionIDExists = data.find(
        (connection) => connection.CONNECTION_ID === CONNECTION_ID
      );
    }

    if (checkConnectionIDExists) {
      NotificationManager.error("", "Connection ID exists!", 10000);
    } else {
      const payload = [
        {
          _CONNECTION_ID: values._CONNECTION_ID,
          CONNECTION_ID: formData.CONNECTION_ID,
          CONNECTION_TYPE: formData.CONNECTION_TYPE,
          PROJECT_ID: formData.PROJECT_ID,
          PROJECT_OID: formData.PROJECT_OID,
          PROJECT_NAME: formData.PROJECT_NAME,
          SPONSOR_NAME: formData.SPONSOR_NAME,
          BUSINESS_ID: formData.BUSINESS_ID,
          CONNECTION_URL: formData.CONNECTION_URL,
          CONNECTION_PROVIDER: formData.CONNECTION_PROVIDER,
          CONNECTION_DATA: formData.CONNECTION_DATA,
          AUTH_TYPE: formData.AUTH_TYPE,
          AUTH_ID: formData.AUTH_ID,
          AUTH_PIN: formData.AUTH_PIN,
          AUTH_PASSCODE: formData.AUTH_PASSCODE,
          STATE: formData.STATE,
          MAX_ROWS_PER_QUERY: formData.MAX_ROWS_PER_QUERY,
          MAX_QUEERIES: formData.MAX_QUEERIES,
          PING_SERVICE: formData.PING_SERVICE,
          COMMENTS: formData.COMMENTS,
        },
      ];

      const resourcePath = "integrations/cfg/connections";

      API.UpdateRecord(resourcePath, payload).then((response) => {
        if (response.code === 200) {
          NotificationManager.success("Record Updated", "Connection", 10000);
          fetchEIConenctionsFromAllEnv();
        } else {
          NotificationManager.error(response.message, "Failed!", 10000);
        }
      });
    }
  }

  function handleClone(event) {
    event.preventDefault();
    console.log(formData);
    const CONNECTION_ID = formData.CONNECTION_ID;

    let checkConnectionIDExists = null;

    checkConnectionIDExists = data.find(
      (connection) => connection.CONNECTION_ID === CONNECTION_ID
    );

    if (checkConnectionIDExists) {
      NotificationManager.error("", "Connection ID exists!", 10000);
    } else {
      const payload = [
        {
          CONNECTION_ID: formData.CONNECTION_ID,
          CONNECTION_TYPE: formData.CONNECTION_TYPE,
          PROJECT_ID: formData.PROJECT_ID,
          PROJECT_OID: formData.PROJECT_OID,
          PROJECT_NAME: formData.PROJECT_NAME,
          SPONSOR_NAME: formData.SPONSOR_NAME,
          BUSINESS_ID: formData.BUSINESS_ID,
          CONNECTION_URL: formData.CONNECTION_URL,
          CONNECTION_PROVIDER: formData.CONNECTION_PROVIDER,
          CONNECTION_DATA: formData.CONNECTION_DATA,
          AUTH_TYPE: formData.AUTH_TYPE,
          AUTH_ID: formData.AUTH_ID,
          AUTH_PIN: formData.AUTH_PIN,
          AUTH_PASSCODE: formData.AUTH_PASSCODE,
          STATE: formData.STATE,
          MAX_ROWS_PER_QUERY: formData.MAX_ROWS_PER_QUERY,
          MAX_QUEERIES: formData.MAX_QUEERIES,
          PING_SERVICE: formData.PING_SERVICE,
          COMMENTS: formData.COMMENTS,
        },
      ];

      const resourcePath = "integrations/cfg/connections";

      API.AddRecord(resourcePath, payload).then((response) => {
        if (response.code === 200) {
          NotificationManager.success(
            "Record added!",
            "Connection Entry",
            10000
          );
          fetchEIConenctionsFromAllEnv();
        } else {
          NotificationManager.error(response.message, "Failed!", 10000);
        }
      });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <Box>
      <FormControl fullWidth={true}>
        <FormLabel>ID</FormLabel>
        <TextField
          required
          id="CONNECTION_ID"
          name="CONNECTION_ID"
          size="large"
          disabled={!editable}
          variant="standard"
          value={formData.CONNECTION_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Type</FormLabel>
        <TextField
          required
          id="CONNECTION_TYPE"
          name="CONNECTION_TYPE"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.CONNECTION_TYPE}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Project ID</FormLabel>
        <TextField
          required
          id="PROJECT_ID"
          name="PROJECT_ID"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.PROJECT_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Project OID</FormLabel>
        <TextField
          required
          id="PROJECT_OID"
          name="PROJECT_OID"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.PROJECT_OID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Project Name</FormLabel>
        <TextField
          required
          id="PROJECT_NAME"
          name="PROJECT_NAME"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.PROJECT_NAME}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Sponsor Name</FormLabel>
        <TextField
          required
          id="SPONSOR_NAME"
          name="SPONSOR_NAME"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.SPONSOR_NAME}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Business ID</FormLabel>
        <TextField
          required
          id="BUSINESS_ID"
          name="BUSINESS_ID"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.BUSINESS_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="STATE">
        <FormLabel>STATE</FormLabel>
        <Select
          name="STATE"
          labelId="STATE"
          id="STATE"
          variant="standard"
          disabled={!editable}
          value={formData.STATE}
          onChange={handleChange}
        >
          {menuItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Connection URL</FormLabel>
        <TextField
          required
          id="CONNECTION_URL"
          name="CONNECTION_URL"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.CONNECTION_URL}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Connection Provider</FormLabel>
        <TextField
          required
          id="CONNECTION_PROVIDER"
          name="CONNECTION_PROVIDER"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.CONNECTION_PROVIDER}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Auth Type</FormLabel>
        <TextField
          required
          id="AUTH_TYPE"
          name="AUTH_TYPE"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.AUTH_TYPE}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Auth ID</FormLabel>
        <TextField
          required
          id="AUTH_ID"
          name="AUTH_ID"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.AUTH_ID}
          onChange={handleChange}
        />
      </FormControl>

      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Auth PIN</FormLabel>
        <TextField
          required
          id="AUTH_PIN"
          name="AUTH_PIN"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.AUTH_PIN}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Auth Passcode</FormLabel>
        <TextField
          required
          id="AUTH_PASSCODE"
          name="AUTH_PASSCODE"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.AUTH_PASSCODE}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Max rows per query</FormLabel>
        <TextField
          required
          id="MAX_ROWS_PER_QUERY"
          name="MAX_ROWS_PER_QUERY"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.MAX_ROWS_PER_QUERY}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Max queries</FormLabel>
        <TextField
          required
          id="MAX_QUERIES"
          name="MAX_QUERIES"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.MAX_QUERIES}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Ping Service</FormLabel>
        <TextField
          required
          id="PING_SERVICE"
          name="PING_SERVICE"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.PING_SERVICE}
          onChange={handleChange}
        />
      </FormControl>

      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Connection Data</FormLabel>
        <TextField
          required
          id="CONNECTION_DATA"
          name="CONNECTION_DATA"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.CONNECTION_DATA}
          onChange={handleChange}
          multiline
          rows={conDataFieldRows}
        />
      </FormControl>

      <Button type="edit" disabled={editable} onClick={onEditClick}>
        Edit
      </Button>
      <Button type="submit" disabled={!editable} onClick={handleSubmit}>
        Save
      </Button>
      <Button type="submit" disabled={!editable} onClick={handleClone}>
        Clone
      </Button>
      <Button type="close" onClick={handleDetailClose}>
        Close
      </Button>
    </Box>
  );
};

export default ViewEditConnection;
function calculateCmdTextLines(values) {
  const conData = values.CONNECTION_DATA;
  let conDataFieldRows = 0;
  if (conData !== null && conData !== "") {
    conDataFieldRows = parseInt(conData.length / 80);
  }

  const lines = (conData.match(/\n/g) || "").length + 1;

  conDataFieldRows = parseInt(conDataFieldRows);

  if (lines > conDataFieldRows) {
    conDataFieldRows = lines;
  }
  return conDataFieldRows;
}
