import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { Paper, Box, TextField, Typography, Button, Link } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDataView } from "../../contexts/data.view.context";

const APIConfigForm = () => {
  const validationSchema = Yup.object().shape({
    API_ID: Yup.string().required("API ID is required"),
    MOD: Yup.string().required("MOD is required"),
    PATH: Yup.string().required("Path is required"),
    OPERATION: Yup.string().required("Operation is required"),
    STATE: Yup.string().required("State is required"),
    NAME: Yup.string().required("Name is required"),
    REF_TYPE: Yup.string().nullable().notRequired().default(""),
    REF_ID: Yup.string().nullable().notRequired().default(""),
    SECURITY_POLICY: Yup.string().nullable().notRequired().default(""),
    SAMPLE_DATA: Yup.string().nullable().notRequired().default(""),
    REF_DATA: Yup.string().nullable().notRequired().default(""),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const { fetchAPIConfigsFromAllEnv } = useDataView();
  const operations = ["GET", "POST", "PUT", "DELETE", "PATCH"];
  const stateItems = ["Active", "Disabled"];
  const refTypes = [
    "AWARDCO",
    "CTPO",
    "DataQuery",
    "ESB",
    "FileQuery",
    "INCYTE",
  ];

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    const resourcePath = "integrations/cfg/apiconfigs";
    API.AddRecord(resourcePath, data).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Api Config", 10000);
        fetchAPIConfigsFromAllEnv();
        navigate("/APIConfigs");
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "left",
        }}
      >
        <Link
          underline="hover"
          onClick={onClickBackButton}
          variant="button"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
            size: "large",
            paddingLeft: "15px",
            width: "30px",
          }}
        >
          {"BACK"}
        </Link>
      </Box>

      <Paper>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          align="left"
        >
          <Typography variant="h6" paddingLeft="10px" margin="dense">
            ADD API CONFIG
          </Typography>

          <div>
            <TextField
              required
              id="API_ID"
              name="API_ID"
              label="API_ID"
              margin="dense"
              size="small"
              {...register("API_ID")}
              error={!!errors.API_ID}
              variant="outlined"
            />
            <br />
            <TextField
              required
              id="MOD"
              name="MOD"
              label="MOD"
              margin="dense"
              size="small"
              {...register("MOD")}
              error={!!errors.MOD}
              variant="outlined"
            />
            <br />
            <TextField
              required
              id="PATH"
              name="PATH"
              label="PATH"
              margin="dense"
              size="small"
              {...register("PATH")}
              error={!!errors.PATH}
              variant="outlined"
            />
            <br />

            <TextField
              select
              label="OPERATION"
              id="OPERATION"
              margin="dense"
              size="small"
              {...register("OPERATION")}
              error={!!errors.OPERATION}
              variant="outlined"
            >
              {operations.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <br />

            <TextField
              select
              label="STATE"
              id="STATE"
              margin="dense"
              size="small"
              {...register("STATE")}
              error={!!errors.STATE}
              variant="outlined"
            >
              {stateItems.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <TextField
              required
              id="NAME"
              name="NAME"
              label="NAME"
              margin="dense"
              size="small"
              {...register("NAME")}
              error={!!errors.NAME}
              variant="outlined"
            />
            <br />

            <TextField
              select
              label="REF_TYPE"
              id="REF_TYPE"
              margin="dense"
              size="small"
              {...register("REF_TYPE")}
              error={!!errors.REF_TYPE}
              variant="outlined"
            >
              {refTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <TextField
              required
              id="REF_ID"
              name="REF_ID"
              label="REF_ID"
              margin="dense"
              size="small"
              {...register("REF_ID")}
              error={!!errors.REF_ID}
              variant="outlined"
            />
            <br />
            <TextField
              id="REF_DATA"
              name="REF_DATA"
              label="REF_DATA"
              margin="dense"
              size="small"
              {...register("REF_DATA")}
              error={!!errors.REF_DATA}
              variant="outlined"
            />
            <br />
            <TextField
              id="SECURITY_POLICY"
              name="SECURITY_POLICY"
              label="SECURITY_POLICY"
              margin="dense"
              size="small"
              {...register("SECURITY_POLICY")}
              error={!!errors.SECURITY_POLICY}
              variant="outlined"
            />
            <br />
            <TextField
              id="SAMPLE_DATA"
              name="SAMPLE_DATA"
              label="SAMPLE_DATA"
              margin="dense"
              size="small"
              {...register("SAMPLE_DATA")}
              error={!!errors.SAMPLE_DATA}
              variant="outlined"
            />
          </div>

          <Box mt={3} paddingLeft="10px">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default APIConfigForm;
