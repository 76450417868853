import React from "react";
import { MaterialReactTable } from "material-react-table";
// import { NotificationManager } from "react-notifications";
import { Link, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import * as API from "../api/index";
// import { useDataView } from "../../contexts/data.view.context";

const EIConnectionViewTable = ({ data, columns, mainColumns }) => {
  const navigate = useNavigate();
  // const { fetchEIConenctionsFromAllEnv } = useDataView();

  // const handleSaveRow = async ({ exitEditingMode, row, values }) => {
  //   const CONNECTION_ID = values.CONNECTION_ID;
  //   const org_con_id = row.original.CONNECTION_ID;
  //   let checkConnectionIDExists = null;

  //   if (org_con_id === CONNECTION_ID) {
  //     // connection id is not edited
  //   } else {
  //     checkConnectionIDExists = data.find(
  //       (connection) => connection.CONNECTION_ID === CONNECTION_ID
  //     );
  //   }

  //   if (checkConnectionIDExists) {
  //     NotificationManager.error("", "Connection ID exists!", 10000);
  //   } else {
  //     data[row.index] = values;
  //     const payload = [
  //       {
  //         _CONNECTION_ID: row.original._CONNECTION_ID,
  //         CONNECTION_ID: values.CONNECTION_ID,
  //         CONNECTION_TYPE: values.CONNECTION_TYPE,
  //         PROJECT_ID: values.PROJECT_ID,
  //         PROJECT_OID: values.PROJECT_OID,
  //         PROJECT_NAME: values.PROJECT_NAME,
  //         SPONSOR_NAME: values.SPONSOR_NAME,
  //         BUSINESS_ID: values.BUSINESS_ID,
  //         CONNECTION_URL: values.CONNECTION_URL,
  //         CONNECTION_PROVIDER: values.CONNECTION_PROVIDER,
  //         CONNECTION_DATA: values.CONNECTION_DATA,
  //         AUTH_TYPE: values.AUTH_TYPE,
  //         AUTH_ID: values.AUTH_ID,
  //         AUTH_PIN: values.AUTH_PIN,
  //         AUTH_PASSCODE: values.AUTH_PASSCODE,
  //         STATE: values.STATE,
  //         MAX_ROWS_PER_QUERY: values.MAX_ROWS_PER_QUERY,
  //         MAX_QUEERIES: values.MAX_QUEERIES,
  //         PING_SERVICE: values.PING_SERVICE,
  //         COMMENTS: values.COMMENTS,
  //       },
  //     ];

  //     const resourcePath = "integrations/cfg/connections";

  //     API.UpdateRecord(resourcePath, payload).then((response) => {
  //       if (response.code === 200) {
  //         fetchEIConenctionsFromAllEnv();
  //         NotificationManager.success("Record Updated", "Connection", 10000);
  //       } else {
  //         NotificationManager.error(response.message, "Failed!", 10000);
  //       }
  //     });
  //   }

  //   exitEditingMode(); //required to exit editing mode
  // };

  function onClick() {
    navigate("/AddEIConnection");
  }

  return (
    <MaterialReactTable
      columns={mainColumns}
      data={data}
      autoResetPageIndex={false}
      layoutMode="grid"
      muiTableHeadCellProps={{
        sx: {
          flex: "0 0 auto",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          flex: "0 0 auto",
        },
      }}
      initialState={{
        density: "compact",
      }}
      enableStickyHeader
      enableStickyFooter
      muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              // paddingLeft: "15px",
            }}
          >
            <Link
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                paddingLeft: "15px",
                width: "200px",
              }}
            >
              {"ADD CONNECTION"}
            </Link>
          </Box>
        </>
      )}
      // renderDetailPanel={({ row }) => {
      //   const rowData = [
      //     {
      //       _CONNECTION_ID: row.original._CONNECTION_ID,
      //       CONNECTION_ID: row.original.CONNECTION_ID,
      //       CONNECTION_TYPE: row.original.CONNECTION_TYPE,
      //       PROJECT_ID: row.original.PROJECT_ID,
      //       PROJECT_OID: row.original.PROJECT_OID,
      //       PROJECT_NAME: row.original.PROJECT_NAME,
      //       SPONSOR_NAME: row.original.SPONSOR_NAME,
      //       BUSINESS_ID: row.original.BUSINESS_ID,
      //       CONNECTION_URL: row.original.CONNECTION_URL,
      //       CONNECTION_PROVIDER: row.original.CONNECTION_PROVIDER,
      //       CONNECTION_DATA: row.original.CONNECTION_DATA,
      //       AUTH_TYPE: row.original.AUTH_TYPE,
      //       AUTH_ID: row.original.AUTH_ID,
      //       AUTH_PIN: row.original.AUTH_PIN,
      //       AUTH_PASSCODE: row.original.AUTH_PASSCODE,
      //       STATE: row.original.STATE,
      //       MAX_ROWS_PER_QUERY: row.original.MAX_ROWS_PER_QUERY,
      //       MAX_QUERIES: row.original.MAX_QUERIES,
      //       PING_SERVICE: row.original.PING_SERVICE,
      //       COMMENTS: row.original.COMMENTS,
      //     },
      //   ];

      //   return (
      //     <MaterialReactTable
      //       columns={columns}
      //       data={rowData}
      //       editingMode="modal"
      //       enableEditing
      //       onEditingRowSave={handleSaveRow}
      //       //onEditingRowCancel={handleRowEditCancel}
      //       initialState={{
      //         density: "compact",
      //       }}
      //       enableFilters={false}
      //       enableDensityToggle={false}
      //       enableFiltersToggle={false}
      //       enableSortingRemoval={false}
      //       enableshowFilter={false}
      //       enableFullScreenToggle={false}
      //       enableColumnFilters={false}
      //       enableTopToolbar={false}
      //       enableBottomToolbar={false}
      //       enableColumnActions={false}
      //     />
      //   );
      // }}
    />
  );
};

export default EIConnectionViewTable;
