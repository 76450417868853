import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { Paper, Box, TextField, Typography, Button, Link } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDataView } from "../../contexts/data.view.context";

const TaskScheduleForm = () => {
  const validationSchema = Yup.object().shape({
    schedule_id: Yup.string().required("Schedule ID is required"),
    //schedule_interval: Yup.string().required("Interval is required"),
    //schedule_count: Yup.string().required("Count is required"),
    schedule_cron: Yup.string().required("Cron is required"),
    schedule_state: Yup.string().required("State is required"),
    schedule_type: Yup.string().required("Type is required"),
    schedule_cmd: Yup.string().required("Command is required"),
    schedule_group: Yup.string().required("Group is required"),
    //parallel_execute: Yup.string().nullable().notRequired().default(""),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const { fetchTaskSchedulesFromAllEnv } = useDataView();
  const stateItems = ["Active", "Disable"];
  const itemsType = ["crontab", "synapse"];

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    console.log("onSubmit");
    const resourcePath = "integrations/cfg/schedules";
    API.AddRecord(resourcePath, data).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Query Schedule", 10000);
        fetchTaskSchedulesFromAllEnv();
        navigate("/TaskSchedules");
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
          }}
        >
          <Link
            underline="hover"
            onClick={onClickBackButton}
            variant="button"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              size: "large",
              paddingLeft: "15px",
              width: "30px",
            }}
          >
            {"BACK"}
          </Link>
        </Box>

        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            <Typography variant="h6" paddingLeft="10px" margin="dense">
              ADD QUERY SCHEDULE
            </Typography>

            <div>
              <TextField
                required
                id="schedule_id"
                name="schedule_id"
                label="ID"
                margin="dense"
                size="small"
                {...register("schedule_id")}
                error={errors.schedule_id ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="schedule_cron"
                name="schedule_cron"
                label="Cron"
                margin="dense"
                size="small"
                {...register("schedule_cron")}
                error={errors.schedule_cron ? true : false}
                variant="outlined"
              />
              <br />

              <TextField
                select
                label="State"
                id="schedule_state"
                margin="dense"
                size="small"
                {...register("schedule_state")}
                error={errors.schedule_state ? true : false}
                variant="outlined"
              >
                {stateItems.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />

              <TextField
                select
                label="Type"
                id="schedule_type"
                margin="dense"
                size="small"
                {...register("schedule_type")}
                error={errors.schedule_type ? true : false}
                variant="outlined"
              >
                {itemsType.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="schedule_cmd"
                name="schedule_cmd"
                label="Command"
                margin="dense"
                size="small"
                {...register("schedule_cmd")}
                error={errors.schedule_cmd ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="schedule_group"
                name="schedule_group"
                label="Group"
                margin="dense"
                size="small"
                {...register("schedule_group")}
                error={errors.schedule_group ? true : false}
                variant="outlined"
              />
            </div>

            <Box mt={3} paddingLeft="10px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default TaskScheduleForm;
