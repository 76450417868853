import React from "react";
import "../tables.css";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import FormatText from "./prettyPrintDetailsText";

const ViewDetailsModal = ({
  cell,
  openDetail,
  currentColumnRef,
  handleDetailOpen,
  handleDetailClose,
  title,
}) => {
  const tooltip = "View Details";
  const styleDetailBox = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, 10%)",
    alignSelf: "flex-start",
    bgcolor: "background.paper",
    border: "2px solid #2BB041",
    borderRadius: "10px",
    p: 4,
  };

  return (
    <>
      <IconButton
        sx={{ color: "blue" }}
        onClick={() => handleDetailOpen({ cell })}
      >
        <Tooltip id="button-report" title={tooltip}>
          <MoreHorizIcon sx={{ color: "black" }} />
        </Tooltip>
      </IconButton>

      <Modal
        open={openDetail}
        onClose={handleDetailClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
        // disablebackdropclick
        sx={{
          overflow: "scroll",
        }}
      >
        <Box sx={styleDetailBox}>
          <Typography
            id="spring-modal-title"
            variant="h6"
            component="h2"
            color="#2B73B0"
          >
            {`${title}`}
          </Typography>
          <br />
          <Divider color="black" />
          <br />

          <FormatText columnID={title} detailText={currentColumnRef.current} />
          <br />
          <div align="right">
            <IconButton sx={{ color: "blue" }} onClick={handleDetailClose}>
              <Tooltip id="button-report" title="Close">
                <CloseIcon sx={{ color: "#5F9EA0" }} />
              </Tooltip>
            </IconButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ViewDetailsModal;
