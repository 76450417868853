import React from "react";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { NotificationManager } from "react-notifications";
import { Link as MuiLink } from "@mui/material";
import { Box } from "@mui/material";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";

const TaskScheduleViewTable = ({ data, columns, mainColumns }) => {
  const { fetchTaskSchedulesFromAllEnv } = useDataView();
  const navigate = useNavigate();

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    data[row.index] = values;

    const payload = [
      {
        _schedule_id: values.schedule_id,
        schedule_interval: values.schedule_interval,
        schedule_count: values.schedule_count,
        schedule_cron: values.schedule_cron,
        schedule_state: values.schedule_state,
        schedule_type: values.schedule_type,
        schedule_cmd: values.schedule_cmd,
        schedule_group: values.schedule_group,
        parallel_execute: values.parallel_execute,
      },
    ];

    const resourcePath = "integrations/cfg/schedules";

    API.UpdateRecord(resourcePath, payload).then((response) => {
      if (response.code === 200) {
        fetchTaskSchedulesFromAllEnv();
        NotificationManager.success("Record Updated", "Query Schedule", 10000);
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });

    exitEditingMode(); //required to exit editing mode
  };

  function onClick() {
    navigate("/AddQuerySchedule");
  }

  const handleRowEditCancel = ({ row, table }) => {
    //avoid setting invalid state on cancel edit
    fetchTaskSchedulesFromAllEnv();
  };

  return (
    <MaterialReactTable
      columns={mainColumns}
      data={data}
      onEditingRowSave={handleSaveRow}
      onEditingRowCancel={handleRowEditCancel}
      initialState={{
        density: "compact",
      }}
      layoutMode="grid"
      muiTableHeadCellProps={{
        sx: {
          flex: "0 0 auto",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          flex: "0 0 auto",
        },
      }}
      enableStickyHeader
      enableStickyFooter
      muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              //left: "15px",
            }}
          >
            <MuiLink
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                paddingLeft: "15px",
                width: "200px",
              }}
            >
              {"ADD TASK SCHEDULE"}
            </MuiLink>
          </Box>
        </>
      )}
      // renderDetailPanel={({ row }) => {
      //   const rowData = [
      //     {
      //       _schedule_id: row.original._schedule_id,
      //       schedule_id: row.original.schedule_id,
      //       task_count: row.original._taskCount_,
      //       schedule_interval: row.original.schedule_interval,
      //       schedule_count: row.original.schedule_count,
      //       schedule_cron: row.original.schedule_cron,
      //       schedule_state: row.original.schedule_state,
      //       schedule_type: row.original.schedule_type,
      //       schedule_cmd: row.original.schedule_cmd,
      //       schedule_group: row.original.schedule_group,
      //       parallel_execute: row.original.parallel_execute,
      //     },
      //   ];

      //   return (
      //     <MaterialReactTable
      //       columns={columns}
      //       data={rowData}
      //       editingMode="modal"
      //       enableEditing
      //       onEditingRowSave={handleSaveRow}
      //       onEditingRowCancel={handleRowEditCancel}
      //       initialState={{
      //         density: "compact",
      //       }}
      //       enableFilters={false}
      //       enableDensityToggle={false}
      //       enableFiltersToggle={false}
      //       enableSortingRemoval={false}
      //       enableshowFilter={false}
      //       enableFullScreenToggle={false}
      //       enableColumnFilters={false}
      //       enableTopToolbar={false}
      //       enableBottomToolbar={false}
      //       enableColumnActions={false}
      //     />
      //   );
      // }}
      autoResetPageIndex={false}
    />
  );
};

export default TaskScheduleViewTable;
