import React from "react";
import "./tables.css";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const ShowLoadingBar = ({ title, loading }) => {
  const h2Style = {
    paddingLeft: "20px",
  };

  const style = {
    position: "absolute",
    top: "300px",
    width: "100%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 0,
    p: 4,
  };

  return (
    <div className="loadin-main-container">
      <h2 style={h2Style}>{title}</h2>
      <Box sx={style}>
        <Typography align="center">Loading</Typography>
        <LinearProgress sx={{ width: "100%" }} loading="true" size="lg" />
      </Box>
    </div>
  );
};

export default ShowLoadingBar;
