import React, { useEffect, useState } from "react";
import TaskScheduleViewTable from "./taskScheduleView";
import { Link } from "react-router-dom";
import "../util/tables.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import { useDataView } from "../../contexts/data.view.context";
import IconButton from "@mui/material/IconButton";
import * as API from "../api/index";
import { NotificationManager } from "react-notifications";
import Tooltip from "@mui/material/Tooltip";
// import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import ViewEditTaskSchedule from "./viewEditTaskSchedule";
import ShowLoadingBar from "../util/showLoadingBar";
import ConfirmPromoteDialog from "../util/confirmPromoteDialog";

const h2Style = {
  paddingLeft: "20px",
};

const TaskSchedules = () => {
  const {
    taskSchedules,
    fetchTaskSchedulesFromAllEnv,
    REACT_APP_ENV,
    envColumn1,
    envColumn2,
    openPromoteModal,
    setOpenPromoteModal,
    openDetailForm,
    editable,
    envCell,
    curRow,
    handleClickOpenDetail,
    handleDetailClose,
    onEditClick,
    handleClickOpen,
    handleClose,
    detailViewBoxStyle,
  } = useDataView();

  const columns = [
    {
      id: "schedule_id",
      accessorKey: "schedule_id",
      header: "ID",
      size: 150,
      enableEditing: false,
    },
    {
      id: "task_count",
      accessorKey: "task_count",
      header: "No of Tasks",
      size: 50,
      enableEditing: false,
      Cell: ({ row }) => {
        return (
          <Link to={"/QueryScheduleTasks/" + row.original.schedule_id}>
            {row.original.task_count}
          </Link>
        );
      },
    },
    {
      accessorKey: "schedule_cron",
      header: "Cron",
      size: 150,
    },
    {
      accessorKey: "schedule_state",
      header: "State",
      size: 40,
      Edit: ({ cell, column, table }) => {
        let originalState = cell.row.original.schedule_state;
        const [stateValue, setState] = React.useState(originalState);

        const handleChange = (event) => {
          cell.row._valuesCache.schedule_state = event.target.value;
          setState(event.target.value);
        };

        const items = ["Active", "Disable"];

        const menuItems = items.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        });

        return (
          <>
            <Box sx={{}}>
              <InputLabel
                id="demo-simple-select-label"
                size="small"
                shrink="true"
                sx={{
                  bottom: -5,
                }}
              >
                State
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                value={stateValue}
                defaultValue={originalState}
                onChange={handleChange}
              >
                {menuItems}
              </Select>
            </Box>
          </>
        );
      },
    },
    {
      accessorKey: "schedule_type",
      header: "Type",
      size: 40,
      Edit: ({ cell, column, table }) => {
        let originalType = cell.row.original.schedule_type;
        const [type, setType] = useState(originalType);

        const handleChange = (event) => {
          cell.row._valuesCache.schedule_type = event.target.value;
          setType(event.target.value);
        };

        const items = ["crontab", "synapse"];

        const menuItems = items.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        });

        return (
          <>
            <Box sx={{}}>
              <InputLabel
                id="demo-simple-select-label"
                size="small"
                shrink="true"
                sx={{
                  bottom: -5,
                }}
              >
                Type
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                value={type}
                defaultValue={originalType}
                onChange={handleChange}
              >
                {menuItems}
              </Select>
            </Box>
          </>
        );
      },
    },
    {
      accessorKey: "schedule_cmd",
      header: "Cmd",
      size: 250,
    },
    {
      accessorKey: "schedule_group",
      header: "Group",
      size: 50,
    },
  ];

  let detailData = null;

  const handleConfirm = () => {
    const payload = [
      {
        schedule_id: envCell.current.row.original.schedule_id,
        schedule_interval: envCell.current.row.original.schedule_interval,
        schedule_count: envCell.current.row.original.schedule_count,
        schedule_cron: envCell.current.row.original.schedule_cron,
        schedule_state: "Pending",
        schedule_type: envCell.current.row.original.schedule_type,
        schedule_cmd: envCell.current.row.original.schedule_cmd,
        schedule_group: envCell.current.row.original.schedule_group,
        parallel_execute: envCell.current.row.original.parallel_execute,
      },
    ];

    const env = envCell.current.column.id;

    onSubmit(payload, env);
    setOpenPromoteModal(false);
  };

  function viewDetails() {
    return function ({ cell, row }) {
      const cellValue = cell.getValue();
      const tooltip = "View Details";

      return (
        <>
          <IconButton
            sx={{ color: "blue" }}
            onClick={() => handleClickOpenDetail({ row })}
          >
            <Tooltip id="button-report" title={tooltip}>
              <InfoIcon sx={{ color: "#2BB05B" }} />
            </Tooltip>
          </IconButton>
          {cellValue}

          <Modal
            open={openDetailForm}
            onClose={handleClickOpenDetail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            hideBackdrop
            paperprops={{
              elevation: 0,
              sx: {
                border: "solid 1px gray",
                boxShadow: "none",
              },
            }}
            sx={{
              overflow: "scroll",
              paddingLeft: "20%",
              paddingTop: "10%",
            }}
          >
            <Box sx={detailViewBoxStyle}>
              <ViewEditTaskSchedule
                curRow={curRow.current}
                handleDetailClose={handleDetailClose}
                editable={editable}
                onEditClick={onEditClick}
                data={detailData}
              />
            </Box>
          </Modal>
        </>
      );
    };
  }

  const onSubmit = (payload, env) => {
    const resourcePath = "integrations/cfg/schedules";

    API.AddRecordToOtherEnv(resourcePath, env, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success(
          "Record added to " + env + " env",
          "Task Schedule ",
          10000
        );
        fetchTaskSchedulesFromAllEnv();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  let currentEnvConfig = [];
  let devConfigs = [];
  let testConfigs = [];
  let prodConfigs = [];

  const mainColumns = [
    {
      id: "schedule_id",
      accessorKey: "schedule_id",
      header: "ID",
      size: 300,
      enableEditing: false,
      Cell: viewDetails(),
    },
    {
      accessorKey: "schedule_type",
      header: "Type",
      size: 80,
    },
    {
      id: "task_count",
      accessorKey: "task_count",
      header: "No of Tasks",
      size: 120,
      enableEditing: false,
      Cell: ({ row }) => {
        return (
          <Link to={"/QueryScheduleTasks/" + row.original.schedule_id}>
            {row.original.task_count}
          </Link>
        );
      },
    },
    {
      accessorKey: "schedule_state",
      header: "State",
      size: 100,
      // Cell: handleStateAnimation(),
    },
    {
      accessorKey: "envColumn1",
      header: envColumn1,
      id: envColumn1,
      size: 150,
      Cell: customCellAction(),
    },
    {
      accessorKey: "envColumn2", //access nested data with dot notation
      header: envColumn2,
      id: envColumn2,
      size: 150,
      Cell: customCellAction(),
    },
  ];

  function customCellAction() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === "N/A") {
        return (
          <ConfirmPromoteDialog
            cell={cell}
            open={openPromoteModal}
            envCell={envCell}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        );
      }
      return cellValue;
    };
  }

  // function handleStateAnimation() {
  //   return function ({ cell }) {
  //     const cellValue = cell.getValue();
  //     let switchOn = false;

  //     if (cellValue === "Active") {
  //       switchOn = true;
  //     }

  //     return (
  //       <Switch
  //         checked={switchOn}
  //         //onChange={handleChange}
  //         inputProps={{ "aria-label": "controlled" }}
  //       />
  //     );
  //   };
  // }

  useEffect(() => {
    (async () => {
      if (taskSchedules.length === 0) await fetchTaskSchedulesFromAllEnv();
    })();
    // eslint-disable-next-line
  }, []);

  if (taskSchedules.length > 0) {
    if (REACT_APP_ENV === "DEV") {
      currentEnvConfig = taskSchedules[0];
    } else if (REACT_APP_ENV === "TEST") {
      currentEnvConfig = taskSchedules[1];
    } else if (REACT_APP_ENV === "PROD") {
      currentEnvConfig = taskSchedules[2];
    } else {
      currentEnvConfig = taskSchedules[0];
    }

    devConfigs = taskSchedules[0];
    testConfigs = taskSchedules[1];
    prodConfigs = taskSchedules[2];

    detailData = currentEnvConfig.map((schedule) => {
      return {
        _schedule_id: schedule._schedule_id,
        schedule_id: schedule.schedule_id,
        schedule_interval: schedule.schedule_interval,
        schedule_count: schedule.schedule_count,
        schedule_cron: schedule.schedule_cron,
        schedule_state: schedule.schedule_state,
        schedule_type: schedule.schedule_type,
        schedule_cmd: schedule.schedule_cmd,
        schedule_group: schedule.schedule_group,
        parallel_execute: schedule.parallel_execute,
      };
    });
  }

  let loading = false;
  if (currentEnvConfig.length === 0) {
    loading = true;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="LIST TASK SCHEDULES" loading={loading} />;
  }

  return (
    <>
      <div className="cache-table">
        <h2 style={h2Style}>LIST TASK SCHEDULES</h2>

        {currentEnvConfig.length > 0 && (
          <TaskScheduleViewTable
            columns={columns}
            data={currentEnvConfig.map((querySchedule) => {
              const schedule_id = querySchedule._schedule_id;
              let col1 = getEnvCol(
                envColumn1,
                devConfigs,
                schedule_id,
                testConfigs,
                prodConfigs
              );

              let col2 = getEnvCol(
                envColumn2,
                devConfigs,
                schedule_id,
                testConfigs,
                prodConfigs
              );

              return {
                _schedule_id: querySchedule._schedule_id,
                schedule_id: querySchedule.schedule_id,
                task_count: querySchedule._taskCount_,
                schedule_interval: querySchedule.schedule_interval,
                schedule_count: querySchedule.schedule_count,
                schedule_cron: querySchedule.schedule_cron,
                schedule_state: querySchedule.schedule_state,
                schedule_type: querySchedule.schedule_type,
                schedule_cmd: querySchedule.schedule_cmd,
                schedule_group: querySchedule.schedule_group,
                parallel_execute: querySchedule.parallel_execute,
                envColumn1: col1,
                envColumn2: col2,
              };
            })}
            mainColumns={mainColumns}
          />
        )}
      </div>
    </>
  );
};

export default TaskSchedules;

function getEnvCol(
  envColumn,
  devConfigs,
  schedule_id,
  testConfigs,
  prodConfigs
) {
  let checkConfigExists = [];
  let columnValue = "N/A";

  if (envColumn === "Dev") {
    checkConfigExists = devConfigs.filter((config) => {
      return config._schedule_id === schedule_id;
    });
  } else if (envColumn === "Test") {
    checkConfigExists = testConfigs.filter((config) => {
      return config._schedule_id === schedule_id;
    });
  } else if (envColumn === "Prod") {
    checkConfigExists = prodConfigs.filter((config) => {
      return config._schedule_id === schedule_id;
    });
  }

  if (checkConfigExists.length > 0) {
    columnValue = checkConfigExists[0].schedule_state;
  }
  return columnValue;
}
