import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const ViewDetailsIcon = ({ row, tooltip, onClick }) => {
  return (
    <IconButton sx={{ color: "blue" }} onClick={() => onClick({ row })}>
      <Tooltip id="button-report" title={tooltip}>
        <InfoIcon sx={{ color: "#2BB05B" }} />
      </Tooltip>
    </IconButton>
  );
};

export default ViewDetailsIcon;
