import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link, Box } from "@mui/material";
import * as API from "../api/index";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FileTransferCommandViewTable from "./fileTransferCommandView";
import "../util/tables.css";

const columns = [
  {
    accessorKey: "TRANSFER_SEQ", //access nested data with dot notation
    header: "Transfer Seq",
    size: 150,
    enableEditing: false,
  },
  {
    id: "PARENT_SEQ",
    accessorKey: "PARENT_SEQ",
    header: "Parent Seq",
    size: 150,
    enableEditing: false,
    enableHiding: true,
  },
  {
    accessorKey: "TRANSFER_NAME", //access nested data with dot notation
    header: "Transfer Name",
    size: 150,
  },
  {
    accessorKey: "STATE", //access nested data with dot notation
    header: "Status",

    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalState = cell.row.original.STATE;
      const [stateValue, setState] = React.useState(originalState);

      const handleChange = (event) => {
        cell.row._valuesCache.STATE = event.target.value;
        setState(event.target.value);
      };

      const items = ["Active", "Disable"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              Status
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={stateValue}
              defaultValue={originalState}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "PROJECT_ID",
    header: "Project ID",
    size: 150,
    enableHiding: true,
  },
  {
    accessorKey: "FREQUENCY",
    header: "Frequency",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let orgFrequency = cell.row.original.FREQUENCY;
      const [frequency, setFrequency] = React.useState(orgFrequency);

      const handleChange = (event) => {
        cell.row._valuesCache.FREQUENCY = event.target.value;
        setFrequency(event.target.value);
      };

      const items = ["HOURLY", "DAILY", "WEEKLY", "MONTHLY"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              Frequency
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={frequency}
              defaultValue={orgFrequency}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "CMD", //access nested data with dot notation
    header: "Command",
    id: "CMD",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalCmd = cell.row.original.CMD;
      const [command, setCommand] = React.useState(originalCmd);

      const handleChange = (event) => {
        cell.row._valuesCache.CMD = event.target.value;
        setCommand(event.target.value);
      };

      const items = [
        "copy",
        "move",
        "convert",
        "csvsubset",
        "rename",
        "rezip",
        "unzip",
        "transform",
      ];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              Command
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={command}
              defaultValue={originalCmd}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "CMD_GROUP", //access nested data with dot notation
    header: "Command Group",
    size: 150,
  },
  {
    accessorKey: "SOURCE",
    header: "Source",
    size: 150,
  },
  {
    accessorKey: "DESTINATION", //access nested data with dot notation
    header: "Destination",
    size: 150,
  },
  {
    accessorKey: "FILTER", //access nested data with dot notation
    header: "Filter",
    size: 150,
  },
  {
    accessorKey: "FROM_PATH",
    header: "From",
    size: 150,
  },
  {
    accessorKey: "TO_PATH", //access nested data with dot notation
    header: "To",
    size: 150,
  },
  {
    accessorKey: "ARCHIVE", //access nested data with dot notation
    header: "Archive",
    size: 150,
  },
  {
    accessorKey: "FILE_MASK", //access nested data with dot notation
    header: "File mask",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalFileMask = cell.row.original.FILE_MASK;
      const [fileMask, setFileMask] = React.useState(originalFileMask);

      const handleChange = (event) => {
        cell.row._valuesCache.FILE_MASK = event.target.value;
        setFileMask(event.target.value);
      };

      const items = ["true", "false"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              File Mask
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={fileMask}
              defaultValue={originalFileMask}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "PROCESS",
    header: "Process",
    size: 150,
  },
  {
    accessorKey: "REQUIRED_FL", //access nested data with dot notation
    header: "Required flag",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalFlag = cell.row.original.REQUIRED_FL;
      const [flag, setFlag] = React.useState(originalFlag);

      const handleChange = (event) => {
        cell.row._valuesCache.REQUIRED_FL = event.target.value;
        setFlag(event.target.value);
      };

      const items = ["0", "1"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              Required flag
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={flag}
              defaultValue={originalFlag}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "OVERWRITE_FL", //access nested data with dot notation
    header: "Overwrite flag",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalFlag = cell.row.original.OVERWRITE_FL;
      const [flag, setFlag] = React.useState(originalFlag);

      const handleChange = (event) => {
        cell.row._valuesCache.OVERWRITE_FL = event.target.value;
        setFlag(event.target.value);
      };

      const items = ["0", "1"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              Overwrite flag
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={flag}
              defaultValue={originalFlag}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "SUBFOLDERS_FL", //access nested data with dot notation
    header: "Sub Folder Flag",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalFlag = cell.row.original.SUBFOLDERS_FL;
      const [flag, setFlag] = React.useState(originalFlag);

      const handleChange = (event) => {
        cell.row._valuesCache.SUBFOLDERS_FL = event.target.value;
        setFlag(event.target.value);
      };

      const items = ["0", "1"];

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              Sub Folder flag
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={flag}
              defaultValue={originalFlag}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
  {
    accessorKey: "LIST_LIMIT",
    header: "List Limit",
    size: 150,
  },
  {
    accessorKey: "LIST_ORDER", //access nested data with dot notation
    header: "List Order",
    size: 150,
  },
  {
    accessorKey: "REPLACES", //access nested data with dot notation
    header: "Replaces",
    size: 150,
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const FileCommands = () => {
  const [commandList, setCommandList] = useState([]);
  const navigate = useNavigate();
  let { transferID } = useParams();

  const resourcePath = "integrations/cfg/transfer/" + transferID + "/cmds";

  useEffect(() => {
    API.GetDataFromAPI(resourcePath).then((data) => {
      data.map((el) => {
        delete el["_pkTransferCmd"];
        delete el["pkTransferCmd"];
        delete el["CREATED_BY"];
        delete el["CREATED_TS"];
        return el;
      });
      setCommandList(data);
    });
  }, [resourcePath]);

  //const transfer_seq = commandList.length;
  const dataParams = {
    transferID: transferID,
    transfer_seq: getNextSeqNumber(),
  };

  function onClick() {
    navigate("/AddTransferCmd", { state: dataParams });
  }

  function onClickBackButton() {
    navigate(-1);
  }

  function getNextSeqNumber() {
    var nextSeqNumber = 0;
    nextSeqNumber = Math.max(...commandList.map((cmd) => cmd.TRANSFER_SEQ), 0);
    return nextSeqNumber + 1;
  }

  return (
    <div className="relative">
      <span>
        <h2 style={h2Style}>PROJECT {transferID} TRANSFER LIST CMDS</h2>
      </span>
      {commandList.length > 0 && (
        <>
          <FileTransferCommandViewTable
            data={commandList.map((command) => ({
              TRANSFER_ID: command.TRANSFER_ID,
              TRANSFER_SEQ: command.TRANSFER_SEQ,
              PARENT_SEQ: command.PARENT_SEQ,
              TRANSFER_NAME: command.TRANSFER_NAME,
              STATE: command.STATE,
              PROJECT_ID: command.PROJECT_ID,
              FREQUENCY: command.FREQUENCY,
              CMD: command.CMD,
              CMD_GROUP: command.CMD_GROUP,
              SOURCE: command.SOURCE,
              DESTINATION: command.DESTINATION,
              FILTER: command.FILTER,
              FROM_PATH: command.FROM_PATH,
              TO_PATH: command.TO_PATH,
              ARCHIVE: command.ARCHIVE,
              FILE_MASK: command.FILE_MASK,
              PROCESS: command.PROCESS,
              REQUIRED_FL: command.REQUIRED_FL,
              OVERWRITE_FL: command.OVERWRITE_FL,
              SUBFOLDERS_FL: command.SUBFOLDERS_FL,
              LIST_LIMIT: command.LIST_LIMIT,
              LIST_ORDER: command.LIST_ORDER,
              REPLACES: command.REPLACES,
            }))}
            columns={columns}
            dataParams={dataParams}
          />
        </>
      )}

      {commandList.length === 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              left: "15px",
            }}
          >
            <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                margin: "15px",
                width: "30px",
              }}
            >
              {"BACK"}
            </Link>

            <Link
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                margin: "15px",
                width: "200px",
              }}
            >
              {"ADD NEW COMMAND"}
            </Link>
          </Box>
        </>
      )}
    </div>
  );
};

export default FileCommands;
