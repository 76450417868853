import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { Link, Box } from "@mui/material";
import * as API from "../api/index";

const ConnectionCommandViewTable = (tableData) => {
  const [data, setData] = useState(tableData.data);

  const navigate = useNavigate();

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    data[row.index] = values;
    const TRANSFER_ID = tableData.dataParams.transferID;

    const payload = {
      TRANSFER_ID: TRANSFER_ID,
      TRANSFER_SEQ: values.TRANSFER_SEQ,
      PARENT_SEQ: values.PARENT_SEQ,
      TRANSFER_NAME: values.TRANSFER_NAME,
      STATE: values.STATE,
      PROJECT_ID: values.PROJECT_ID,
      FREQUENCY: values.FREQUENCY,
      CMD: values.CMD,
      CMD_GROUP: values.CMD_GROUP,
      SOURCE: values.SOURCE,
      DESTINATION: values.DESTINATION,
      FILTER: values.FILTER,
      FROM_PATH: values.FROM_PATH,
      TO_PATH: values.TO_PATH,
      ARCHIVE: values.ARCHIVE,
      FILE_MASK: values.FILE_MASK,
      PROCESS: values.PROCESS,
      REQUIRED_FL: values.REQUIRED_FL,
      OVERWRITE_FL: values.OVERWRITE_FL,
      SUBFOLDERS_FL: values.SUBFOLDERS_FL,
      LIST_LIMIT: values.LIST_LIMIT,
      LIST_ORDER: values.LIST_ORDER,
      REPLACES: values.REPLACES,
    };

    const apiPayload = data;
    apiPayload[row.index] = payload;

    const resourcePath = "integrations/cfg/transfer/" + TRANSFER_ID + "/cmds";

    API.UpdateRecord(resourcePath, apiPayload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record Updated", "File Command", 10000);
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });

    setData([...data]);
    exitEditingMode(); //required to exit editing mode
  };

  const stateParams = {
    transferID: tableData.dataParams.transferID,
    transfer_seq: tableData.dataParams.transfer_seq,
    original_data: tableData.data,
  };

  function onClick() {
    navigate("/AddConnectionCmd", { state: stateParams });
  }

  function onClickBackButton() {
    navigate(-1);
  }

  const handleRowEditCancel = ({ row, table }) => {
    //avoid setting invalid state on cancel edit
    setData([...data]);
  };

  return (
    <MaterialReactTable
      columns={tableData.columns}
      data={data}
      editingMode="modal"
      enableEditing
      onEditingRowSave={handleSaveRow}
      onEditingRowCancel={handleRowEditCancel}
      initialState={{
        density: "compact",
        columnVisibility: {
          PARENT_SEQ: false,
          PROJECT_ID: false,
        },
      }}
      enableStickyHeader
      enableStickyFooter
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              //left: "15px",
            }}
          >
            <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                paddingLeft: "15px",
                width: "30px",
              }}
            >
              {"BACK"}
            </Link>

            <Link
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                //margin: "15px",
                paddingLeft: "15px",
                width: "200px",
              }}
            >
              {"ADD COMMAND"}
            </Link>
          </Box>
        </>
      )}
      autoResetPageIndex={false}
      enableRowOrdering
      enableSorting={false}
      muiTableBodyRowDragHandleProps={({ table }) => ({
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState();
          if (hoveredRow && draggingRow) {
            data.splice(
              hoveredRow.index,
              0,
              data.splice(draggingRow.index, 1)[0]
            );

            // overwrite existing transfer sequence with new order as DB record is delete and inserted into
            for (var i = 0; i < data.length; i++) {
              data[i].TRANSFER_SEQ = i;
            }

            const TRANSFER_ID = tableData.dataParams.transferID;

            const resourcePath =
              "integrations/cfg/transfer/" + TRANSFER_ID + "/cmds";

            API.UpdateRecord(resourcePath, data).then((response) => {
              if (response.code === 200) {
                NotificationManager.success(
                  "Transfer sequence successfully updated",
                  "File Command",
                  10000
                );
              } else {
                NotificationManager.error(response.message, "Failed!", 10000);
              }
            });

            setData([...data]);
          }
        },
      })}
    />
  );
};

export default ConnectionCommandViewTable;
