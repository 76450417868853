import * as API from "../components/api/index";
import { createContext, useState, useContext, useRef } from "react";

const DataViewContext = createContext();

export const useDataView = () => useContext(DataViewContext);

export function DataViewProvider({ children }) {
  const mainNavIndexSession = sessionStorage.getItem("mainNavIndex");
  let currentIndex = 0;
  if (mainNavIndexSession) {
    currentIndex = parseInt(mainNavIndexSession);
  }

  const subNavIndexSession = sessionStorage.getItem("subNavIndex");
  let currentSubNavIndex = 0;
  if (subNavIndexSession) {
    currentSubNavIndex = parseInt(subNavIndexSession);
  }

  let defaultNavigationIndex = {
    main: currentIndex,
    sub: currentSubNavIndex,
  };

  const { REACT_APP_ENV = "DEV" } = process.env;

  let envColumn1 = "";
  let envColumn2 = "";

  if (REACT_APP_ENV === "TEST") {
    envColumn1 = "Dev";
    envColumn2 = "Prod";
  } else if (REACT_APP_ENV === "PROD") {
    envColumn1 = "Dev";
    envColumn2 = "Test";
  } else {
    envColumn1 = "Test";
    envColumn2 = "Prod";
  }

  const [fileTransfers, setFileTransfers] = useState([]);
  const [eiConnections, setEIConnections] = useState([]);
  const [ouraPatients, setOuraPatients] = useState([]);
  const [apiConfigs, setApiConfigs] = useState([]);
  const [serviceConfigs, setServiceConfigs] = useState([]);
  const [taskSchedules, setQuerySchedules] = useState([]);
  const [remarqueEvents, setRemarqueEvents] = useState([]);
  const [errorLogReport, setErrorLogReport] = useState([]);
  const [eventsReport, setEventsReport] = useState([]);
  const [recieveDataReport, setRecieveDataReport] = useState([]);
  const [queryInfo, setQueryInfo] = useState([]);

  const [navigationIndex, setNavigationIndex] = useState(
    defaultNavigationIndex
  );
  const [fileTransferStatsByTransID, setFileTransferStatsByTransID] = useState(
    []
  );
  const [fileTransferStatsByGroup, setfileTransferStatsByGroup] = useState([]);
  const [fileTransferStatsByProject, setfileTransferStatsByProject] = useState(
    []
  );
  const [fileTransferHistory, setFileTransferHistory] = useState([]);
  const [eiCacheList, setEICacheList] = useState([]);

  const [openPromoteModal, setOpenPromoteModal] = useState(false);
  const [openDetailForm, setOpenDetailForm] = useState(false);
  const [editable, setEditable] = useState(false);

  let envCell = useRef(null);
  const curRow = useRef(null);

  const detailViewBoxStyle = {
    //position: "absolute",
    top: "20%",
    left: "20%",
    //transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 0,
    p: 4,
  };

  const handleClickOpenDetail = ({ row }) => {
    curRow.current = row;
    setOpenDetailForm(true);
  };

  const handleDetailClose = () => {
    setOpenDetailForm(false);
    setEditable(false);
  };

  const onEditClick = () => {
    setEditable(true);
  };

  const handleClickOpen = (cell) => {
    let id = "" + cell.id;
    id = id.split("_")[1];
    cell.id = id;
    envCell.current = cell;
    setOpenPromoteModal(true);
  };

  const handleClose = () => {
    setOpenPromoteModal(false);
  };

  const fetchFileTransfers = () => {
    const resourcePath = "integrations/cfg/transfers";
    return API.GetDataFromAPI(resourcePath).then((data) =>
      setFileTransfers(data)
    );
  };

  const fetchFileTransfersFromAllEnv = () => {
    const resourcePath = "integrations/cfg/transfers";

    API.GetDataFromAllEnv(resourcePath).then((data) => {
      setFileTransfers(data);
    });
  };

  const fetchEIConenctions = () => {
    const resourcePath = "integrations/cfg/connections";
    return API.GetDataFromAPI(resourcePath).then((data) =>
      setEIConnections(data)
    );
  };

  const fetchEIConenctionsFromAllEnv = () => {
    const resourcePath = "integrations/cfg/connections";
    return API.GetDataFromAllEnv(resourcePath).then((data) =>
      setEIConnections(data)
    );
  };

  const fetchOuraPatients = () => {
    const resourcePath = "incyte/oura/onboard";
    return API.GetDataFromAPI(resourcePath).then((data) =>
      setOuraPatients(data)
    );
  };

  const fetchAPIConfigs = () => {
    const resourcePath = "integrations/cfg/apiconfigs";
    API.GetDataFromAPI(resourcePath).then((data) => setApiConfigs(data));
  };

  const fetchAPIConfigsFromAllEnv = () => {
    const resourcePath = "integrations/cfg/apiconfigs";

    API.GetDataFromAllEnv(resourcePath).then((data) => {
      setApiConfigs(data);
    });
  };

  const fetchServiceConfigs = () => {
    const resourcePath = "integrations/cfg/serviceconfigs";
    return API.GetDataFromAPI(resourcePath).then((data) =>
      setServiceConfigs(data)
    );
  };

  const fetchServiceConfigsFromAllEnv = () => {
    const resourcePath = "integrations/cfg/serviceconfigs";
    return API.GetDataFromAllEnv(resourcePath).then((data) =>
      setServiceConfigs(data)
    );
  };

  const fetchQuerySchedules = () => {
    const resourcePath = "integrations/cfg/schedules";
    return API.GetDataFromAPI(resourcePath).then((data) =>
      setQuerySchedules(data)
    );
  };

  const fetchTaskSchedulesFromAllEnv = () => {
    const resourcePath = "integrations/cfg/schedules";
    return API.GetDataFromAllEnv(resourcePath).then((data) =>
      setQuerySchedules(data)
    );
  };

  const fetchRemarqueEvents = () => {
    const resourcePath = "integrations/remarque/lastgetevents";

    return API.GetDataFromAPI(resourcePath).then((data) =>
      setRemarqueEvents(data)
    );
  };

  const fetchFileTransferStatsByTransID = () => {
    const resourcePath = "integrations/cfg/transfers/count";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setFileTransferStatsByTransID(data);
    });
  };

  const fetchFileTransferStatsByProject = () => {
    const resourcePath = "integrations/cfg/transfers/project";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setfileTransferStatsByProject(data);
    });
  };

  const fetchFileTransferStatsByGroup = () => {
    const resourcePath = "integrations/cfg/transfers/group";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setfileTransferStatsByGroup(data);
    });
  };

  const fetchfileTransferHistory = () => {
    const resourcePath = "integrations/cfg/transfers/daily";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setFileTransferHistory(data);
    });
  };

  const fetchEICacheList = () => {
    const resourcePath = "integrations/esb/cache/list";
    return API.GetCacheListEI(resourcePath).then((data) => {
      const cacheList = data["cache.data"];
      let names = cacheList.map((cacheItem) => {
        return {
          name: cacheItem.name,
          size: cacheItem.size,
          data: cacheItem.data,
        };
      });

      names = names.filter(function (element) {
        return element.name !== undefined;
      });

      setEICacheList(names);
    });
  };

  const fetchErrorLogReport = () => {
    const resourcePath = "integrations/esb/errorlog/count";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setErrorLogReport(data);
    });
  };

  const fetchEventsReport = () => {
    const resourcePath = "integrations/esb/events/count";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setEventsReport(data);
    });
  };

  const fetchRecieveDataReport = () => {
    const resourcePath = "integrations/esb/receivedata/count";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setRecieveDataReport(data);
    });
  };

  const fetchQueryInfo = () => {
    const resourcePath = "integrations/cfg/query/info";
    return API.GetDataFromAPI(resourcePath).then((data) => {
      setQueryInfo(data);
    });
  };

  return (
    <DataViewContext.Provider
      value={{
        fileTransfers,
        setFileTransfers,
        eiConnections,
        setEIConnections,
        ouraPatients,
        setOuraPatients,
        apiConfigs,
        setApiConfigs,
        serviceConfigs,
        setServiceConfigs,
        taskSchedules,
        setQuerySchedules,
        navigationIndex,
        setNavigationIndex,
        fileTransferStatsByTransID,
        setFileTransferStatsByTransID,
        fileTransferStatsByGroup,
        setfileTransferStatsByGroup,
        fileTransferStatsByProject,
        setfileTransferStatsByProject,
        fileTransferHistory,
        setFileTransferHistory,
        eiCacheList,
        setEICacheList,
        remarqueEvents,
        setRemarqueEvents,
        errorLogReport,
        setErrorLogReport,
        eventsReport,
        setEventsReport,
        recieveDataReport,
        setRecieveDataReport,
        queryInfo,
        setQueryInfo,
        fetchFileTransfers,
        fetchEIConenctions,
        fetchOuraPatients,
        fetchAPIConfigs,
        fetchServiceConfigs,
        fetchQuerySchedules,
        fetchFileTransferStatsByTransID,
        fetchFileTransferStatsByProject,
        fetchFileTransferStatsByGroup,
        fetchEICacheList,
        fetchRemarqueEvents,
        fetchErrorLogReport,
        fetchEventsReport,
        fetchRecieveDataReport,
        fetchQueryInfo,
        fetchAPIConfigsFromAllEnv,
        fetchServiceConfigsFromAllEnv,
        fetchTaskSchedulesFromAllEnv,
        fetchEIConenctionsFromAllEnv,
        fetchFileTransfersFromAllEnv,
        fetchfileTransferHistory,
        REACT_APP_ENV,
        envColumn1,
        envColumn2,
        openPromoteModal,
        setOpenPromoteModal,
        openDetailForm,
        editable,
        envCell,
        curRow,
        handleClickOpenDetail,
        handleDetailClose,
        onEditClick,
        handleClickOpen,
        handleClose,
        detailViewBoxStyle,
      }}
    >
      {children}
    </DataViewContext.Provider>
  );
}
