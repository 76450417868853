import React from "react";
import "./tables.css";
import KeyboardDoubleArrowUpSharpIcon from "@mui/icons-material/KeyboardDoubleArrowUpSharp";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const ConfirmPromoteDialog = ({
  cell,
  open,
  envCell,
  handleClickOpen,
  handleClose,
  handleConfirm,
}) => {
  const cellValue = cell.getValue();
  const tooltip = "Promote to " + cell.column.id;
  let env = "";

  if (envCell.current != null) {
    env = envCell.current.id;
  }

  const styleDetailBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignSelf: "flex-start",
    bgcolor: "background.paper",
    border: "2px solid #2BB041",
    borderRadius: "10px",
    boxShadow: 0,
    p: 2,
  };

  return (
    <>
      {cellValue}
      <IconButton sx={{ color: "blue" }} onClick={() => handleClickOpen(cell)}>
        <Tooltip id="button-report" title={tooltip}>
          <KeyboardDoubleArrowUpSharpIcon sx={{ color: "#5F9EA0" }} />
        </Tooltip>
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
        paperprops={{
          elevation: 0,
          sx: {
            border: "solid 1px gray",
            boxShadow: "none",
          },
        }}
        sx={{
          overflow: "scroll",
          paddingLeft: "20%",
        }}
      >
        <Box sx={styleDetailBox}>
          <Typography
            id="spring-modal-title"
            variant="h6"
            component="h2"
            color="#2B73B0"
          >
            {`Promote to ${env}`}
          </Typography>
          <br />
          <Divider color="black" />
          <br />
          <Typography>{`Do you want to proceed with promoting?`}</Typography>
          <br />
          <div align="right">
            <IconButton sx={{ color: "blue" }} onClick={handleClose}>
              <Tooltip id="button-report" title="No">
                <CloseIcon sx={{ color: "#5F9EA0" }} />
              </Tooltip>
            </IconButton>
            <IconButton sx={{ color: "blue" }} onClick={handleConfirm}>
              <Tooltip id="button-report" title="Yes">
                <CheckIcon sx={{ color: "#5F9EA0" }} />
              </Tooltip>
            </IconButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmPromoteDialog;
