import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDataView } from "../../contexts/data.view.context";
import * as API from "../api/index";
import "../util/tables.css";
import ApiConfigTable from "./apiConfigView";
import ShowLoadingBar from "../util/showLoadingBar";
import ConfirmPromoteDialog from "../util/confirmPromoteDialog";
import viewDetailsModal from "./viewDetailsModal";

const h2Style = {
  paddingLeft: "20px",
};

const APIConfigs = () => {
  const {
    apiConfigs,
    fetchAPIConfigsFromAllEnv,
    REACT_APP_ENV,
    envColumn1,
    envColumn2,
    openPromoteModal,
    setOpenPromoteModal,
    envCell,
    handleClickOpen,
    handleClose,
  } = useDataView();

  let detailData = "";
  let tableData = "";
  let currentEnvAPIConfig = [];
  let devConfigs = [];
  let testConfigs = [];
  let prodConfigs = [];

  const mainColumns = [
    {
      accessorKey: "API_ID",
      header: "Api id",
      size: 275,
      Cell: viewDetailsModal({ detailData }),
    },
    {
      accessorKey: "MOD",
      header: "Mod",
      size: 75,
    },
    {
      accessorKey: "STATE",
      header: "State",
      size: 150,
    },
    {
      accessorKey: "envColumn1",
      header: envColumn1,
      id: envColumn1,
      size: 150,
      Cell: promoteToOtherEnv(),
    },
    {
      accessorKey: "envColumn2", //access nested data with dot notation
      header: envColumn2,
      id: envColumn2,
      size: 150,
      Cell: promoteToOtherEnv(),
    },
  ];

  const handleConfirmOnPromote = () => {
    const payload = [
      {
        API_ID: envCell.current.row.original.API_ID,
        MOD: envCell.current.row.original.MOD,
        PATH: envCell.current.row.original.PATH,
        OPERATION: envCell.current.row.original.OPERATION,
        STATE: "Pending",
        NAME: envCell.current.row.original.NAME,
        REF_TYPE: envCell.current.row.original.REF_TYPE,
        REF_ID: envCell.current.row.original.REF_ID,
        SECURITY_POLICY: envCell.current.row.original.SECURITY_POLICY,
        SAMPLE_DATA: envCell.current.row.original.SAMPLE_DATA,
        REF_DATA: envCell.current.row.original.REF_DATA,
      },
    ];

    const env = envCell.current.column.id;

    onSubmit(payload, env);
    setOpenPromoteModal(false);
  };

  const onSubmit = (payload, env) => {
    const resourcePath = "integrations/cfg/apiconfigs";

    API.AddRecordToOtherEnv(resourcePath, env, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success(
          "Record added to " + env + " env",
          "API Config",
          10000
        );
        fetchAPIConfigsFromAllEnv();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  function promoteToOtherEnv() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === "N/A") {
        return (
          <ConfirmPromoteDialog
            cell={cell}
            open={openPromoteModal}
            envCell={envCell}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleConfirm={handleConfirmOnPromote}
          />
        );
      }
      return cellValue;
    };
  }

  useEffect(() => {
    (async () => {
      if (apiConfigs.length === 0) await fetchAPIConfigsFromAllEnv();
    })();
    // eslint-disable-next-line
  }, []);

  if (apiConfigs.length > 0) {
    if (REACT_APP_ENV === "DEV") {
      currentEnvAPIConfig = apiConfigs[0];
    } else if (REACT_APP_ENV === "TEST") {
      currentEnvAPIConfig = apiConfigs[1];
    } else if (REACT_APP_ENV === "PROD") {
      currentEnvAPIConfig = apiConfigs[2];
    } else {
      currentEnvAPIConfig = apiConfigs[0];
    }

    devConfigs = apiConfigs[0];
    testConfigs = apiConfigs[1];
    prodConfigs = apiConfigs[2];

    detailData = currentEnvAPIConfig.map((apiConfig) => {
      return {
        _API_ID: apiConfig._API_ID,
        API_ID: apiConfig.API_ID,
        MOD: apiConfig.MOD,
        PATH: apiConfig.PATH,
        OPERATION: apiConfig.OPERATION,
        STATE: apiConfig.STATE,
        NAME: apiConfig.NAME,
        REF_TYPE: apiConfig.REF_TYPE,
        REF_ID: apiConfig.REF_ID,
        SECURITY_POLICY: apiConfig.SECURITY_POLICY,
        SAMPLE_DATA: apiConfig.SAMPLE_DATA,
        REF_DATA: apiConfig.REF_DATA,
      };
    });

    tableData = currentEnvAPIConfig.map((apiConfig) => {
      const col1 = getColumnValue(
        apiConfig,
        envColumn1,
        devConfigs,
        testConfigs,
        prodConfigs
      );

      const col2 = getColumnValue(
        apiConfig,
        envColumn2,
        devConfigs,
        testConfigs,
        prodConfigs
      );

      return {
        _API_ID: apiConfig._API_ID,
        API_ID: apiConfig.API_ID,
        MOD: apiConfig.MOD,
        PATH: apiConfig.PATH,
        OPERATION: apiConfig.OPERATION,
        STATE: apiConfig.STATE,
        NAME: apiConfig.NAME,
        REF_TYPE: apiConfig.REF_TYPE,
        REF_ID: apiConfig.REF_ID,
        SECURITY_POLICY: apiConfig.SECURITY_POLICY,
        SAMPLE_DATA: apiConfig.SAMPLE_DATA,
        REF_DATA: apiConfig.REF_DATA,
        envColumn1: col1,
        envColumn2: col2,
      };
    });
  }

  let loading = false;
  if (currentEnvAPIConfig.length === 0) {
    loading = true;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="LIST of API CONFIGS" loading={loading} />;
  }

  return (
    <div className="cache-table">
      <h2 style={h2Style}>LIST of API CONFIGS</h2>

      {currentEnvAPIConfig.length > 0 && (
        <ApiConfigTable
          data={tableData}
          mainColumns={mainColumns}
          autoResetPageIndex={false}
        />
      )}
    </div>
  );
};

export default APIConfigs;

function getColumnValue(
  apiConfig,
  envColumn,
  devConfigs,
  testConfigs,
  prodConfigs
) {
  const apiID = apiConfig._API_ID;
  let checkConfigExists = [];

  if (envColumn === "Dev") {
    checkConfigExists = devConfigs.filter((config) => {
      return config._API_ID === apiID;
    });
  } else if (envColumn === "Test") {
    checkConfigExists = testConfigs.filter((config) => {
      return config._API_ID === apiID;
    });
  } else if (envColumn === "Prod") {
    checkConfigExists = prodConfigs.filter((config) => {
      return config._API_ID === apiID;
    });
  }

  if (checkConfigExists.length > 0) {
    return checkConfigExists[0].STATE;
  }

  return "N/A";
}
