import React from "react";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { Link as MuiLink } from "@mui/material";
import { Box } from "@mui/material";

const FileTransferViewTable = ({ data, columns, mainColumns }) => {
  const navigate = useNavigate();

  function onClick() {
    navigate("/AddFileTransfer");
  }

  return (
    <MaterialReactTable
      columns={mainColumns}
      data={data}
      initialState={{
        density: "compact",
        columnVisibility: {
          TRANSFER_ID: true,
        },
      }}
      autoResetPageIndex={false}
      layoutMode="grid"
      muiTableHeadCellProps={{
        sx: {
          flex: "0 0 auto",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          flex: "0 0 auto",
        },
      }}
      enableStickyHeader
      enableStickyFooter
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
            }}
          >
            <MuiLink
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                paddingLeft: "15px",
                width: "200px",
              }}
            >
              {"ADD FILE TRANSFER"}
            </MuiLink>
          </Box>
        </>
      )}
    />
  );
};

export default FileTransferViewTable;
