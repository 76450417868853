import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link, Box } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import * as API from "../api/index";
import QueryScheduleTaskTable from "./queryScheduleTaskView";
import "../util/tables.css";

const columns = [
  {
    accessorKey: "task_no", //access nested data with dot notation
    header: "Task #",
    size: 150,
    enableEditing: false,
  },
  {
    id: "query_id",
    accessorKey: "query_id",
    header: "Query ID",
    size: 150,
  },
  {
    accessorKey: "query_params",
    header: "Query Params",
    size: 150,
  },
  {
    accessorKey: "entity",
    header: "Entity",
    size: 150,
  },
  {
    accessorKey: "service_name",
    header: "Service Name",
    size: 150,
  },
  {
    accessorKey: "task_state",
    header: "State",
    size: 150,
    Edit: ({ cell, column, table }) => {
      let originalState = cell.row.original.task_state;
      const [stateValue, setState] = React.useState(originalState);

      const items = ["Active", "Disable", "Not Active", "Pending", "Not Exist"];

      const handleChange = (event) => {
        cell.row._valuesCache.task_state = event.target.value;
        setState(event.target.value);
      };

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              State
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={stateValue}
              defaultValue={originalState}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const QueryScheduleTask = () => {
  const [queryScheduleTaskList, setqueryScheduleTaskList] = useState([]);
  const navigate = useNavigate();
  let { schedule_id } = useParams();

  const resourcePath = "integrations/cfg/schedule/" + schedule_id + "/tasks";

  useEffect(() => {
    API.GetDataFromAPI(resourcePath).then((data) =>
      setqueryScheduleTaskList(data)
    );
  }, [resourcePath]);

  const task_no = queryScheduleTaskList.length;
  const dataParams = { schedule_id: schedule_id, task_no: task_no };

  function onClick() {
    navigate("/AddQueryScheduleTask", { state: dataParams });
  }

  function onClickBackButton() {
    navigate(-1);
  }

  return (
    <div className="relative">
      <span>
        <h2 style={h2Style}> {schedule_id} TASKS</h2>
      </span>
      {queryScheduleTaskList.length > 0 && (
        <>
          <QueryScheduleTaskTable
            data={queryScheduleTaskList.map((command) => ({
              task_no: command.task_no,
              query_id: command.query_id,
              query_params: command.query_params,
              entity: command.entity,
              service_name: command.service_name,
              task_state: command.task_state,
            }))}
            columns={columns}
            dataParams={dataParams}
          />
        </>
      )}

      {queryScheduleTaskList.length === 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              left: "15px",
            }}
          >
            <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                margin: "15px",
                width: "30px",
              }}
            >
              {"BACK"}
            </Link>

            <Link
              underline="hover"
              onClick={onClick}
              variant="button"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                //padding: "15px",
                margin: "15px",
                width: "200px",
              }}
            >
              {"ADD TASK"}
            </Link>
          </Box>
        </>
      )}
    </div>
  );
};

export default QueryScheduleTask;
