import React, { useEffect, useRef, useState } from "react";
import ServiceConfigViewTable from "./serviceConfigView";
import "../util/tables.css";
import { useDataView } from "../../contexts/data.view.context";
import IconButton from "@mui/material/IconButton";
import * as API from "../api/index";
import { NotificationManager } from "react-notifications";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import ViewEditServiceConfig from "./viewEditServiceConfig";
import ShowLoadingBar from "../util/showLoadingBar";
import ConfirmPromoteDialog from "../util/confirmPromoteDialog";
import ViewDetailsModal from "../util/detailsModal/viewDetailsModal";

const h2Style = {
  paddingLeft: "20px",
};

const ServiceConfigs = () => {
  const {
    serviceConfigs,
    fetchServiceConfigsFromAllEnv,
    REACT_APP_ENV,
    envColumn1,
    envColumn2,
    envCell,
    openPromoteModal,
    setOpenPromoteModal,
    handleClickOpen,
    handleClose,
  } = useDataView();

  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const currentColumnRef = useRef(null);
  const titleRef = useRef(null);

  let emailList = [];

  const style = {
    left: "20%",
    marginRight: "20%",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #2BB041",
    boxShadow: 0,
    p: 4,
  };

  const handleDetailOpen = ({ cell }) => {
    currentColumnRef.current = cell.getValue();
    titleRef.current = cell.column.id;
    setOpenDetail(true);
  };

  const handleEditOpen = ({ cellValue }) => {
    currentColumnRef.current = cellValue;

    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleDetailClose = () => {
    setOpenDetail(false);
  };

  const handleConfirm = () => {
    const payload = [
      {
        ServiceName: envCell.current.row.original.ServiceName,
        PropertyName: envCell.current.row.original.PropertyName,
        PropertyValue: envCell.current.row.original.PropertyValue,
      },
    ];

    const env = envCell.current.column.id;

    onSubmit(payload, env);
    setOpenPromoteModal(false);
  };

  const onSubmit = (payload, env) => {
    const resourcePath = "integrations/cfg/serviceconfigs";

    API.AddRecordToOtherEnv(resourcePath, env, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success(
          "Record added to " + env + " env",
          "Service Config",
          10000
        );
        fetchServiceConfigsFromAllEnv();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  let currentEnvServiceConfigs = [];
  let devConfigs = [];
  let testConfigs = [];
  let prodConfigs = [];

  const mainColumns = [
    {
      accessorKey: "ServiceName",
      header: "Service Name",
      size: 75,
      enableEditing: false,
      Cell: editRecord(),
    },
    {
      accessorKey: "PropertyName",
      header: "Property Name",
      size: 75,
      enableEditing: false,
    },
    {
      accessorKey: "PropertyValue",
      header: "Property Value",
      size: 150,
      Cell: viewDetails(),
    },
    {
      accessorKey: "envColumn1",
      header: envColumn1,
      id: envColumn1,
      size: 150,
      Cell: customCellAction(),
      enableEditing: false,
    },
    {
      accessorKey: "envColumn2", //access nested data with dot notation
      header: envColumn2,
      id: envColumn2,
      size: 150,
      Cell: customCellAction(),
      enableEditing: false,
    },
  ];

  function customCellAction() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue === "N/A") {
        return (
          <ConfirmPromoteDialog
            cell={cell}
            open={openPromoteModal}
            envCell={envCell}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        );
      } else if (cellValue.length > 49) {
        return (
          <>
            {cellValue.substring(0, 49)}
            <ViewDetailsModal
              cell={cell}
              openDetail={openDetail}
              currentColumnRef={currentColumnRef}
              handleDetailOpen={handleDetailOpen}
              handleDetailClose={handleDetailClose}
              title={titleRef.current}
            />
          </>
        );
      }

      return cellValue;
    };
  }

  function viewDetails() {
    return function ({ cell }) {
      const cellValue = cell.getValue();

      if (cellValue.length > 50) {
        return (
          <>
            {cellValue.substring(0, 49)}
            <ViewDetailsModal
              cell={cell}
              openDetail={openDetail}
              currentColumnRef={currentColumnRef}
              handleDetailOpen={handleDetailOpen}
              handleDetailClose={handleDetailClose}
              title={titleRef.current}
            />
          </>
        );
      }

      return cellValue;
    };
  }

  function editRecord() {
    return function ({ cell, row }) {
      const cellText = cell.getValue();
      const cellValue = row;
      const tooltip = "Edit Record";

      return (
        <>
          <IconButton onClick={() => handleEditOpen({ cellValue })}>
            <Tooltip id="button-report" title={tooltip}>
              <EditIcon sx={{ color: "#5F9EA0" }} />
            </Tooltip>
          </IconButton>
          {cellText}

          <Modal
            open={openEdit}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            hideBackdrop
            paperprops={{
              elevation: 0,
              sx: {
                border: "solid 1px green",
                boxShadow: "none",
              },
            }}
            sx={{
              overflow: "auto",
              paddingLeft: "20%",
              top: "30%",
            }}
          >
            <Box sx={style}>
              <ViewEditServiceConfig
                curRow={currentColumnRef.current}
                handleEditClose={handleEditClose}
                emailList={emailList}
              />
            </Box>
          </Modal>
        </>
      );
    };
  }

  useEffect(() => {
    (async () => {
      if (serviceConfigs.length === 0) await fetchServiceConfigsFromAllEnv();
    })();
    // eslint-disable-next-line
  }, []);

  if (serviceConfigs.length > 0) {
    if (REACT_APP_ENV === "DEV") {
      currentEnvServiceConfigs = serviceConfigs[0];
    } else if (REACT_APP_ENV === "TEST") {
      currentEnvServiceConfigs = serviceConfigs[1];
    } else if (REACT_APP_ENV === "PROD") {
      currentEnvServiceConfigs = serviceConfigs[2];
    } else {
      currentEnvServiceConfigs = serviceConfigs[0];
    }

    devConfigs = serviceConfigs[0];
    testConfigs = serviceConfigs[1];
    prodConfigs = serviceConfigs[2];
  }

  let loading = false;
  if (currentEnvServiceConfigs.length === 0) {
    loading = true;
  } else {
    emailList = getEmailList(currentEnvServiceConfigs);
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="LIST of SERVICE CONFIGS" loading={loading} />;
  }

  return (
    <div className="service-config-table">
      <h2 style={h2Style}>LIST of SERVICE CONFIGS</h2>

      {currentEnvServiceConfigs.length > 0 && (
        <ServiceConfigViewTable
          data={getTableData(
            currentEnvServiceConfigs,
            envColumn1,
            devConfigs,
            testConfigs,
            prodConfigs,
            envColumn2
          )}
          mainColumns={mainColumns}
          emailList={emailList}
        />
      )}
    </div>
  );
};

export default ServiceConfigs;

function getTableData(
  currentEnvServiceConfigs,
  envColumn1,
  devConfigs,
  testConfigs,
  prodConfigs,
  envColumn2
) {
  return currentEnvServiceConfigs.map((serviceConfig) => {
    const _ServiceName = serviceConfig._ServiceName;
    const _PropertyName = serviceConfig._PropertyName;

    let col1 = getEnvCol(
      envColumn1,
      devConfigs,
      _ServiceName,
      _PropertyName,
      testConfigs,
      prodConfigs
    );

    let col2 = getEnvCol(
      envColumn2,
      devConfigs,
      _ServiceName,
      _PropertyName,
      testConfigs,
      prodConfigs
    );

    return {
      _ServiceName: serviceConfig._ServiceName,
      _PropertyName: serviceConfig._PropertyName,
      ServiceName: serviceConfig.ServiceName,
      PropertyName: serviceConfig.PropertyName,
      PropertyValue: serviceConfig.PropertyValue,
      envColumn1: col1,
      envColumn2: col2,
    };
  });
}

function getEmailList(currentEnvServiceConfigs) {
  const duplicateListArr = [];
  const serviceConfigValues = currentEnvServiceConfigs.map((serviceConfig) => {
    return serviceConfig.PropertyValue;
  });

  const tempEmailValues = serviceConfigValues.filter((value) => {
    return value.includes("@");
  });

  tempEmailValues.forEach((val) => {
    const mailArr = val.split(",");
    mailArr.forEach(function (element) {
      duplicateListArr.push(element.toLowerCase());
    });
  });

  const emailList = duplicateListArr.filter((element, index) => {
    return duplicateListArr.indexOf(element) === index;
  });
  return emailList;
}

function getEnvCol(
  envColumn,
  devConfigs,
  _ServiceName,
  _PropertyName,
  testConfigs,
  prodConfigs
) {
  let checkConfigExists = [];

  if (envColumn === "Dev") {
    checkConfigExists = devConfigs.filter((config) => {
      return (
        config._ServiceName === _ServiceName &&
        config._PropertyName === _PropertyName
      );
    });
  } else if (envColumn === "Test") {
    checkConfigExists = testConfigs.filter((config) => {
      return (
        config._ServiceName === _ServiceName &&
        config._PropertyName === _PropertyName
      );
    });
  } else if (envColumn === "Prod") {
    checkConfigExists = prodConfigs.filter((config) => {
      return (
        config._ServiceName === _ServiceName &&
        config._PropertyName === _PropertyName
      );
    });
  }

  if (checkConfigExists.length > 0) {
    const cellValue = checkConfigExists[0].PropertyValue;
    return cellValue;
  }

  return "N/A";
}
