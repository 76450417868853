import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";

import { Paper, Box, TextField, Typography, Button, Link } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const FileTransferCommandForm = () => {
  const validationSchema = Yup.object().shape({
    TRANSFER_NAME: Yup.string().required("TRANSFER_NAME is required"),
    STATE: Yup.string().required("STATE is required"),
    PROJECT_ID: Yup.string().required("Project ID is required"),
    FREQUENCY: Yup.string().required("FREQUENCY is required"),
    CMD: Yup.string().required("CMD is required"),
    CMD_GROUP: Yup.string().required("CMD_GROUP is required"),
    SOURCE: Yup.string().required("SOURCE is required"),
    DESTINATION: Yup.string().required("DESTINATION is required"),
    FILTER: Yup.string().nullable().notRequired().default(""),
    FROM_PATH: Yup.string().required("FROM_PATH is required"),
    TO_PATH: Yup.string().required("TO_PATH is required"),
    ARCHIVE: Yup.string().required("ARCHIVE is required"),
    FILE_MASK: Yup.string().required("FILE_MASK is required"),
    PROCESS: Yup.string().required("PROCESS is required"),
    REQUIRED_FL: Yup.string().required("REQUIRED_FL is required"),
    OVERWRITE_FL: Yup.string().required("OVERWRITE_FL is required"),
    SUBFOLDERS_FL: Yup.string().nullable().notRequired().default(""),
    LIST_LIMIT: Yup.string().nullable().notRequired().default(""),
    LIST_ORDER: Yup.string().nullable().notRequired().default(""),
    REPLACES: Yup.string().nullable().notRequired().default(""),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const transferMetaData = location.state;

  const stateItems = ["Active", "Disable"];
  const frequencyItems = ["HOURLY", "DAILY", "WEEKLY", "MONTHLY"];
  const itemsCommand = ["copy", "move", "convert", "csvsubset", "rename", "rezip", "unzip", "transform"];
  const itemsArchive = ["true", "false"];
  const itemsFileMask = ["true", "false"];
  const itemsFlags = ["0", "1"];

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onClickFileTrns = () => {
    navigate("/FileTransfers");
  };

  const transferID = transferMetaData.transferID;
  const transfer_seq = transferMetaData.transfer_seq;

  const onSubmit = (data) => {
    const payload = [
      {
        TRANSFER_ID: transferID,
        TRANSFER_SEQ: transfer_seq,
        PARENT_SEQ: 0,
        ...data,
      },
    ];

    let finalPayload;

    if (transferMetaData.original_data) {
      finalPayload = transferMetaData.original_data;
      finalPayload.push(...payload);
    } else {
      finalPayload = payload;
    }

    const resourcePath = "integrations/cfg/transfer/" + transferID + "/cmds";

    API.AddRecord(resourcePath, finalPayload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Connection Entry", 10000);
        navigate("/FileCommands/" + transferID);
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
          }}
        >
          <Link
            underline="hover"
            onClick={onClickBackButton}
            variant="button"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              size: "large",
              paddingLeft: "15px",
              width: "30px",
            }}
          >
            {"BACK"}
          </Link>

          <Link
            underline="hover"
            onClick={onClickFileTrns}
            variant="button"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
              size: "large",
              paddingLeft: "15px",
              width: "200px",
            }}
          >
            {"FILE TRANSFERS"}
          </Link>
        </Box>

        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            <Typography variant="h6" align="center" margin="dense">
              Add Transfer Command - {transferID}
            </Typography>

            <div>
              <TextField
                required
                id="TRANSFER_NAME"
                name="TRANSFER_NAME"
                label="Transfer Name"
                margin="dense"
                size="small"
                {...register("TRANSFER_NAME")}
                error={errors.TRANSFER_NAME ? true : false}
                variant="outlined"
              />
              <br />

              <TextField
                select
                label="State"
                id="STATE"
                margin="dense"
                size="small"
                {...register("STATE")}
                error={errors.STATE ? true : false}
                variant="outlined"
              >
                {stateItems.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="PROJECT_ID"
                name="PROJECT_ID"
                label="Project ID"
                margin="dense"
                size="small"
                {...register("PROJECT_ID")}
                error={errors.PROJECT_ID ? true : false}
                variant="outlined"
              />
              <br />

              <TextField
                select
                label="Frequency"
                id="FREQUENCY"
                margin="dense"
                size="small"
                {...register("FREQUENCY")}
                error={errors.FREQUENCY ? true : false}
                variant="outlined"
              >
                {frequencyItems.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />

              <TextField
                select
                label="Command"
                id="CMD"
                margin="dense"
                size="small"
                {...register("CMD")}
                error={errors.CMD ? true : false}
                variant="outlined"
              >
                {itemsCommand.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="CMD_GROUP"
                name="CMD_GROUP"
                label="Cmd Group"
                margin="dense"
                size="small"
                {...register("CMD_GROUP")}
                error={errors.CMD_GROUP ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="SOURCE"
                name="SOURCE"
                label="Source"
                margin="dense"
                size="small"
                {...register("SOURCE")}
                error={errors.SOURCE ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="DESTINATION"
                name="DESTINATION"
                label="Destination"
                margin="dense"
                size="small"
                {...register("DESTINATION")}
                error={errors.DESTINATION ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="FILTER"
                name="FILTER"
                label="Filter"
                margin="dense"
                size="small"
                {...register("FILTER")}
                error={errors.FILTER ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="FROM_PATH"
                name="FROM_PATH"
                label="From Path"
                margin="dense"
                size="small"
                {...register("FROM_PATH")}
                error={errors.FROM_PATH ? true : false}
                variant="outlined"
                style={{ width: "97%" }}
              />
              <br />
              <TextField
                required
                id="TO_PATH"
                name="TO_PATH"
                label="To Path"
                margin="dense"
                size="small"
                {...register("TO_PATH")}
                error={errors.TO_PATH ? true : false}
                variant="outlined"
                style={{ width: "97%" }}
              />
              <br />

              <TextField
                select
                label="Archive"
                id="ARCHIVE"
                margin="dense"
                size="small"
                {...register("ARCHIVE")}
                error={errors.ARCHIVE ? true : false}
                variant="outlined"
              >
                {itemsArchive.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>

              <br />

              <TextField
                select
                label="File Mask"
                id="FILE_MASK"
                margin="dense"
                size="small"
                {...register("FILE_MASK")}
                error={errors.FILE_MASK ? true : false}
                variant="outlined"
              >
                {itemsFileMask.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="PROCESS"
                name="PROCESS"
                label="Process"
                margin="dense"
                size="small"
                {...register("PROCESS")}
                error={errors.PROCESS ? true : false}
                variant="outlined"
              />
              <br />

              <TextField
                select
                label="Required Flag"
                id="REQUIRED_FL"
                margin="dense"
                size="small"
                {...register("REQUIRED_FL")}
                error={errors.REQUIRED_FL ? true : false}
                variant="outlined"
              >
                {itemsFlags.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />

              <TextField
                select
                label="Overwrite Flag"
                id="OVERWRITE_FL"
                margin="dense"
                size="small"
                {...register("OVERWRITE_FL")}
                error={errors.OVERWRITE_FL ? true : false}
                variant="outlined"
              >
                {itemsFlags.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />

              <TextField
                select
                label="Sub Folders Flag"
                id="SUBFOLDERS_FL"
                margin="dense"
                size="small"
                {...register("SUBFOLDERS_FL")}
                error={errors.SUBFOLDERS_FL ? true : false}
                variant="outlined"
              >
                {itemsFlags.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="LIST_LIMIT"
                name="LIST_LIMIT"
                label="List Limit"
                margin="dense"
                size="small"
                {...register("LIST_LIMIT")}
                error={errors.LIST_LIMIT ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="LIST_ORDER"
                name="LIST_ORDER"
                label="List Order"
                margin="dense"
                size="small"
                {...register("LIST_ORDER")}
                error={errors.LIST_ORDER ? true : false}
                variant="outlined"
              />
              <br />
              <TextField
                required
                id="REPLACES"
                name="REPLACES"
                label="Replaces"
                margin="dense"
                size="small"
                {...register("REPLACES")}
                error={errors.REPLACES ? true : false}
                variant="outlined"
              />
            </div>

            <Box mt={3} paddingLeft="10px">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default FileTransferCommandForm;
