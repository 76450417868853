import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../api/index";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { useDataView } from "../../contexts/data.view.context";
import "../util/tables.css";

import { Paper, Box, TextField, Typography, Button, Link } from "@mui/material";

const EIConnectionForm = () => {
  const validationSchema = Yup.object().shape({
    CONNECTION_ID: Yup.string().required("Connection ID is required"),
    CONNECTION_TYPE: Yup.string().required("Connection ID is required"),
    PROJECT_ID: Yup.string().required("Project ID is required"),
    PROJECT_OID: Yup.string().required("Project OID is required"),
    PROJECT_NAME: Yup.string().required("Project Name is required"),
    SPONSOR_NAME: Yup.string().required("Sponsor Name is required"),
    BUSINESS_ID: Yup.string().required("Business ID is required"),
    CONNECTION_URL: Yup.string().required("Connection URL is required"),
    CONNECTION_PROVIDER: Yup.string().required(
      "Connection Provider is required"
    ),
    CONNECTION_DATA: Yup.string().required("Connection Data is required"),
    AUTH_TYPE: Yup.string().required("Auth Type is required"),
    AUTH_ID: Yup.string().required("Auth ID is required"),
    AUTH_PIN: Yup.string().required("Auth PIN is required"),
    AUTH_PASSCODE: Yup.string().required("Ath  passcode is required"),
    STATE: Yup.string().required("State is required"),
    MAX_ROWS_PER_QUERY: Yup.string().nullable().notRequired().default(""),
    MAX_QUERIES: Yup.string().nullable().notRequired().default(""),
    PING_SERVICE: Yup.string().nullable().notRequired().default(""),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fetchEIConenctionsFromAllEnv } = useDataView();
  const navigate = useNavigate();

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    const resourcePath = "integrations/cfg/connections";
    API.AddRecord(resourcePath, data).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record added!", "Connection Entry", 10000);
        fetchEIConenctionsFromAllEnv();
        navigate("/EIConnection");
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  };

  return (
    <div className="form">
      <Fragment>
        <Paper>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            align="left"
          >
            <Link
              underline="hover"
              onClick={onClickBackButton}
              variant="button"
              align="left"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                size: "large",
                paddingLeft: "15px",
                width: "50px",
              }}
            >
              {"BACK"}
            </Link>
            <Typography variant="h6" paddingLeft="10px" margin="dense">
              Add EI Connection
            </Typography>
            <div paddingLeft="200px">
              <TextField
                required
                id="CONNECTION_ID"
                name="CONNECTION_ID"
                label="Connection ID"
                margin="dense"
                {...register("CONNECTION_ID")}
                error={errors.CONNECTION_ID ? true : false}
                variant="outlined"
                size="small"
              />
              <br />
              <TextField
                required
                id="CONNECTION_TYPE"
                name="CONNECTION_TYPE"
                label="Connection Type"
                margin="dense"
                {...register("CONNECTION_TYPE")}
                error={errors.CONNECTION_TYPE ? true : false}
                size="small"
              />
              <br />
              <TextField
                required
                id="PROJECT_ID"
                name="PROJECT_ID"
                label="Project ID"
                margin="dense"
                {...register("PROJECT_ID")}
                error={errors.PROJECT_ID ? true : false}
                size="small"
              />
              <br />
              <TextField
                required
                id="PROJECT_OID"
                name="PROJECT_OID"
                label="Project OID"
                margin="dense"
                {...register("PROJECT_OID")}
                error={errors.PROJECT_OID ? true : false}
                size="small"
              />
              <br />
              <TextField
                required
                id="PROJECT_NAME"
                name="PROJECT_NAME"
                label="Project Name"
                margin="dense"
                {...register("PROJECT_NAME")}
                error={errors.PROJECT_NAME ? true : false}
                size="small"
              />
              <br />
              <TextField
                required
                id="SPONSOR_NAME"
                name="SPONSOR_NAME"
                label="Sponsor Name"
                margin="dense"
                {...register("SPONSOR_NAME")}
                error={errors.SPONSOR_NAME ? true : false}
                size="small"
              />
              <br />
              <TextField
                required
                id="BUSINESS_ID"
                name="BUSINESS_ID"
                label="Business ID"
                margin="dense"
                {...register("BUSINESS_ID")}
                error={errors.BUSINESS_ID ? true : false}
                size="small"
              />
              <br />
              <TextField
                required
                id="STATE"
                name="STATE"
                label="State"
                margin="dense"
                size="small"
                {...register("STATE")}
                error={errors.STATE ? true : false}
              />
              <br />
              <TextField
                required
                id="CONNECTION_URL"
                name="CONNECTION_URL"
                label="Connection URL"
                margin="dense"
                size="small"
                style={{ width: "60%" }}
                {...register("CONNECTION_URL")}
                error={errors.CONNECTION_URL ? true : false}
              />
              <br />

              <TextField
                required
                id="CONNECTION_PROVIDER"
                name="CONNECTION_PROVIDER"
                label="Connection Provider"
                margin="dense"
                size="small"
                style={{ width: "60%" }}
                {...register("CONNECTION_PROVIDER")}
                error={errors.CONNECTION_PROVIDER ? true : false}
              />
              <br />
              <TextField
                required
                id="AUTH_TYPE"
                name="AUTH_TYPE"
                label="Auth Type"
                margin="dense"
                size="small"
                {...register("AUTH_TYPE")}
                error={errors.AUTH_TYPE ? true : false}
              />
              <br />
              <TextField
                required
                id="AUTH_ID"
                name="AUTH_ID"
                label="Auth ID"
                margin="dense"
                size="small"
                style={{ width: "30%" }}
                {...register("AUTH_ID")}
                error={errors.AUTH_ID ? true : false}
              />
              <br />
              <TextField
                required
                id="AUTH_PIN"
                name="AUTH_PIN"
                label="Auth PIN"
                margin="dense"
                size="small"
                style={{ width: "30%" }}
                {...register("AUTH_PIN")}
                error={errors.AUTH_PIN ? true : false}
              />
              <br />
              <TextField
                required
                id="AUTH_PASSCODE"
                name="AUTH_PASSCODE"
                label="Auth Passcode"
                margin="dense"
                size="small"
                style={{ width: "60%" }}
                {...register("AUTH_PASSCODE")}
                error={errors.AUTH_PASSCODE ? true : false}
              />
              <br />
              <TextField
                id="MAX_ROWS_PER_QUERY"
                name="MAX_ROWS_PER_QUERY"
                label="Max rows per query"
                margin="dense"
                size="small"
                {...register("MAX_ROWS_PER_QUERY")}
                error={errors.MAX_ROWS_PER_QUERY ? true : false}
              />
              <br />
              <TextField
                id="MAX_QUERIES"
                name="MAX_QUERIES"
                label="Max queries"
                margin="dense"
                size="small"
                {...register("MAX_QUERIES")}
                error={errors.MAX_QUERIES ? true : false}
              />
              <br />
              <TextField
                id="PING_SERVICE"
                name="PING_SERVICE"
                label="Ping Service"
                margin="dense"
                size="small"
                style={{ width: "60%" }}
                {...register("PING_SERVICE")}
                error={errors.PING_SERVICE ? true : false}
              />
              <br />
              <TextField
                required
                id="CONNECTION_DATA"
                name="CONNECTION_DATA"
                label="Connection Data"
                {...register("CONNECTION_DATA")}
                error={errors.CONNECTION_DATA ? true : false}
                multiline
                rows={4}
                style={{ width: "60%" }}
              />
            </div>
            <Box mt={3} paddingLeft={10}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    </div>
  );
};

export default EIConnectionForm;
