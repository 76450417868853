import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, FormControl, FormLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";

const ViewEditTaskSchedule = ({
  curRow,
  handleDetailClose,
  editable,
  onEditClick,
  data,
}) => {
  const { fetchTaskSchedulesFromAllEnv } = useDataView();
  const values = curRow.original;

  const [formData, setFormData] = useState({
    _schedule_id: values._schedule_id,
    schedule_id: values.schedule_id,
    schedule_interval: values.schedule_interval,
    schedule_count: values.schedule_count,
    schedule_cron: values.schedule_cron,
    schedule_state: values.schedule_state,
    schedule_type: values.schedule_type,
    schedule_cmd: values.schedule_cmd,
    schedule_group: values.schedule_group,
    parallel_execute: values.parallel_execute,
  });

  const states = ["Active", "Disable"];
  const types = ["crontab", "synapse"];
  const parallel_execute_options = ["Y", "N"];

  const stateItems = states.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  const typeItems = types.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  const parallel_execute_Items = parallel_execute_options.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    const schedule_id = formData.schedule_id;
    const origScheduleId = values.schedule_id;
    let checkConnectionIDExists = null;

    if (origScheduleId === schedule_id) {
      // connection id is not edited
    } else {
      checkConnectionIDExists = data.find(
        (connection) => connection.schedule_id === schedule_id
      );
    }

    if (checkConnectionIDExists) {
      NotificationManager.error("", "Schedule ID exists!", 10000);
    } else {
      const payload = [
        {
          _schedule_id: values._schedule_id,
          schedule_id: formData.schedule_id,
          schedule_interval: formData.schedule_interval,
          schedule_count: formData.schedule_count,
          schedule_cron: formData.schedule_cron,
          schedule_state: formData.schedule_state,
          schedule_type: formData.schedule_type,
          schedule_cmd: formData.schedule_cmd,
          schedule_group: formData.schedule_group,
          parallel_execute: formData.parallel_execute,
        },
      ];

      const resourcePath = "integrations/cfg/schedules";

      API.UpdateRecord(resourcePath, payload).then((response) => {
        if (response.code === 200) {
          fetchTaskSchedulesFromAllEnv();
          NotificationManager.success(
            "Record Updated",
            "Query Schedule",
            10000
          );
        } else {
          NotificationManager.error(response.message, "Failed!", 10000);
        }
      });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <Box>
      <FormControl fullWidth={true}>
        <FormLabel>ID</FormLabel>
        <TextField
          required
          id="schedule_id"
          name="schedule_id"
          size="large"
          disabled={true}
          variant="standard"
          value={formData.schedule_id}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Cron</FormLabel>
        <TextField
          required
          id="schedule_cron"
          name="schedule_cron"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.schedule_cron}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="schedule_state">
        <FormLabel>State</FormLabel>
        <Select
          name="schedule_state"
          labelId="schedule_state"
          id="schedule_state"
          variant="standard"
          disabled={!editable}
          value={formData.schedule_state}
          onChange={handleChange}
        >
          {stateItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="schedule_type">
        <FormLabel>Type</FormLabel>
        <Select
          name="schedule_type"
          labelId="schedule_type"
          id="schedule_type"
          variant="standard"
          disabled={!editable}
          value={formData.schedule_type}
          onChange={handleChange}
        >
          {typeItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Command</FormLabel>
        <TextField
          required
          id="schedule_cmd"
          name="schedule_cmd"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.schedule_cmd}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>Group</FormLabel>
        <TextField
          required
          id="schedule_group"
          name="schedule_group"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.schedule_group}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="parallel_execute">
        <FormLabel>Parallel Execute</FormLabel>
        <Select
          name="parallel_execute"
          labelId="parallel_execute"
          id="parallel_execute"
          variant="standard"
          disabled={!editable}
          value={formData.parallel_execute}
          onChange={handleChange}
        >
          {parallel_execute_Items}
        </Select>
      </FormControl>

      <Button type="edit" disabled={editable} onClick={onEditClick}>
        Edit
      </Button>
      <Button type="submit" disabled={!editable} onClick={handleSubmit}>
        Save
      </Button>
      <Button type="close" onClick={handleDetailClose}>
        Close
      </Button>
    </Box>
  );
};

export default ViewEditTaskSchedule;
