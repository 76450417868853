import React from "react";
import { MaterialReactTable } from "material-react-table";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";

const OuraPatientViewTable = ({ data, columns }) => {
  const { fetchOuraPatients } = useDataView();

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    data[row.index] = values;

    const payload = [
      {
        _PROJECT_ID: row.original.PROJECT_ID,
        _SITE_ID: row.original.SITE_ID,
        _PATIENT_ID: row.original.PATIENT_ID,
        TOKEN: values.TOKEN,
        STATE: values.STATE,
        INFORMED_CONSENT_DATE: values.INFORMED_CONSENT_DATE,
      },
    ];

    const resourcePath = "incyte/oura/onboard";

    API.UpdateOuraPatient(resourcePath, payload).then((response) => {
      if (response.code === 200) {
        NotificationManager.success("Record Updated", "Oura Patient", 10000);
        fetchOuraPatients();
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });

    exitEditingMode();
  };

  const handleRowEditCancel = ({ row, table }) => {
    //avoid setting invalid state on cancel edit
    fetchOuraPatients();
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      editingMode="modal"
      enableEditing
      onEditingRowSave={handleSaveRow}
      onEditingRowCancel={handleRowEditCancel}
      initialState={{
        density: "compact",
        columnVisibility: { PROJECT_ID: false, SITE_ID: false },
      }}
      enableStickyHeader
      enableStickyFooter
      autoResetPageIndex={false}
    />
  );
};

export default OuraPatientViewTable;
