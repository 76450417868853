import React, { useEffect } from "react";
import OuraPatientViewTable from "./ouraPatientView";
import "../util/tables.css";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import { useDataView } from "../../contexts/data.view.context";
import ShowLoadingBar from "../util/showLoadingBar";

const columns = [
  {
    id: "PROJECT_ID",
    accessorKey: "PROJECT_ID", //access nested data with dot notation
    header: "Project ID",
    size: 150,
    enableEditing: false,
  },
  {
    id: "SITE_ID",
    accessorKey: "SITE_ID",
    header: "Site ID",
    size: 150,
    enableEditing: false,
  },
  {
    id: "PATIENT_ID",
    accessorKey: "PATIENT_ID",
    header: "Patient ID",
    size: 150,
    enableEditing: false,
  },
  {
    id: "INFORMED_CONSENT_DATE",
    accessorKey: "INFORMED_CONSENT_DATE",
    header: "Informed Consent Date",
    size: 150,
  },

  {
    id: "STATE",
    accessorKey: "STATE",
    header: "State",
    size: 150,

    Edit: ({ cell, column, table }) => {
      let originalState = cell.row.original.STATE;
      const [stateValue, setState] = React.useState(originalState);

      const items = ["Active", "Disabled"];

      const handleChange = (event) => {
        cell.row._valuesCache.STATE = event.target.value;
        setState(event.target.value);
      };

      const menuItems = items.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });

      return (
        <>
          <Box sx={{}}>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              shrink="true"
              sx={{
                bottom: -5,
              }}
            >
              State
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={stateValue}
              defaultValue={originalState}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </Box>
        </>
      );
    },
  },

  {
    id: "TOKEN",
    accessorKey: "TOKEN", //normal accessorKey
    header: "Token",
    size: 300,

    Cell: ({ row }) => {
      return (
        <Tooltip
          title={
            <React.Fragment>
              <Typography variant="subtitle2">
                {row.original._RAW_TOKEN_}
              </Typography>
            </React.Fragment>
          }
        >
          <span>{row.original.TOKEN}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "_RECORD_COUNT_",
    accessorKey: "_RECORD_COUNT_",
    header: "Record Count",
    size: 150,
    enableEditing: false,
  },
  {
    id: "_LAST_EVENT_DAY_",
    accessorKey: "_LAST_EVENT_DAY_",
    header: "Last Event Day",
    size: 150,
    enableEditing: false,
    Cell: ({ cell }) => {
      const cellValue = cell.getValue();
      if (cellValue === "") {
        return "N/A";
      }

      return cellValue;
    },
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const OuraPatients = () => {
  const { fetchOuraPatients, ouraPatients } = useDataView();

  useEffect(() => {
    (async () => {
      if (ouraPatients.length === 0) await fetchOuraPatients();
    })();
    // eslint-disable-next-line
  }, []);

  let loading = false;
  if (ouraPatients.length === 0) {
    loading = true;
  } else {
    loading = false;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="Oura Patient List" loading={loading} />;
  }

  return (
    <>
      <div className="relative">
        <h2 style={h2Style}>Oura Patient List</h2>

        {ouraPatients.length > 0 && (
          <OuraPatientViewTable
            columns={columns}
            data={ouraPatients.map((patient) => ({
              PROJECT_ID: patient.PROJECT_ID,
              SITE_ID: patient.SITE_ID,
              PATIENT_ID: patient.PATIENT_ID,
              INFORMED_CONSENT_DATE: patient.INFORMED_CONSENT_DATE,
              STATE: patient.STATE,
              TOKEN: patient.TOKEN,
              _LAST_EVENT_DAY_: patient._LAST_EVENT_DAY_,
              _RECORD_COUNT_: patient._RECORD_COUNT_,
              _RAW_TOKEN_: patient._RAW_TOKEN_,
            }))}
          />
        )}
      </div>
    </>
  );
};

export default OuraPatients;
