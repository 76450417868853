import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, FormControl, FormLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";

const ViewEditAPIConfig = ({
  curRow,
  handleDetailClose,
  editable,
  onEditClick,
  data,
}) => {
  const { fetchAPIConfigsFromAllEnv } = useDataView();
  const values = curRow.original;

  const [formData, setFormData] = useState({
    _API_ID: values._API_ID,
    API_ID: values.API_ID,
    MOD: values.MOD,
    PATH: values.PATH,
    OPERATION: values.OPERATION,
    STATE: values.STATE,
    NAME: values.NAME,
    REF_TYPE: values.REF_TYPE,
    REF_ID: values.REF_ID,
    SECURITY_POLICY: values.SECURITY_POLICY,
    SAMPLE_DATA: values.SAMPLE_DATA,
    REF_DATA: values.REF_DATA,
  });

  const operations = ["GET", "POST", "PUT", "DELETE", "PATCH"];
  const states = ["Active", "Disabled"];
  const refTypes = [
    "AWARDCO",
    "CTPO",
    "DataQuery",
    "ESB",
    "FileQuery",
    "INCYTE",
  ];

  const operationItems = operations.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  const stateItems = states.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  const refTypeItems = refTypes.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    const edited_api_id = formData.API_ID;
    const org_api_id = values.API_ID;
    let checkAPIid = null;

    if (org_api_id === edited_api_id) {
      // api id is not edited
    } else {
      checkAPIid = data.find((apiConfig) => apiConfig.API_ID === edited_api_id);
    }

    if (checkAPIid) {
      NotificationManager.error("", "API ID exists!", 10000);
    } else {
      const payload = [
        {
          _API_ID: values._API_ID,
          API_ID: formData.API_ID,
          MOD: formData.MOD,
          PATH: formData.PATH,
          OPERATION: formData.OPERATION,
          STATE: formData.STATE,
          NAME: formData.NAME,
          REF_TYPE: formData.REF_TYPE,
          REF_ID: formData.REF_ID,
          SECURITY_POLICY: formData.SECURITY_POLICY,
          SAMPLE_DATA: formData.SAMPLE_DATA,
          REF_DATA: formData.REF_DATA,
        },
      ];

      const resourcePath = "integrations/cfg/apiconfigs";

      API.UpdateRecord(resourcePath, payload).then((response) => {
        if (response.code === 200) {
          fetchAPIConfigsFromAllEnv();
          NotificationManager.success("Record Updated", "API Config", 10000);
        } else {
          NotificationManager.error(response.message, "Failed!", 10000);
        }
      });
    }
  }

  function handleClone(event) {
    event.preventDefault();
    console.log(formData);
    const API_ID = formData.API_ID;

    let checkAPIIDExists = null;

    checkAPIIDExists = data.find((apiConfig) => apiConfig.API_ID === API_ID);

    if (checkAPIIDExists) {
      NotificationManager.error("", "API ID exists!", 10000);
    } else {
      const payload = [
        {
          API_ID: formData.API_ID,
          MOD: formData.MOD,
          PATH: formData.PATH,
          OPERATION: formData.OPERATION,
          STATE: formData.STATE,
          NAME: formData.NAME,
          REF_TYPE: formData.REF_TYPE,
          REF_ID: formData.REF_ID,
          SECURITY_POLICY: formData.SECURITY_POLICY,
          SAMPLE_DATA: formData.SAMPLE_DATA,
          REF_DATA: formData.REF_DATA,
        },
      ];

      const resourcePath = "integrations/cfg/apiconfigs";

      API.AddRecord(resourcePath, payload).then((response) => {
        if (response.code === 200) {
          NotificationManager.success(
            "Record added!",
            "API Config Entry",
            10000
          );
          fetchAPIConfigsFromAllEnv();
        } else {
          NotificationManager.error(response.message, "Failed!", 10000);
        }
      });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <Box>
      <FormControl fullWidth={true}>
        <FormLabel>ID</FormLabel>
        <TextField
          required
          id="API_ID"
          name="API_ID"
          size="large"
          disabled={!editable}
          variant="standard"
          value={formData.API_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>MOD</FormLabel>
        <TextField
          required
          id="MOD"
          name="MOD"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.MOD}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>PATH</FormLabel>
        <TextField
          required
          id="PATH"
          name="PATH"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.PATH}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="OPERATION">
        <FormLabel>OPERATION</FormLabel>
        <Select
          name="OPERATION"
          labelId="OPERATION"
          id="OPERATION"
          variant="standard"
          disabled={!editable}
          value={formData.OPERATION}
          onChange={handleChange}
        >
          {operationItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="STATE">
        <FormLabel>STATE</FormLabel>
        <Select
          name="STATE"
          labelId="STATE"
          id="STATE"
          variant="standard"
          disabled={!editable}
          value={formData.STATE}
          onChange={handleChange}
        >
          {stateItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>NAME</FormLabel>
        <TextField
          required
          id="NAME"
          name="NAME"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.NAME}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="REF_TYPE">
        <FormLabel>REF_TYPE</FormLabel>
        <Select
          name="REF_TYPE"
          labelId="REF_TYPE"
          id="REF_TYPE"
          variant="standard"
          disabled={!editable}
          value={formData.REF_TYPE}
          onChange={handleChange}
        >
          {refTypeItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>REF_ID</FormLabel>
        <TextField
          required
          id="REF_ID"
          name="REF_ID"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.REF_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>REF_DATA</FormLabel>
        <TextField
          required
          id="REF_DATA"
          name="REF_DATA"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.REF_DATA}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>SECURITY_POLICY</FormLabel>
        <TextField
          required
          id="SECURITY_POLICY"
          name="SECURITY_POLICY"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.SECURITY_POLICY}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>SAMPLE_DATA</FormLabel>
        <TextField
          required
          id="SAMPLE_DATA"
          name="SAMPLE_DATA"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.SAMPLE_DATA}
          onChange={handleChange}
        />
      </FormControl>

      <Button type="edit" disabled={editable} onClick={onEditClick}>
        Edit
      </Button>
      <Button type="submit" disabled={!editable} onClick={handleSubmit}>
        Save
      </Button>
      <Button type="submit" disabled={!editable} onClick={handleClone}>
        Clone
      </Button>
      <Button type="close" onClick={handleDetailClose}>
        Close
      </Button>
    </Box>
  );
};

export default ViewEditAPIConfig;
