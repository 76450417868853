import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, FormControl, FormLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { NotificationManager } from "react-notifications";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";

const ViewEditFileTransfer = ({
  curRow,
  handleDetailClose,
  editable,
  onEditClick,
  data,
}) => {
  const { fetchFileTransfersFromAllEnv } = useDataView();
  const values = curRow.original;

  const [formData, setFormData] = useState({
    _pkTransfer: values._pkTransfer,
    TRANSFER_ID: values.TRANSFER_ID,
    PROJECT_ID: values.PROJECT_ID,
    COMMANDS: values.COMMANDS_TEXT,
    STATE: values.STATE,
    TRANSFER_TYPE: values.TRANSFER_TYPE,
    RETENTION_DAYS: values.RETENTION_DAYS,
    COMMENTS: values.COMMENTS,
  });

  const states = ["Active", "Closed", "Pending", "Locked"];

  const stateItems = states.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    const payload = [
      {
        _pkTransfer: values._pkTransfer,
        TRANSFER_ID: formData.TRANSFER_ID,
        STATE: formData.STATE,
        TRANSFER_TYPE: formData.TRANSFER_TYPE,
        RETENTION_DAYS: formData.RETENTION_DAYS,
        COMMENTS: formData.COMMENTS,
        COMMANDS: formData.COMMANDS,
      },
    ];

    console.log(payload);

    const resourcePath = "integrations/cfg/transfers";

    API.UpdateRecord(resourcePath, payload).then((response) => {
      if (response.code === 200) {
        fetchFileTransfersFromAllEnv();
        NotificationManager.success("Record Updated", "File Transfer", 10000);
      } else {
        NotificationManager.error(response.message, "Failed!", 10000);
      }
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <Box>
      <FormControl fullWidth={true}>
        <FormLabel>TRANSFER_ID</FormLabel>
        <TextField
          required
          id="TRANSFER_ID"
          name="TRANSFER_ID"
          size="large"
          disabled={true}
          variant="standard"
          value={formData.TRANSFER_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>PROJECT_ID</FormLabel>
        <TextField
          required
          id="PROJECT_ID"
          name="PROJECT_ID"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.PROJECT_ID}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="STATE">
        <FormLabel>State</FormLabel>
        <Select
          name="STATE"
          labelId="STATE"
          id="STATE"
          variant="standard"
          disabled={!editable}
          value={formData.STATE}
          onChange={handleChange}
        >
          {stateItems}
        </Select>
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="TRANSFER_TYPE">
        <FormLabel>TRANSFER_TYPE</FormLabel>
        <TextField
          required
          id="TRANSFER_TYPE"
          name="TRANSFER_TYPE"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.TRANSFER_TYPE}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>RETENTION_DAYS</FormLabel>
        <TextField
          required
          id="RETENTION_DAYS"
          name="RETENTION_DAYS"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.RETENTION_DAYS}
          onChange={handleChange}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true}>
        <FormLabel>COMMANDS</FormLabel>
        <TextField
          required
          id="COMMANDS"
          name="COMMANDS"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.COMMANDS}
          onChange={handleChange}
          multiline
          rows={5}
          inputProps={{ style: { resize: "both" } }}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} id="parallel_execute">
        <FormLabel>COMMENTS</FormLabel>
        <TextField
          required
          id="COMMENTS"
          name="COMMENTS"
          size="small"
          disabled={!editable}
          variant="standard"
          value={formData.COMMENTS}
          onChange={handleChange}
        />
      </FormControl>

      <Button type="edit" disabled={editable} onClick={onEditClick}>
        Edit
      </Button>
      <Button type="submit" disabled={!editable} onClick={handleSubmit}>
        Save
      </Button>
      <Button type="close" onClick={handleDetailClose}>
        Close
      </Button>
    </Box>
  );
};

export default ViewEditFileTransfer;
