import React, { useEffect } from "react";
import FileTransferStatsViewTable from "./fileTransferStatsView";
import "../../util/tables.css";
import { useDataView } from "../../../contexts/data.view.context";

const columns = [
  {
    id: "cmdGroup",
    accessorKey: "cmdGroup",
    header: "Command Group",
    size: 150,
  },
  {
    id: "projectId",
    accessorKey: "projectId",
    header: "Project ID",
    enableGrouping: false,
    size: 150,
  },
  {
    id: "transferSeq",
    accessorKey: "transferSeq",
    header: "Seq",
    enableGrouping: false,
    size: 150,
  },
  {
    id: "status",
    accessorKey: "status",
    header: "Status",
    enableGrouping: false,
    size: 200,

    Cell: ({ row }) => {
      let value = row.original.status;
      value = "" + value;

      if (value === "200") {
        return <p style={{ color: "green" }}>{value}</p>;
      }

      return <p style={{ color: "red" }}>{value}</p>;
    },
  },
  {
    id: "average_30_day",
    accessorKey: "average_30_day",
    header: "30 Day Average",
    enableGrouping: false,
    size: 200,
  },
  {
    id: "average_7_day",
    accessorKey: "average_7_day",
    header: "7 Day Average",
    enableGrouping: false,
    Cell: ({ row }) => {
      let value = row.original.average_7_day;
      value = "" + value;

      if (value.includes(">")) {
        value = value.replace(/>/g, "");
        value = value.replace(/</g, "");
      }

      return <span>{value}</span>;
    },
    size: 200,
  },
  {
    id: "yesterday",
    accessorKey: "yesterday",
    header: "Yesterday",
    enableGrouping: false,
    size: 200,
    Cell: ({ row }) => {
      let value = row.original.yesterday;
      value = "" + value;

      if (value.includes(">")) {
        value = value.replace(/>/g, "");
        value = value.replace(/</g, "");
      }

      return <span>{value}</span>;
    },
  },
];

const h2Style = {
  paddingLeft: "20px",
};

const FileTrnsStatsViewByGroup = () => {
  const { fetchFileTransferStatsByGroup, fileTransferStatsByGroup } =
    useDataView();

  useEffect(() => {
    (async () => {
      if (fileTransferStatsByGroup.length === 0)
        await fetchFileTransferStatsByGroup();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="relative">
        <h2 style={h2Style}>FileTransfer Stats By Group </h2>

        {fileTransferStatsByGroup.length > 0 && (
          <FileTransferStatsViewTable
            columns={columns}
            data={fileTransferStatsByGroup.map((fileStats) => ({
              cmdGroup: fileStats.cmdGroup,
              projectId: fileStats.projectId,
              transferSeq: fileStats.transferSeq,
              status: fileStats.status,
              average_30_day: fileStats.TransferCount30DayAverage,
              average_7_day: fileStats.TransferCount7DayAverage,
              yesterday: fileStats.TransferCountYesterday,
            }))}
          />
        )}
      </div>
    </>
  );
};

export default FileTrnsStatsViewByGroup;
