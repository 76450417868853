import { useDataView } from "../../contexts/data.view.context";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import ViewDetailsIcon from "../util/detailsModal/viewDetailsIcon";
import ViewEditAPIConfig from "./viewEditApiConfig";

const viewDetailsModal = ({ detailData }) => {
  return function ({ cell, row }) {
    const {
      openDetailForm,
      editable,
      curRow,
      handleClickOpenDetail,
      handleDetailClose,
      onEditClick,
      detailViewBoxStyle,
    } = useDataView();

    const cellValue = cell.getValue();
    const tooltip = "View Details";

    return (
      <>
        <ViewDetailsIcon
          row={row}
          tooltip={tooltip}
          onClick={handleClickOpenDetail}
        />
        {cellValue}
        <Modal
          open={openDetailForm}
          onClose={handleClickOpenDetail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          hideBackdrop
          paperprops={{
            elevation: 0,
            sx: {
              border: "solid 1px gray",
              boxShadow: "none",
            },
          }}
          sx={{
            overflow: "scroll",
            paddingLeft: "20%",
          }}
        >
          <Box sx={detailViewBoxStyle}>
            <ViewEditAPIConfig
              curRow={curRow.current}
              handleDetailClose={handleDetailClose}
              editable={editable}
              onEditClick={onEditClick}
              data={detailData}
            />
          </Box>
        </Modal>
      </>
    );
  };
};

export default viewDetailsModal;
