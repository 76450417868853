import React, { useEffect } from "react";
import FileTransferHistoryViewTable from "./fileTransferHistoryView";
import "../util/tables.css";
import { useDataView } from "../../contexts/data.view.context";

const h2Style = {
  paddingLeft: "20px",
};

const FileTransferHistoryView = () => {
  const columns = [
    {
      id: "projectId",
      accessorKey: "projectId",
      header: "Project ID",
      size: 150,
    },
    {
      id: "cmdGroup",
      accessorKey: "cmdGroup",
      header: "Cmd Group",
      size: 50,
    },
    {
      id: "transferName",
      accessorKey: "transferName",
      header: "Transfer Name",
      size: 200,
    },
    {
      id: "createdDt",
      accessorKey: "createdDt",
      header: "Date",
      size: 100,
    },
    {
      id: "transferSeq",
      accessorKey: "transferSeq",
      header: "Seq",
      size: 50,
    },
    {
      id: "status",
      accessorKey: "status",
      header: "Status",

      size: 50,

      Cell: ({ row }) => {
        let value = row.original.status;
        value = "" + value;

        if (value === "200") {
          return <p style={{ color: "green" }}>{value}</p>;
        }

        return <p style={{ color: "red" }}>{value}</p>;
      },
    },
    {
      id: "fromPath",
      accessorKey: "fromPath",
      header: "From",
      size: 200,
    },
    {
      id: "toPath",
      accessorKey: "toPath",
      header: "To",
      size: 200,
    },
    {
      id: "source",
      accessorKey: "source",
      header: "Source",
      size: 200,
    },
    {
      id: "destination",
      accessorKey: "destination",
      header: "Destination",
      size: 200,
    },
  ];
  const { fetchfileTransferHistory, fileTransferHistory } = useDataView();

  useEffect(() => {
    (async () => {
      if (fileTransferHistory.length === 0) await fetchfileTransferHistory();
    })();
    // eslint-disable-next-line
  }, []);

  let projectsList = null;
  let mainCmdGroupList = null;
  let mainTransferNameList = null;

  if (fileTransferHistory.length > 0) {
    let list = fileTransferHistory.map((fileHistory) => {
      if (fileHistory.projectId === null) {
        fileHistory.projectId = "TESTS_PROJECT";
      }
      return fileHistory.projectId;
    });

    let uniq = [...new Set(list)];

    projectsList = uniq.map((projectId) => {
      return { value: projectId, label: projectId };
    });

    list = fileTransferHistory.map((fileHistory) => {
      return fileHistory.cmdGroup;
    });

    uniq = [...new Set(list)];

    mainCmdGroupList = uniq.map((cmdGrp) => {
      return { value: cmdGrp, label: cmdGrp };
    });

    list = fileTransferHistory.map((fileHistory) => {
      return fileHistory.transferName;
    });

    uniq = [...new Set(list)];

    mainTransferNameList = uniq.map((transferName) => {
      return { value: transferName, label: transferName };
    });
  }

  return (
    <>
      <div className="relative">
        <h2 style={h2Style}>FileTransfer History</h2>

        {fileTransferHistory.length > 0 && (
          <FileTransferHistoryViewTable
            columns={columns}
            data={fileTransferHistory.map((fileHistory) => {
              if (fileHistory.projectId === null) {
                fileHistory.projectId = "TESTS_PROJECT";
              }

              return {
                transferId: fileHistory.transferId,
                transferSeq: fileHistory.transferSeq,
                status: fileHistory.status,
                details: fileHistory.details,
                fromPath: fileHistory.fromPath,
                toPath: fileHistory.toPath,
                transferName: fileHistory.transferName,
                cmdGroup: fileHistory.cmdGroup,
                projectId: fileHistory.projectId,
                source: fileHistory.source,
                destination: fileHistory.destination,
                createdDt: fileHistory.createdDt,
                createdBy: fileHistory.createdBy,
              };
            })}
            projectsList={projectsList}
            mainCmdGroupList={mainCmdGroupList}
            mainTransferNameList={mainTransferNameList}
          />
        )}
      </div>
    </>
  );
};

export default FileTransferHistoryView;
