import React, { useEffect, useState, useRef } from "react";
import EICacheViewTable from "./eiCacheView";
import { Button } from "@mui/material";
import { NotificationManager } from "react-notifications";
import "../util/tables.css";
import * as API from "../api/index";
import { useDataView } from "../../contexts/data.view.context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ShowLoadingBar from "../util/showLoadingBar";

function handleClick(name) {
  const resourcePath = "integrations/esb/cache/clear/" + name;

  API.ClearCache(resourcePath).then((response) => {
    if (response.code === 200) {
      NotificationManager.success(response.message, name, 10000);
    } else {
      NotificationManager.error(response.message, "Failed!", 10000);
    }
  });
}

const h2Style = {
  paddingLeft: "20px",
};

const EICaches = () => {
  const { fetchEICacheList, eiCacheList } = useDataView();

  const columns = [
    {
      accessorKey: "Name",
      header: "Name",
      id: "Name",
      size: 100,
      Cell: viewDetails(),
    },
    {
      accessorKey: "Size",
      header: "Size",
      id: "Size",
      size: 100,
    },
    {
      accessorKey: "Action",
      header: "Action",
      size: 75,

      Cell: ({ row }) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleClick(row.original.Name)}
          >
            REFRESH
          </Button>
        );
      },
    },
  ];

  const [openDetail, setOpenDetail] = useState(false);
  const refEnvCell = useRef(null);

  const handleDetailClickOpen = ({ row }) => {
    refEnvCell.current = row.original.data;
    setOpenDetail(true);
  };

  const handleDetailClose = () => {
    setOpenDetail(false);
  };

  function viewDetails() {
    return function ({ cell, row }) {
      const cellValue = cell.getValue();
      let tooltip = "View Details";

      return (
        <>
          <IconButton
            sx={{ color: "blue" }}
            onClick={() => handleDetailClickOpen({ row })}
          >
            <Tooltip id="button-report" title={tooltip}>
              <InfoIcon sx={{ color: "#5F9EA0" }} />
            </Tooltip>
          </IconButton>
          {cellValue}

          <Dialog
            id="viewContent"
            open={openDetail}
            onClose={handleDetailClose}
            hideBackdrop
            paperprops={{
              elevation: 0,
              sx: {
                border: "solid 1px gray",
                boxShadow: "none",
                maxWidth: "80%",
              },
            }}
          >
            <DialogContent>
              <DialogContentText id="viewDteails" flexGrow={true}>
                {
                  <div>
                    <pre>{JSON.stringify(refEnvCell.current, null, 2)}</pre>
                  </div>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDetailClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      );
    };
  }

  useEffect(() => {
    (async () => {
      if (eiCacheList.length === 0) await fetchEICacheList();
    })();
    // eslint-disable-next-line
  }, []);

  let loading = false;
  if (eiCacheList.length === 0) {
    loading = true;
  } else {
    loading = false;
  }

  if (Boolean(loading)) {
    return <ShowLoadingBar title="LIST of Caches" loading={loading} />;
  }

  return (
    <div className="cache-table">
      <h2 style={h2Style}>LIST of Caches</h2>

      {eiCacheList.length > 0 && (
        <EICacheViewTable
          data={eiCacheList.map((cache) => {
            return {
              Name: cache.name,
              Size: cache.size,
              data: cache.data,
            };
          })}
          columns={columns}
        />
      )}
    </div>
  );
};

export default EICaches;
